import { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, CircularProgress, Grid, styled } from '@mui/material';
import { DocIllustration } from '../../assets/assets';
import { fetchCoaching } from '../../redux/coachingReducer';

import HoursSection from './hoursSection';
import CardHeader from '../../components/cardHeader';
import GoalsKanban from './goalsKanban';
import SessionSection from './sessionSection';
import ActionSection from './actionSection';
import EvaluationSection from './evaluationSection';
import { store } from '../../redux/Store';
import { getEventsByCoachee } from '../../redux/calendarReducer';
import { fetchGoalBuyCoach, fetchGoalBuyCoachee } from '../../redux/goalReducer';
import { fetchAction } from '../../redux/actionReducer';

function Dashboard() {
  const dispatch = useDispatch();

  const { coachingList, isLoadingCoaching } = useSelector((state) => state.coaching);
  const { user } = useSelector((state) => state.auth?.user?.user);
  const { currentUser } = useSelector((state) => state.listUser);

   //  react-hooks/exhaustive-deps
   useEffect(() => {

    if (user.role === "COACHE" && user?.userId) {
      store.dispatch(getEventsByCoachee(user?.userId));
      store.dispatch(fetchGoalBuyCoachee(user.userId));
    } else if (user.role === "COACH" && user?.userId && currentUser) {
      store.dispatch(getEventsByCoachee(currentUser?._id));
      store.dispatch(fetchGoalBuyCoachee(currentUser?._id));
    }
    store.dispatch(fetchAction());
    store.dispatch(fetchCoaching());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header   />
      <TabsNav coachingList={coachingList} isLoadingCoaching={isLoadingCoaching} user={user} />

      <Divider
        sx={{
          my: 8,
        }}
      />
    </>
  );
}

function Header() {
  const { user } = useSelector((state) => state.auth);
  const { board } = useSelector((state) => state.goal);
  const { actionList } = useSelector((state) => state.action);

  const count = () => {
    let len = 0;
    board?.columns[1]?.cardIds?.map((val) => {
      // console.log("------------",val);
      // console.log("------------",actionList.filter((action)=> action.goalId === val && action.status === "PENDING" ));
      const ans = actionList.filter((action) => action.goalId === val && action.status === 'PENDING');
      len += ans.length;
      return ans;
    });
    return len;
  };

  return (
    <CardHeader
      sx={{
        mt: 4,
      }}
      size="small"
      title={` Bonjour ${user.user.user.name}!`}
      subtitle={`Aujourd'hui ${new Date().toLocaleDateString('fr-FR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`}
    >
      <Stack
        pt={{ xs: 5, sm: 0 }}
        divider={<Divider orientation="vertical" flexItem />}
        direction="row"
        alignItems="center"
        spacing={3}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1" fontSize={35} display="inline">
            {board?.columns[1]?.cardIds?.length}
          </Typography>
          <Typography variant="caption" textTransform="uppercase">
            Objectifs <br />
            encours
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1" fontSize={35} display="inline">
            {count()}
          </Typography>
          <Typography variant="caption" textTransform="uppercase">
            Actions <br />
            encours
          </Typography>
        </Stack>
      </Stack>
    </CardHeader>
  );
}

function TabsNav({ isLoadingCoaching, coachingList, user }) {

  const [value, setValue] = useState(0);
  console.log("coachingList", coachingList);
  // const [slot, setSlot] = useState('Today');
  const navigate = useNavigate();


  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };

  const hasCoach = () => {
    console.log("coachingList", coachingList);
    let val = false;
    coachingList.forEach(element => {
      if (element.coache.includes(user.userId)) {
        val = true;
      }
    });
    return val;
  }

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: theme.palette.error.lighter,
    [theme.breakpoints.up('md')]: {
      height: '100%',
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));


  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        aria-label="grap type"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab label="Aperçu général" />
        <Tab label="Sessions" />
        <Tab label="Objectifs" />
        <Tab label="Actions" />
        <Tab label="Evaluation" />
        <Box flexGrow={1} />

        {/* <Stack direction="row" alignItems="center" spacing={0}>
          {["Ajourd'hui", 'Cette semaine', 'Ce mois'].map((tab, i) => (
            <Button
              key={i}
              size="small"
              onClick={() => setSlot(tab)}
              variant={slot === tab ? 'outlined' : 'text'}
              sx={{
                color: slot === tab ? 'primary.main' : 'text.secondary',
              }}
            >
              {tab}
            </Button>
          ))}
        </Stack> */}
      </Tabs>
      {
        isLoadingCoaching ?
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="warning" />
          </Box> :

          user?.role === "COACHE" ? !hasCoach() ?
            <Grid item xs={12} sm={12}>
              <RootStyle>
                <CardContent
                  sx={{
                    p: { md: 0 },
                    pl: { md: 5 },
                    color: 'grey.800',
                  }}
                >
                  <Typography gutterBottom variant="h5">
                    Veiller choisir votre coach
                  </Typography>

                  <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 580, mx: 'auto' }}>
                    Nous vous conseillons de bien vouloir choisir un coah entrepreneurial!
                  </Typography>

                  <Button variant="contained" color="error"
                    onClick={() => navigate('/dashboard/coach', { replace: true })}
                  >
                    Choisir un coach
                  </Button>
                </CardContent>

                <DocIllustration
                  sx={{
                    p: 3,
                    width: 160,
                    margin: { xs: 'auto', md: 'inherit' },
                  }}
                />
              </RootStyle>
            </Grid> : null : null
      }

      {user?.role === "COACHE" ?

        <Grid item xs={12} sm={12}>
          <RootStyle>
            <CardContent
              sx={{
                p: { md: 0 },
                pl: { md: 5 },
                color: 'grey.800',
              }}
            >
              <Typography gutterBottom variant="h5">
                Passez votre première évaluation!
              </Typography>

              <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 580, mx: 'auto' }}>
                Nous voulons identifier les compétences clés à développer pour assurer la réussite de votre  projet ou service!
              </Typography>

              <Button variant="contained" color="error"
                onClick={() => navigate('/dashboard/evaluation', { replace: true })}
              >
                Commencer
              </Button>
            </CardContent>

            <DocIllustration
              sx={{
                p: 3,
                width: 160,
                margin: { xs: 'auto', md: 'inherit' },
              }}
            />
          </RootStyle>
        </Grid> : null}



      {value === 0 ? (
        <HoursSection />
      ) : value === 1 ? (
        <SessionSection />
      ) : value === 2 ? (
        <Box paddingTop={3} paddingBottom={3}>
          <GoalsKanban />
        </Box>
      ) : value === 3 ? (
        <ActionSection />
      ) : (
        <EvaluationSection />
      )}
    </Box>
  );
}

export default Dashboard;
