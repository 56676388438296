import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';

// @mui
import { Container, Box, Typography, Divider, MenuItem, Avatar, Popover, Chip, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import Dashboard from './dashboard';
import { fetchCurrentUser, fetchUsers } from '../redux/listUserReducer';
import { store } from '../redux/Store';
import { fetchCoaching } from '../redux/coachingReducer';
import { getEventsByCoachee } from '../redux/calendarReducer';
import { deleteAll, fetchGoalBuyCoachee } from '../redux/goalReducer';

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const { userList, isLoadingUser, currentUser } = useSelector((state) => state.listUser);
  const { user } = useSelector((state) => state.auth?.user?.user);
  const { coachingList } = useSelector((state) => state.coaching);

  const [open, setOpen] = useState(false);

  //  react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchUsers());
    store.dispatch(fetchCoaching());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectCurrentUser = async (user) => {
    dispatch(fetchCurrentUser(user));
    dispatch(deleteAll());
    dispatch(fetchGoalBuyCoachee(user?._id));
    dispatch(getEventsByCoachee(user?._id));
    setOpen(null);
  };

  const hasCoach = () => {
    let val = null;
    coachingList.forEach(element => {
      if (element.coache.includes(user.userId)) {

        val = userList.find(u => u._id === element.coach)
      }
    });
    console.log("has", val);
    return val;
  }

  const coaching = (listCoachs, listUsers) => {
    // Filter coaching entries for the current user (coach)
    const myCoachesTemp = listCoachs.filter(val => val.coach === user.userId);

    // If no coaching entries are found, return an empty array
    if (myCoachesTemp.length === 0) {
      return [];
    }

    // Create a Set to store unique coachee IDs
    const coacheeIds = new Set();

    // Collect all coachee IDs from the filtered coaching entries
    myCoachesTemp.forEach(coachingEntry => {
      coachingEntry.coache.forEach(coacheeId => {
        coacheeIds.add(coacheeId); // Use Set to avoid duplicates
      });
    });

    // Filter the list of users to find coachees based on the collected IDs
    const myCoaches = listUsers.filter(user => coacheeIds.has(user._id));

    return myCoaches; // Return the array of coachee user objects
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Coahing </title>
      </Helmet>

      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
          <Typography variant="h4" sx={{ mr: 2 }}>
            Tableau de board
          </Typography>

          {
            isLoadingUser ? (
              <CircularProgress />
            ) : (
              user.role === "COACHE" ?
                hasCoach() ?
                  <Chip
                    avatar={<Avatar alt="Mon coach" src={hasCoach()?.profile_picture} />}
                    label={hasCoach() ? `COACH : ${hasCoach().name}` : 'Mon coache'}
                    variant="outlined"
                    clickable
                  /> : null
                :
                <Chip
                  avatar={<Avatar alt="Tous" src={currentUser?.profile_picture} />}
                  label={currentUser ? currentUser?.name : 'Tous mes coaché(e)s'}
                  variant="outlined"
                  clickable
                  onClick={handleOpen}
                />
            )
          }

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
              sx: {
                p: 0,
                mt: 1.5,
                ml: 0.75,
                // width: 180,
                '& .MuiMenuItem-root': {
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem onClick={() => onSelectCurrentUser(null)} sx={{ my: 1.5, px: 2.5 }}>
              Tous mes coaché(e)s
            </MenuItem>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {coaching(coachingList, userList.filter((value) => value.role === 'COACHE'))
              .map((val, key) => (
                <Box key={key}>
                  <MenuItem
                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    onClick={() => onSelectCurrentUser(val)}
                  >
                    <Avatar src={val.profile_picture} />
                    <Typography variant="body2" sx={{ ml: 1 }} noWrap>
                      {val.name}
                    </Typography>
                  </MenuItem>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                </Box>
              ))}
          </Popover>
        </Box>

        <Dashboard />
      </Container>
    </>
  );
}
