import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux'; // import the useSelector hook

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import EvaluationPage from './pages/EvaluationPage';

import RegisterPage from './pages/registerPage';
import ResetPassowrdPage from './pages/ResetPassowrdPage';
import SettingsPage from './pages/SettingsPage';
import Coach from './pages/Coach';
import Coache from './pages/Coache';
import CoacheProfile from './pages/CoacheProfile';
import Invoice from './pages/Invoice';
import InvoiceCreate from './pages/InvoiceCreate';

export default function Router() {
  const { user } = useSelector((state) => state.auth);

  const routes = useRoutes([
    {
      //   path: '/dashboard',
      //   element: <DashboardLayout />,
      //   children: getRoutesBasedOnRole(user?.user?.user?.role),
      // },
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'evaluation', element: <EvaluationPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'coach', element: <Coach /> },
        { path: 'coache', element: <Coache /> },
        { path: 'coacheProfile', element: <CoacheProfile /> },
        { path: 'invoice', element: <Invoice /> },
        { path: 'invoiceCreate', element: <InvoiceCreate /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      // 
      path: 'resetPassword',
      element: <ResetPassowrdPage />
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
