import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";
// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of social. 
// Then we define a new slice called socialSlice with an initial state containing 
// an empty list of social, isLoading flag, and an error message if any.

export const fetchSocial = createAsyncThunk(
  "social/fetchSocial",
  async () => {
    const response = await axios.get(
      `${BASE_URL}api/social/`
    );
    return response.data;
  }
);

// Create an async thunk to create a new social object
export const createSocial = createAsyncThunk('social/create', async (social) => {
  // console.log("----------------------", social);
  try {
    const response = await axios.post(`${BASE_URL}api/social/`, social);
    return response.data;
  } catch (error) {
    console.log("Error =========??????", error);

    throw error;
  }
});

// Create an async thunk to update an existing social object
export const updateSocial = createAsyncThunk(
  "social/update",
  async ({
    _id,
    owner, socialMedia, url
  }) => {
    console.log("id========", _id);
    const baseUrl = `${BASE_URL}api/social/${_id}`; // Concatenate ID to the base URL

    const response = await axios.put(baseUrl, { // Use PUT request for updating
      owner, socialMedia, url
    });

    console.log("Edit social---?????? ok==", response.data);
    return response.data;
  }
);


const socialSlice = createSlice({
  name: "social",
  initialState: {
    socialList: [],

    isLoadingSocial: false,
    errorSocial: null,

    isLoadingCreateSocial: false,
    errorCreateSocial: null,

    isLoadingUpdateSocial: false,
    errorUpdateSocial: null,
    updateSocial: null,

  },
  reducers: {},
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchSocial is in a pending, fulfilled, or rejected state. 
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocial.pending, (state) => {
        state.isLoadingSocial = true;
        state.errorSocial = null;
      })
      .addCase(fetchSocial.fulfilled, (state) => { // action
        state.isLoadingSocial = false;
        state.socialList = []; // action.payload;
        state.errorSocial = null;
      })
      .addCase(fetchSocial.rejected, (state, action) => {
        state.isLoadingSocial = false;
        state.errorSocial = action.error.message;
      });

    // Handle the createSocial action
    builder
      .addCase(createSocial.pending, (state) => {
        state.isLoadingCreateSocial = true;
      })
      .addCase(createSocial.fulfilled, (state, action) => {
        state.isLoadingCreateSocial = false;
        state.socialList.push(action.payload);
      })
      .addCase(createSocial.rejected, (state, action) => {
        state.isLoadingCreateSocial = false;
        state.errorCreateSocial = action.error.message;
      });

    // update
    builder
      .addCase(updateSocial.pending, (state) => {
        state.isLoadingUpdateSocial = true;
      })
      .addCase(updateSocial.fulfilled, (state, action) => {
        state.isLoadingUpdateSocial = false;
        state.socialList.push(action.payload);
      })
      .addCase(updateSocial.rejected, (state, action) => {
        state.isLoadingUpdateSocial = false;
        state.errorUpdateSocial = action.error.message;
      });

  },
});

export default socialSlice.reducer;
