import PropTypes from 'prop-types';
import { Typography, Stack, LinearProgress } from '@mui/material';
import { fPercent } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

export default function Action({action, desc}) {
  return (
    <ProgressItem key={1} task={action} desc={desc}/>
  );
}

// ----------------------------------------------------------------------

ProgressItem.propTypes = {
  desc: PropTypes.string,
  task: PropTypes.shape({
    score: PropTypes.number,
    description: PropTypes.string,
  }),
};

function ProgressItem({ task, desc }) {

  return (
    <Stack spacing={1} sx={{ width: '100%', mb:2 }}> {/* Ensure full width for the entire Stack */}
      <Stack direction="row" alignItems="center" sx={{ width: '100%' }}> {/* Ensure full width */}
        {/* Description will take up all available space */}
        <Typography variant="caption" sx={{ flexGrow: 1, wordBreak: 'break-word' }}>
          {desc || 'No description available'}
        </Typography>
         <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
           &nbsp;({fPercent(task?.score)})
         </Typography> 
      </Stack>

      <LinearProgress
        variant="determinate"
        value={task.score} // Use the validated progress value
        color={
          (task.score  > 20 && task.score  <= 80 && 'warning') ||
          (task.score <= 20 && 'error') ||
          'success'
        }
        sx={{ width: '100%' }} // Ensure full width for the progress bar
      />
    </Stack>
  );
}

