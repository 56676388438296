import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";
// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of session. 
// Then we define a new slice called sessionSlice with an initial state containing 
// an empty list of session, isLoading flag, and an error message if any.

export const fetchSession = createAsyncThunk(
  "session/fetchSession",
  async () => {
    const response = await axios.get(
      `${BASE_URL}api/session/`
    );
    return response.data;
  }
);

// Create an async thunk to create a new session object
export const createSession = createAsyncThunk('session/create', async (session) => {
  // console.log("----------------------", session);
  try {
    const response = await axios.post(`${BASE_URL}api/session/`, session);
    return response.data;
  } catch (error) {
    console.log("Error =========??????", error);

    throw error;
  }
});

// Create an async thunk to update an existing session object
export const updateSession = createAsyncThunk(
  "session/update",
  async ({
    _id,
    idCoach, status, idCoachee
  }) => {
    console.log("id========", _id);
    const url = `${BASE_URL}api/session/${_id}`; // Concatenate ID to the base URL

    const response = await axios.put(url, { // Use PUT request for updating
      idCoach, status, idCoachee
    });

    console.log("Edit session---?????? ok==", response.data);
    return response.data;
  }
);


const sessionSlice = createSlice({
  name: "session",
  initialState: {
    sessionList:[],
   
    isLoadingSession: false,
    errorSession: null,

    isLoadingCreateSession: false,
    errorCreateSession: null,

    isLoadingUpdateSession: false,
    errorUpdateSession: null,
    updateSession: null,

  },
  reducers: {},
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchSession is in a pending, fulfilled, or rejected state. 
  extraReducers: (builder) => {
    builder
      .addCase(fetchSession.pending, (state) => {
        state.isLoadingSession = true;
        state.errorSession = null;
      })
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.isLoadingSession = false;
        state.sessionList = action.payload;
        state.errorSession = null;
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.isLoadingSession = false;
        state.errorSession = action.error.message;
      });

    // Handle the createSession action
    builder
      .addCase(createSession.pending, (state) => {
        state.isLoadingCreateSession = true;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.isLoadingCreateSession = false;
        state.sessionList.push(action.payload);
      })
      .addCase(createSession.rejected, (state, action) => {
        state.isLoadingCreateSession = false;
        state.errorCreateSession = action.error.message;
      });

    // update
    builder
      .addCase(updateSession.pending, (state) => {
        state.isLoadingUpdateSession = true;
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        state.isLoadingUpdateSession = false;
        state.sessionList.push(action.payload);
      })
      .addCase(updateSession.rejected, (state, action) => {
        state.isLoadingUpdateSession = false;
        state.errorUpdateSession = action.error.message;
      });

  },
});

export default sessionSlice.reducer;
