import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";

// Fetch all answers
export const fetchAnswers = createAsyncThunk(
    "answer/fetchAnswers",
    async () => {
        const response = await axios.get(`${BASE_URL}api/answer/`);
        return response.data;
    }
);

// Create a new answer
export const createAnswers = createAsyncThunk(
    'answer/createAnswers',
    async (answerData, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${BASE_URL}api/answer`, answerData);
            return response.data;
        } catch (error) {
            console.log("Error creating answer:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

// Update an existing answer
export const updateAnswer = createAsyncThunk(
    "answer/updateAnswer",
    async ({ answerId, updateData }, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${BASE_URL}api/answer/${answerId}`, updateData);
            return response.data;
        } catch (error) {
            console.log("Error updating answer:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

// Delete an answer
export const deleteAnswer = createAsyncThunk(
    "answer/deleteAnswer",
    async (answerId, { rejectWithValue }) => {
        try {
            await axios.delete(`${BASE_URL}api/answer/${answerId}`);
            return answerId;
        } catch (error) {
            console.log("Error deleting answer:", error);
            return rejectWithValue(error.response.data);
        }
    }
);

const answerSlice = createSlice({
    name: "answer",
    initialState: {
        answerList: [],
        isLoadingAnswers: false,
        errorAnswers: null,
        isLoadingCreateAnswers: false,
        errorCreateAnswers: null,
        isLoadingUpdateAnswer: false,
        errorUpdateAnswer: null,
        isLoadingDeleteAnswer: false,
        errorDeleteAnswer: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Answers
            .addCase(fetchAnswers.pending, (state) => {
                state.isLoadingAnswers = true;
                state.errorAnswers = null;
            })
            .addCase(fetchAnswers.fulfilled, (state, action) => {
                state.isLoadingAnswers = false;
                state.answerList = action.payload;
                state.errorAnswers = null;
            })
            .addCase(fetchAnswers.rejected, (state, action) => {
                state.isLoadingAnswers = false;
                state.errorAnswers = action.error.message;
            })
            // Create Answer
            .addCase(createAnswers.pending, (state) => {
                state.isLoadingCreateAnswers = true;
                state.errorCreateAnswers = null;
            })
            .addCase(createAnswers.fulfilled, (state, action) => {
                state.isLoadingCreateAnswers = false;
                state.answerList.push(action.payload);
                state.errorCreateAnswers = null;
            })
            .addCase(createAnswers.rejected, (state, action) => {
                state.isLoadingCreateAnswers = false;
                state.errorCreateAnswers = action.error.message;
            })
            // Update Answer
            .addCase(updateAnswer.pending, (state) => {
                state.isLoadingUpdateAnswer = true;
                state.errorUpdateAnswer = null;
            })
            .addCase(updateAnswer.fulfilled, (state, action) => {
                state.isLoadingUpdateAnswer = false;
                const index = state.answerList.findIndex(answer => answer._id === action.payload._id);
                if (index !== -1) {
                    state.answerList[index] = action.payload;
                }
                state.errorUpdateAnswer = null;
            })
            .addCase(updateAnswer.rejected, (state, action) => {
                state.isLoadingUpdateAnswer = false;
                state.errorUpdateAnswer = action.error.message;
            })
            // Delete Answer
            .addCase(deleteAnswer.pending, (state) => {
                state.isLoadingDeleteAnswer = true;
                state.errorDeleteAnswer = null;
            })
            .addCase(deleteAnswer.fulfilled, (state, action) => {
                state.isLoadingDeleteAnswer = false;
                state.answerList = state.answerList.filter(answer => answer._id !== action.payload);
                state.errorDeleteAnswer = null;
            })
            .addCase(deleteAnswer.rejected, (state, action) => {
                state.isLoadingDeleteAnswer = false;
                state.errorDeleteAnswer = action.error.message;
            });
    },
});

export default answerSlice.reducer;
