import PropTypes from 'prop-types';

// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  myProfile: PropTypes.object // .isRequired,
};

export default function MyAvatar({  myProfile, ...other}) {
  return (
    <Avatar
      src={myProfile?.profile_picture }
      alt={myProfile?.name}
      color={myProfile?.profile_picture ? 'default' : createAvatar(myProfile?.name).color}
      {...other}
    >
      {createAvatar(myProfile?.name).name}
    </Avatar>
  );
}
