/* eslint-disable */

import PropTypes from 'prop-types';
import { format, isSameDay, isSameMonth } from 'date-fns';
import { useState, useCallback } from 'react';
import {
  Box,
  Paper,
  Stack,
  OutlinedInput,
  ClickAwayListener,
  Tooltip,
  IconButton,
  Checkbox,
} from '@mui/material';
import { MobileDateRangePicker } from '@mui/lab';
import uuidv4 from '../../../utils/uuidv4';
import Iconify from 'src/components/Iconify';

const defaultTask = {
  name: '',
  coachMood: '',
  // sessionId: '',
  cover: '',
  label: [],
  date_limite: '',
  description: '',
  attachements: [],
  chat: [],
  percentage: 0,
};

KanbanTaskAdd.propTypes = {
  onAddTask: PropTypes.func.isRequired,
  onCloseAddTask: PropTypes.func.isRequired,
};

export default function KanbanTaskAdd({ onAddTask, onCloseAddTask }) {
  const [name, setName] = useState('');
  const [completed, setCompleted] = useState(false);
  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker,
  } = useDatePicker();

  const handleKeyUpAddTask = useCallback((event) => {
    if (event.key === 'Enter' && name.trim() !== '') {
      onAddTask({ ...defaultTask, id: uuidv4(), name, completed });
      setName('');
      setCompleted(false);
    }
  }, [name, completed, onAddTask]);

  const handleClickAddTask = useCallback(() => {
    if (name.trim() !== '') {
      onAddTask({ ...defaultTask, id: uuidv4(), name, completed });
      setName('');
      setCompleted(false);
    }
    onCloseAddTask();
  }, [name, completed, onAddTask, onCloseAddTask]);

  const handleChangeCompleted = useCallback((event) => {
    setCompleted(event.target.checked);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAddTask}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <OutlinedInput
          fullWidth
          multiline
          size="small"
          placeholder="Nom de l'objectif"
          value={name}
          onChange={(event) => setName(event.target.value)}
          onKeyUp={handleKeyUpAddTask}
          sx={{
            '& input': { p: 0 },
            '& fieldset': { borderColor: 'transparent !important' },
          }}
        />

        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Tooltip title="Marquer la tâche comme terminée">
            <Checkbox
              disableRipple
              checked={completed}
              onChange={handleChangeCompleted}
              icon={<Iconify icon={'eva:radio-button-off-outline'} />}
              checkedIcon={<Iconify icon={'eva:checkmark-circle-2-outline'} />}
            />
          </Tooltip>

          <Stack direction="row" spacing={1.5} alignItems="center">
            <Tooltip title="Assigner directement">
              <IconButton size="small">
                <Iconify icon={'eva:people-fill'} width={20} height={20} />
              </IconButton>
            </Tooltip>

            {startTime && endTime ? (
              <DisplayTime
                startTime={startTime}
                endTime={endTime}
                isSameDays={isSameDays}
                isSameMonths={isSameMonths}
                onOpenPicker={onOpenPicker}
              />
            ) : (
              <Tooltip title="Date limite">
                <IconButton size="small" onClick={onOpenPicker}>
                  <Iconify icon={'eva:calendar-fill'} width={20} height={20} />
                </IconButton>
              </Tooltip>
            )}

            <MobileDateRangePicker
              open={openPicker}
              onClose={onClosePicker}
              onOpen={onOpenPicker}
              value={dueDate}
              onChange={onChangeDueDate}
              renderInput={() => {}}
            />
          </Stack>
        </Stack>
      </Paper>
    </ClickAwayListener>
  );
}

function useDatePicker() {
  const [dueDate, setDueDate] = useState([null, null]);
  const [openPicker, setOpenPicker] = useState(false);

  const startTime = dueDate[0] || '';
  const endTime = dueDate[1] || '';

  const isSameDays = startTime && endTime && isSameDay(new Date(startTime), new Date(endTime));
  const isSameMonths = startTime && endTime && isSameMonth(new Date(startTime), new Date(endTime));

  const handleChangeDueDate = useCallback((newValue) => {
    setDueDate(newValue);
  }, []);

  const handleOpenPicker = useCallback(() => {
    setOpenPicker(true);
  }, []);

  const handleClosePicker = useCallback(() => {
    setOpenPicker(false);
  }, []);

  return {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate: handleChangeDueDate,
    openPicker,
    onOpenPicker: handleOpenPicker,
    onClosePicker: handleClosePicker,
  };
}

DisplayTime.propTypes = {
  isSameDays: PropTypes.bool,
  isSameMonths: PropTypes.bool,
  onOpenPicker: PropTypes.func,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  sx: PropTypes.object,
};

function DisplayTime({ startTime, endTime, isSameDays, isSameMonths, onOpenPicker, sx }) {
  const style = {
    typography: 'caption',
    cursor: 'pointer',
    '&:hover': { opacity: 0.72 },
  };

  if (!startTime || !endTime) return null;

  if (isSameMonths) {
    return (
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
        {isSameDays
          ? format(new Date(endTime), 'dd MMM')
          : `${format(new Date(startTime), 'dd')} - ${format(new Date(endTime), 'dd MMM')}`}
      </Box>
    );
  }

  return (
    <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
      {format(new Date(startTime), 'dd MMM')} - {format(new Date(endTime), 'dd MMM')}
    </Box>
  );
}
