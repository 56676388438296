import axios from 'axios';
import { loginFailure, loginStart, loginSuccess } from './authReducer';
import { BASE_URL } from "../utils/axios";

export const loginGoogle = ( {email, name, imageUrl} ) => async (dispatch) => {
  try {
    dispatch(loginStart());

    const response = await axios.post(`${BASE_URL}auth/login-google`, {
      email, name, imageUrl 
    });

    

    // Save user data to LocalStorage
    localStorage.setItem('user', JSON.stringify(await response.data));

    // Dispatch loginSuccess action with response data
    dispatch(loginSuccess(response.data));

  } catch (error) {
    console.log(error);
    dispatch(loginFailure(error?.response?.data?.message !== undefined ? error.response.data.message : "Verifiez votre internet!"));
  }
};
