import Groq from "groq-sdk";

import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import Quiz from 'react-quiz-component';
import { enqueueSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';

// @mui
import { Container, Box, Stack, Button, Typography, styled, Card, CircularProgress } from '@mui/material';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { fPercent } from '../utils/formatNumber';
import { store } from '../redux/Store';
import { fetchEvaluation } from '../redux/evaluationReducer';
import { fetchCoaching } from '../redux/coachingReducer';
import { createAnswers, fetchAnswers } from '../redux/answerReducer';
import { createGoal } from '../redux/goalReducer';
import { createAction } from '../redux/actionReducer';

export default function EvaluationPage() {
  const dispatch = useDispatch();

  const groq = new Groq({
    apiKey: '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',
    dangerouslyAllowBrowser: true
  });

  const getGroqChatCompletion = async (message) => {
    try {
      const response = await groq.chat.completions.create({
        messages: [
          {
            role: "user",
            content: message,
          },
        ],
        model: "llama3-8b-8192",
      });
      // console.log('Groq API response:', response);
      return response;
    } catch (error) {
      console.error('Error in getGroqChatCompletion:', error);
      throw error;
    }
  };
  const query = async (data) => {
    const response = await fetch(
      "https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-dev",
      {
        headers: {
          Authorization: "Bearer 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",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );

    // console.log("Content-Type :", response.headers.get("Content-Type")); // Check the response content type

    const result = await response.blob();
    return result;
  };

  const [quizState, setQuizState] = useState(0);
  const RowStyle = styled('div')({
    display: 'flex',
    marginLeft: 200,
    justifyContent: 'space-between'
  });

  //  react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchEvaluation());
    store.dispatch(fetchCoaching());
    store.dispatch(fetchAnswers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { coachingList } = useSelector((state) => state.coaching);
  const { evaluationList, isLoadingEvaluation, isLoadingCreateAnswer } = useSelector((state) => state.evaluation);
  const { user } = useSelector((state) => state.auth.user?.user);
  const { userList } = useSelector((state) => state.listUser);
  const { answerList } = useSelector((state) => state.answer);
  const [isLoading, setIsLoading] = useState(false);
  console.log("isLoading", isLoading);

  const hasCoach = () => {
    let val = null;
    coachingList?.forEach(element => {
      if (element.coache.includes(user.userId)) {

        val = userList.find(u => u?._id === element.coach)
      }
    });
    // console.log("val", val);
    return val;
  }

  const checkResult = (res) => {
    const analyse = res.questions.filter((val) => val.type === "Analyse").length;
    const outils = res.questions.filter((val) => val.type === "Outils").length;
    const exe = res.questions.filter((val) => val.type === "Exécution").length;
    const dura = res.questions.filter((val) => val.type === "Durabilité").length;

    let analyseWrongAnswer = 0;
    let outilsWrongAnswer = 0;
    let exeWrongAnswer = 0;
    let duraWrongAnswer = 0;

    res.questions.filter((val) => val.type === "Analyse").forEach(an_ => {
      if (res.userInput[an_.questionIndex - 1] === 1) {
        analyseWrongAnswer += 1
      }
    })

    res.questions.filter((val) => val.type === "Outils").forEach(an_ => {
      if (res.userInput[an_.questionIndex - 1] === 1) {
        outilsWrongAnswer += 1
      }
    })

    res.questions.filter((val) => val.type === "Exécution").forEach(an_ => {
      if (res.userInput[an_.questionIndex - 1] === 1) {
        exeWrongAnswer += 1
      }
    })

    res.questions.filter((val) => val.type === "Durabilité").forEach(an_ => {
      if (res.userInput[an_.questionIndex - 1] === 1) {
        duraWrongAnswer += 1
      }
    })

    return {
      "analyseWrongAnswer": (analyseWrongAnswer * 100) / analyse,
      "outilsWrongAnswer": (outilsWrongAnswer * 100) / outils,
      "exeWrongAnswer": (exeWrongAnswer * 100) / exe,
      "duraWrongAnswer": (duraWrongAnswer * 100) / dura,
    }
  }

  const renderCustomResultPage = (obj) => {
    // console.log("checkResult",checkResult(obj));
    const score = (obj.numberOfCorrectAnswers * 100) / obj.numberOfQuestions
    return (
      <Card sx={{ p: 3 }}>
        <Typography variant="subtitle2" gutterBottom>
          {
            quizState === 1 ? "Evaluation - Produit/Service" :
              quizState === 3 ? "Evaluation -  Marketing et Ventes" :
                quizState === 2 ? "Evaluation -  Finance" :
                  quizState === 4 ? "Evaluation -  Ressources Humaines" :
                    quizState === 5 ? "Evaluation -  Planification" :
                      quizState === 6 ? "Evaluation -  Logistique" : null

          }
        </Typography>

        <Stack spacing={2}>
          <Typography variant="h3">Score : {fPercent(score)}</Typography>
          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Capacité à comprendre, décomposer et interpréter des informations. (Analyse)
            </Typography>
            <Typography variant="subtitle1"> {fPercent(checkResult(obj).analyseWrongAnswer)}</Typography>
          </RowStyle>

          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Maîtrise des outils et des techniques spécifiques à chaque domaine. (Outils)
            </Typography>
            <Typography variant="subtitle1"> {fPercent(checkResult(obj).outilsWrongAnswer)}</Typography>
          </RowStyle>

          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Aptitude à mettre en œuvre les décisions et à atteindre les objectifs. (Exécution)
            </Typography>
            <Typography variant="subtitle1">{fPercent(checkResult(obj).exeWrongAnswer)}</Typography>
          </RowStyle>

          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Vision à long terme et capacité à maintenir les performances dans le temps. (Durabilité)
            </Typography>
            <Typography variant="subtitle1">{fPercent(checkResult(obj).duraWrongAnswer)}</Typography>
          </RowStyle>

          <Stack direction="row" spacing={1.5}>
            <Button fullWidth variant="contained" color="warning" onClick={() => setQuizState(0)}>
              Retourner
            </Button>
            <LoadingButton disabled={isLoadingCreateAnswer || isLoading} loading={isLoadingCreateAnswer || isLoading} fullWidth variant="contained" onClick={() => handlerSubmitQuiz(obj)}>
              Soumettre
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
    )
  };


  function transformQuestions(obje) {
    // Initialize an object to hold the transformed questions grouped by questionHeader
    const transformedByHeader = {};

    // Loop through each evaluation
    obje.forEach(evaluation => {
      // Initialize an array to hold the questions for the current questionHeader
      if (!transformedByHeader[evaluation.questionHeader]) {
        transformedByHeader[evaluation.questionHeader] = [];
      }

      // Loop through each facteur in the current evaluation
      evaluation.facteur.forEach(facteur => {
        // Loop through each question in the current facteur
        facteur.questions.forEach(async question => {
          // Initialize a new question object with the required fields
          const newQuestion = {
            _id: question?._id,
            question: question.question,
            questionType: "text",
            answerSelectionType: "single",
            answers: ["Vrai", "Faux"], // Assuming these are the possible answers
            correctAnswer: "1", // Assuming "1" represents the correct answer index
            point: "10", // Assuming a default point value of "10"
            type: facteur.nomFacteur, // Set the type to the nomFacteur
            recommandations: question.recommandations, // Copy recommendations from the original question
          };

          // Mix in the evaluations, if they exist
          if (question.evaluations && question.evaluations.length > 0) {
            newQuestion.evaluations = question.evaluations.map(evaluation => ({
              status_by_coach: evaluation.status_by_coach,
              coach: evaluation.coach,
              coachee: evaluation.coachee,
              score_by_coach: evaluation.score_by_coach,
              _id: evaluation?._id,
              date: evaluation.date,
            }));
          }

          // Add the new question object to the array corresponding to the current questionHeader
          transformedByHeader[evaluation.questionHeader].push(newQuestion);
        });
      });
    });

    // Return the object with questionHeaders as keys and arrays of questions as values
    return transformedByHeader;
  }

  const handlerSubmitQuiz = async (ans) => {
    try {
      setIsLoading(true);
      // Ensure evaluationList[quizState - 1] exists
      if (!evaluationList[quizState - 1]) {
        setIsLoading(false);
        throw new Error(`No evaluation found for quizState ${quizState}`);
      }

      const evaluationId = evaluationList[quizState - 1]?._id;
      const coachId = hasCoach()?._id;
      const coacheeId = user.userId;

      // Calculate the overall score and determine the overall status
      let totalScore = 0;
      const chatCompletionsByFactor = {};

      const answers = await Promise.all(ans.questions.map(async (val, index) => {
        if (!val.type) {
          setIsLoading(false);
          console.warn(`Question at index ${index} is missing 'type' property`);
          return null;
        }

        const score = ans.userInput[parseInt(val.questionIndex, 10) - 1] || 0;
        totalScore += score;

        const myImageUrl = '';
        if (score === 2) {
          try {
            const chatCompletion = await getGroqChatCompletion(`
              Voici les détails d'une entreprise : ${user.detailsOfProject}.
              Cette entreprise répond : "Faux" à cette question: ${val.question}.
              Génère une liste de 10 à 15 recommandations précises au format JSON en francais et ne mets pas de commentaires. 
              Juste une liste de recommandations sous ce format : 
              [
                {
                  "Recommandation 1"
                },
                {
                  "Recommandation 2"
                }
              ]
                les recomandations doivent être spécifiques à cette entreprise et son domaine d'activité: ${user.detailsOfProject}.

            Les recommandations doivent être concrètes et applicables. À l'avenir, 
            les entreprises pourront fournir des preuves et des captures d'écran 
            pour vérifier si elles ont mis en œuvre les recommandations.
               ` );
            const ext = chatCompletion.choices[0]?.message?.content || "";
            // console.log("val.question:", val.question);
            // console.log("chatCompletion:", ext);
            // console.log("user:", user);

            if (!chatCompletionsByFactor[val.type]) {
              chatCompletionsByFactor[val.type] = [];
            }
            chatCompletionsByFactor[val.type].push(ext);
          } catch (error) {
            enqueueSnackbar(`Erreur de l'IA ${error}`, { variant: 'error' });

            console.error(`Error generating chat completion for ${val.type}:`, error);
          }
        }

        return {
          idQuestion: val?._id,
          score_by_coach: 0,
          answer: score,
          status_by_coach: "En attente", // Default status
          comment_by_coach: "", // You might want to add individual comments later
          myImageUrl, // Add the generated image URL
        };
      }));

      const validAnswers = answers.filter(answer => answer !== null);
      const averageScore = validAnswers.length > 0 ? totalScore / validAnswers.length : 0;

      // Prepare the answer object
      const answerData = {
        level: "1", // You might want to determine this based on some logic
        coach: coachId,
        coachee: coacheeId,
        date: new Date().toISOString(),
        score_by_coach: averageScore,
        status_by_coach: "En attente", // You might want to determine this based on the average score
        evaluation: evaluationId,
        answers: validAnswers,
      };

      // Send the answer data in a single request
      console.log("answerData", answerData);
      const response = await dispatch(createAnswers(answerData));
      setIsLoading(false);
      if (createAnswers.fulfilled.match(response)) {
        // console.log('Success:', response.payload);
        enqueueSnackbar('Réponse envoyée avec succès', { variant: 'success' });
        setQuizState(0);
        await dispatch(fetchAnswers());
      } else if (createAnswers.rejected.match(response)) {
        console.error('Error:', response.error);
        if (response.payload && response.payload.errors) {
          response.payload.errors.forEach(error => {
            enqueueSnackbar(error, { variant: 'error' });
          });
        } else {
          console.log('ErrorFinal:', response.error);
          enqueueSnackbar('Erreur lors de la soumission de la réponse', { variant: 'error' });
        }
      }

      // Generate and submit goals for each factor
      const goalPromises = Object.entries(chatCompletionsByFactor).map(async ([factor, completions]) => {
        const combinedCompletions = completions.join(' ');
        const goalResponse = await getGroqChatCompletion(`
          Sur la base de ces recommandations: ${combinedCompletions}.
          Générez un titre d'objectif concis, une description courte, et une priorité pour le facteur: ${factor}.
          Personalisé pour cette entreprise : ${user.detailsOfProject}.
          La priorité doit être choisie parmi "LOW", "MEDIUM", ou "HIGH" en fonction de l'importance et de l'urgence des recommandations.
          Répondez uniquement avec un objet JSON au format suivant, sans aucun commentaire supplémentaire:
          {
            "title": "Titre de l'objectif",
            "description": "Description courte de l'objectif",
            "priority": "LOW|MEDIUM|HIGH"
          }
        `);

        let goalData;
        try {
          goalData = JSON.parse(goalResponse.choices[0]?.message?.content || '{}');
        } catch (error) {
          console.error('Error parsing JSON response:', error);
          goalData = {
            title: `Améliorer ${factor}`,
            description: 'Description non disponible',
            priority: "MEDIUM" // Default priority if parsing fails
          };
        }

        const task = {
          name: goalData.title || `Améliorer ${factor}`,
          description: goalData.description || 'Aucune description fournie',
          idCoach: coachId,
          idCoachee: [coacheeId],
          priority: goalData.priority || "MEDIUM" // Use the priority from the API or default to MEDIUM
        };

        const createdGoal = await dispatch(createGoal({ ...task, "column": 0, role: "COACHE" }));
        console.log("Created goal:", createdGoal);
        console.log("completions", completions);

        // Save all recommendations as actions
        const actionPromises = completions.flatMap(recommendation => {
          let parsedRecommendations;

          try {
            // Remove any leading/trailing whitespace and parse the JSON
            // parsedRecommendations = JSON.parse(recommendation);
            parsedRecommendations = JSON.parse(recommendation);
            console.log("parsedRecommendations", typeof parsedRecommendations, parsedRecommendations);
            if (Array.isArray(parsedRecommendations)) {
              console.log();
            } else {
              console.error("Erreur : la chaîne n'est pas un tableau JSON valide.");
            }

          } catch (error) {
            // console.log("recommendation", typeof recommendation, recommendation);
            console.error('Error parsing recommendation JSON:', error);
            // console.log('Raw recommendation:', recommendation);



            // Attempt to extract recommendations manually
            const manualParse = recommendation.match(/\{([^}]+)\}/g);
            if (manualParse) {
              parsedRecommendations = manualParse.map(item => {
                const [key, value] = item.replace(/[{}]/g, '').split(':');
                return { [key?.trim()]: value?.trim() };
              });
              console.log('Manually parsed recommendations:', parsedRecommendations);
            } else {
              return [];
            }
          }

          console.log("Parsed recommendations:", parsedRecommendations);

          return parsedRecommendations.map(rec => {

            const description = Object.keys(rec)[0];
            console.log("Object.keys(rec)[0];", typeof Object.keys(rec)[0], Object.keys(rec)[0]);
            console.log("Object.values(rec);", typeof Object.values(rec), Object.values(rec));
            console.log("rec;", typeof rec, rec);



            if (typeof description !== 'string' || description?.trim() === '') {
              console.error('Invalid or empty description:', description);
              return null;
            }

            console.log(`Creating action for: "${description?.trim()}"`);

            try {
              const action = dispatch(createAction({
                goalId: createdGoal.payload?.createdData?._id,
                description: description?.trim(),
                status: "PENDING",
                priority: "MEDIUM"
              }));

              console.log('Action created:', action);
              return action;
            } catch (error) {
              console.error('Error creating action:', error);
              return null;
            }
          }).filter(action => action !== null);
        });

        try {
          const results = await Promise.all(actionPromises);
          console.log('All actions created:', results);

          // Check if any actions were actually created
          const createdActions = results.filter(result => result !== null && result !== undefined);
          console.log('Number of actions created:', createdActions.length);

          if (createdActions.length === 0) {
            console.error('No actions were created successfully');
          } else {
            console.log('Actions created successfully:', createdActions);
          }
        } catch (error) {
          console.error('Error in Promise.all:', error);
        }

        return createdGoal;
      });

      await Promise.all(goalPromises);
    } catch (error) {
      console.error(`Erreur lors de la soumission de la réponse:`, error);
      enqueueSnackbar(`Erreur lors de la soumission de la réponse: ${error.message}`, { variant: 'error' });
    }
  };

  const produitService = {
    "quizTitle": "Evaluation - Produit/Service",
    "quizSynopsis": "Pour former la Roue de compétence nous evaluons votre produit ou service pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.",
    "nrOfQuestions": "26",
    "appLocale": {
      "landingHeaderText": "<questionLength> Questions",
      "question": "Question",
      "startQuizBtn": "Commencer l'évaluation",
      "resultFilterAll": "Tous",
      "resultFilterCorrect": "Correctes",
      "resultFilterIncorrect": "Incorrectes",
      "prevQuestionBtn": "Précednat",
      "nextQuestionBtn": "Suivant",
      "resultPageHeaderText": "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions."
    },
    "questions": transformQuestions(evaluationList)["Evaluation - Produit/Service"]
  };

  const MarketingVentes = {
    "quizTitle": "Evaluation - Marketing et ventes",
    "quizSynopsis": "Pour former la Roue de compétence nous evaluons votre manière de gerer le Marketing et Ventes pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.",
    "nrOfQuestions": "26",
    "appLocale": {
      "landingHeaderText": "<questionLength> Questions",
      "question": "Question",
      "startQuizBtn": "Commencer l'évaluation",
      "resultFilterAll": "Tous",
      "resultFilterCorrect": "Correctes",
      "resultFilterIncorrect": "Incorrectes",
      "prevQuestionBtn": "Précednat",
      "nextQuestionBtn": "Suivant",
      "resultPageHeaderText": "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions."
    },
    "questions": transformQuestions(evaluationList)["Evaluation - Marketing et ventes"]
  };

  const finance = {
    "quizTitle": "Evaluation - Finance",
    "quizSynopsis": "Pour former la Roue de compétence nous evaluons votre manière de gerer votre gestion financière pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.",
    "nrOfQuestions": "26",
    "appLocale": {
      "landingHeaderText": "<questionLength> Questions",
      "question": "Question",
      "startQuizBtn": "Commencer l'évaluation",
      "resultFilterAll": "Tous",
      "resultFilterCorrect": "Correctes",
      "resultFilterIncorrect": "Incorrectes",
      "prevQuestionBtn": "Précednat",
      "nextQuestionBtn": "Suivant",
      "resultPageHeaderText": "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions."
    },
    "questions": transformQuestions(evaluationList)["Evaluation - Finance"]

  };

  const rh = {
    "quizTitle": "Evaluation - Ressources Humaines",
    "quizSynopsis": "Pour former la Roue de compétence nous evaluons votre manière de gerer vos ressources Humainess pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.",
    "nrOfQuestions": "26",
    "appLocale": {
      "landingHeaderText": "<questionLength> Questions",
      "question": "Question",
      "startQuizBtn": "Commencer l'évaluation",
      "resultFilterAll": "Tous",
      "resultFilterCorrect": "Correctes",
      "resultFilterIncorrect": "Incorrectes",
      "prevQuestionBtn": "Précednat",
      "nextQuestionBtn": "Suivant",
      "resultPageHeaderText": "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions."
    },
    "questions": transformQuestions(evaluationList)["Evaluation - Ressources Humaines"]
  };

  const Planification = {
    "quizTitle": "Evaluation - Planification",
    "quizSynopsis": "Pour former la Roue de compétence nous evaluons votre manière de gerer vos Planification pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.",
    "nrOfQuestions": "26",
    "appLocale": {
      "landingHeaderText": "<questionLength> Questions",
      "question": "Question",
      "startQuizBtn": "Commencer l'évaluation",
      "resultFilterAll": "Tous",
      "resultFilterCorrect": "Correctes",
      "resultFilterIncorrect": "Incorrectes",
      "prevQuestionBtn": "Précednat",
      "nextQuestionBtn": "Suivant",
      "resultPageHeaderText": "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions."
    },

    "questions": transformQuestions(evaluationList)["Evaluation - Planification"]
  };

  const Logistique = {
    "quizTitle": "Evaluation - Logistique",
    "quizSynopsis": "Pour former la Roue de compétence nous evaluons votre manière de gerer vos Logistique pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.",
    "nrOfQuestions": "26",
    "appLocale": {
      "landingHeaderText": "<questionLength> Questions",
      "question": "Question",
      "startQuizBtn": "Commencer l'évaluation",
      "resultFilterAll": "Tous",
      "resultFilterCorrect": "Correctes",
      "resultFilterIncorrect": "Incorrectes",
      "prevQuestionBtn": "Précednat",
      "nextQuestionBtn": "Suivant",
      "resultPageHeaderText": "You have completed the quiz. You got <correctIndexLength> out of <questionLength> questions."
    },
    "questions": transformQuestions(evaluationList)["Evaluation - Logistique"]
  };

  return (
    <>
      <Helmet>
        <title> Evaluation | Coahing </title>
      </Helmet>

      <Container>
        <Stack direction="row" justifyContent="space-between" >

          <HeaderBreadcrumbs
            heading="Evaluation"
            links={[
              { name: 'Accueil', href: '/dashboard' },
              { name: 'Evaluation', href: '/dashboard/evaluation' },
            ]}
          />
          <Box>
            {quizState === 0 ? null :
              <Button variant="contained" color="error" onClick={() => setQuizState(0)}>
                Annuler
              </Button>}
          </Box>

        </Stack>
        {isLoadingEvaluation ?
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="warning" />
          </Box> :

          <Stack>

            {quizState === 0 ?
              <Stack sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                },
              }}>

                {
                  evaluationList && evaluationList.map((val, key) => {
                    const existingAnswer = answerList.find(answer =>
                      answer.evaluation?._id === val?._id && answer.coachee?._id === user.userId
                    );

                    let score = 0;
                    if (existingAnswer) {
                      const successfulAnswers = existingAnswer.answers.filter(a => a.answer === 1).length;
                      const totalAnswers = existingAnswer.answers.length;
                      score = (successfulAnswers / totalAnswers) * 100;
                    }

                    return (
                      <Card sx={{ p: 3, opacity: 1 }} key={key}>
                        <Typography variant="subtitle2" gutterBottom>
                          {existingAnswer ? "Évaluation complétée" : "Passer une évaluation"}
                        </Typography>

                        <Stack spacing={2}>
                          <Typography variant="h5">{(val.questionHeader).split("Evaluation - ")[1]}</Typography>
                          <RowStyle>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              Pour former la Roue de compétence nous évaluons votre {(val.questionHeader).split("Evaluation - ")[1]} pour chacun des ces quatre facteurs : Analyse, Outils, Exécution et Durabilité.
                            </Typography>
                          </RowStyle>

                          {existingAnswer ? (
                            <Stack direction="row" spacing={1.5} alignItems="center">
                              <Typography variant="body1">Score: {fPercent(score)} </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                (Évaluation déjà complétée)
                              </Typography>
                            </Stack>
                          ) : (
                            <Stack direction="row" spacing={1.5}>
                              <Button
                                fullWidth
                                variant="contained"
                                color="warning"
                                onClick={() => setQuizState(key + 1)}
                              >
                                Commencer
                              </Button>
                            </Stack>
                          )}
                        </Stack>
                      </Card>
                    );
                  })
                }

              </Stack>


              : null}

            {
              // evaluationList && evaluationList.map((val, key) => 

              quizState === 1 ? <Quiz quiz={produitService} showInstantFeedback={false} disableSynopsis={false}
                shuffle showDefaultResult={false} customResultPage={renderCustomResultPage} /> :
                quizState === 3 ? <Quiz quiz={MarketingVentes} showInstantFeedback={false} disableSynopsis={false}
                  shuffle showDefaultResult={false} customResultPage={renderCustomResultPage} /> :
                  quizState === 2 ? <Quiz quiz={finance} showInstantFeedback={false} disableSynopsis={false}
                    shuffle showDefaultResult={false} customResultPage={renderCustomResultPage} /> :
                    quizState === 4 ? <Quiz quiz={rh} showInstantFeedback={false} disableSynopsis={false}
                      shuffle showDefaultResult={false} customResultPage={renderCustomResultPage} /> :
                      quizState === 5 ? <Quiz quiz={Planification} showInstantFeedback={false} disableSynopsis={false}
                        shuffle showDefaultResult={false} customResultPage={renderCustomResultPage} /> :
                        quizState === 6 ? <Quiz quiz={Logistique} showInstantFeedback={false} disableSynopsis={false}
                          shuffle showDefaultResult={false} customResultPage={renderCustomResultPage} /> : null
            }
          </Stack>}
      </Container>
    </>
  );
}