import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";
// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of users. 
// Then we define a new slice called usersSlice with an initial state containing 
// an empty list of users, isLoading flag, and an error message if any.

export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async () => {
    const response = await axios.get(
      `${BASE_URL}auth/user-list`
    );
    return response.data;
  }
);

export const fetchCurrentUser = createAsyncThunk(
  "user/currentUser",
  async (user) => {
    return user
  }
);

export const sendResetPassword = createAsyncThunk(
  "user/resetPassword",
  async ({ userId, newPassword, confirmPassword }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}auth/reset-password-no-token/${userId}`, { newPassword, confirmPassword }
      );
      return response.data;
    } catch (error) {

      throw error?.response?.data?.message !== undefined ? error.response.data.message : "Verifiez votre internet!";
    }
  });

export const sendResetPasswordEmail = createAsyncThunk(
  "user/sendOTP",
  async ({ email, code }) => {
    try {

      const response = await axios.post(
        `${BASE_URL}auth/reset-password-code/`, { email, code }
      );
      // console.log("response.data", response.data);

      return response.data;
    } catch (error) {
      throw error?.response?.data?.message !== undefined ? error.response.data.message : "Verifiez votre internet!";
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    userList: [],
    userList2: [

      {
        "_id": "66341d2c3f7d70212d33545b",
        "name": "Eric3 Stanislas3",
        "email": "eric3@gmail.com",
        "mobile": "+243890000000",
        "mobile_secondaire": "+243890000000",
        "username": "Eric3 Stanislas3",
        "password": "$2b$16$QQLUWKW5x4w4epur0FiEgOcvZGR8GUnqRT.s6sHpPC3KE8SkJ5y8.",
        "sex": "M",
        "role": "COACH",
        "profile_picture": "https://i.pravatar.cc/48?u=41903",
        "status": "DEACTIVATED",
        "bio": "Avec plus de cinq ans d'expérience dans le domaine de la surveillance et de l'évaluation (M&E), je possède une expertise avérée dans l'orientation, la conception et la mise en œuvre de systèmes M&E adaptés à divers contextes, qu'ils soient d'urgence ou de développement. Mon expérience s'étend sur plusieurs secteurs, y compris mais sans s'y limiter, la protection de l'enfance, la sécurité alimentaire, la santé, la nutrition, l'éducation, la gouvernance, l'assainissement et l'hygiène (WASH), la consolidation de l a paix, la cohésion sociale, la lutte contre la violence basée sur le genre, la préparation aux situations d'urgence, la résilience, la microfinance et le logement.",
        "experience": "2 ans",
        "__v": 0
      },
      {
        "_id": "66341d513f7d70212d335461",
        "name": "guy",
        "email": "guy@gmail.com",
        "mobile": "+243123456892",
        "mobile_secondaire": "+243123456892",
        "username": "guy",
        "password": "$2b$16$0wVOam4cWwwHQMynEJYsXuhmr4JK5RCA2mNbHv2Pbt5aayJJBhHKK",
        "sex": "M",
        "role": "COACHE",
        "profile_picture": "https://i.pravatar.cc/48?u=556869",
        "status": "DEACTIVATED",
        "__v": 0
      },
      {
        "_id": "66341dbb3f7d70212d33546b",
        "name": "Guillain Bisimwa",
        "email": "guy2@gmail.com",
        "mobile": "+243890000004",
        "mobile_secondaire": "+243890000004",
        "username": "Guillain Bisimwa",
        "password": "$2b$16$8K.uxkEiPOYWFjLM.6....eGbCmYkoL3TExbR7Fa0Fc.qsCB9gpR6",
        "sex": "M",
        "role": "COACH",
        "profile_picture": "https://i.pravatar.cc/48?u=2890",
        "status": "DEACTIVATED",
        "__v": 0
      },
      {
        "_id": "66341dfb3f7d70212d335471",
        "name": "Nadia Mapenzi",
        "email": "nadia@gmail.com",
        "mobile": "+243123456893",
        "mobile_secondaire": "+243123456893",
        "username": "Nadia Mapenzi",
        "password": "$2b$16$vdVjJWqWk9Hma7mWKm/ui.jUE7xA0OUxyJJg.RMa1SdusX8deQutS",
        "sex": "F",
        "role": "COACHE",
        "profile_picture": "https://i.pravatar.cc/48?u=259914",
        "status": "DEACTIVATED",
        "__v": 0
      },
      {
        "_id": "6654e632ca24f00fb3275d9b",
        "name": "Divine Wany",
        "email": "diva@test.me",
        "mobile": "+243890000111",
        "mobile_secondaire": "+243890000111",
        "username": "Divine Wany",
        "password": "$2b$16$UljIlCzOIFQxZARv9lvGAu572LZhxWGRvOHxlSvBTBZYvCbjoyLiu",
        "sex": "F",
        "role": "COACH",
        "profile_picture": "https://i.pravatar.cc/48?u=477159",
        "status": "DEACTIVATED",
        "__v": 0
      },
      {
        "_id": "66aea992c45d0af60103e0ea",
        "name": "Chris",
        "email": "chris@test.me",
        "mobile": "+243890988767",
        "mobile_secondaire": "+243890988767",
        "username": "Chris",
        "password": "$2b$16$M1z2vDSVnlJK12YT58JX6.Lj/FhkN.vhE8sFC395lhkk00C2s0k0G",
        "sex": "M",
        "role": "COACH",
        "profile_picture": "https://i.pravatar.cc/48?u=297142",
        "status": "DEACTIVATED",
        "__v": 0
      },
      {
        "_id": "66bc7999f8fe0fff86a1edff",
        "name": "Valence NDIBWAMI",
        "email": "val@test.me",
        "mobile": "+243890988769",
        "mobile_secondaire": "+243890988769",
        "username": "val",
        "password": "$2b$16$bANW51y9ql5wzjYgIVSwE.7URP8YWmPgN7GuzKissArNshq4VbBXy",
        "sex": "F",
        "role": "COACHE",
        "profile_picture": "https://i.pravatar.cc/48?u=851407",
        "status": "DEACTIVATED",
        "__v": 0
      }

    ],
    isLoadingUser: false,
    errorUser: null,

    otpStatus: null,
    isLoadingSendOTP: false,
    errorOTP: null,

    ChangePasswordStatus: null,
    isLoadingSendChangePassword: false,
    errorChangePassword: null,

    currentUser: null
  },
  reducers: {},
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchUsers is in a pending, fulfilled, or rejected state. 
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoadingUser = true;
        state.errorUser = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.userList = action.payload;
        state.errorUser = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.errorUser = action.error.message;
      });

    builder
      .addCase(sendResetPasswordEmail.pending, (state) => {
        state.isLoadingSendOTP = true;
        state.errorOTP = null;
      })
      .addCase(sendResetPasswordEmail.fulfilled, (state, action) => {
        state.isLoadingSendOTP = false;
        state.otpStatus = action.payload;
        state.errorOTP = null;
      })
      .addCase(sendResetPasswordEmail.rejected, (state, action) => {
        state.isLoadingSendOTP = false;
        state.errorOTP = action.error.message;
      });

    builder
      .addCase(sendResetPassword.pending, (state) => {
        state.isLoadingSendChangePassword = true;
        state.errorChangePassword = null;
      })
      .addCase(sendResetPassword.fulfilled, (state, action) => {
        state.isLoadingSendChangePassword = false;
        state.ChangePasswordStatus = action.payload;
        state.errorChangePassword = null;
      })
      .addCase(sendResetPassword.rejected, (state, action) => {
        state.isLoadingSendChangePassword = false;
        state.errorChangePassword = action.error.message;
      });


    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.currentUser = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      })
      .addCase(fetchCurrentUser.rejected, (state) => {
        state.currentUser = null;
      });

  },

});

export default usersSlice.reducer;
