import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from "../utils/axios";


// Initial state
const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
};

// Async thunks
export const getEvents = createAsyncThunk('calendar/getEvents', async () => {
  const response = await axios.get(`${BASE_URL}api/session/`);

  return response.data;
});

// Async thunks
export const getEventsByCoachee = createAsyncThunk('calendar/getEvents/coachee', async (id) => {
  const response = await axios.get(`${BASE_URL}api/session/coachee/${id}`);

  return response.data;
});

// Async thunks
export const getEventsByCoach = createAsyncThunk('calendar/getEvents/coach', async (id) => {
  const response = await axios.get(`${BASE_URL}api/session/coach/${id}`);

  return response.data;
});

export const createEvent = createAsyncThunk('calendar/createEvent', async (newEvent) => {
  const response = await axios.post(`${BASE_URL}api/session/`, newEvent);
  return response.data;
});

export const updateEvent = createAsyncThunk('calendar/updateEvent', async ({ eventId, updateEvent }) => {
  console.log("eventId", eventId);
  console.log("updateEvent", updateEvent);
  const response = await axios.put(`${BASE_URL}api/session/${eventId}`, updateEvent);
  return response.data;
});

export const deleteEvent = createAsyncThunk('calendar/deleteEvent', async (eventId) => {
  const response = await axios.delete(`${BASE_URL}api/session/${eventId}`);
  return response.data;
});

// Slice
const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // SELECT EVENT
    selectEvent(state, action) {
      state.isOpenModal = true;
      state.selectedEventId = action.payload;
    },

    // SELECT RANGE
    selectRange(state, action) {
      state.isOpenModal = true;
      state.selectedRange = action.payload;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get events
      .addCase(getEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.events = action.payload;
        state.events = action.payload.map(event => ({
          ...event,
          id: event._id
        }));
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Get events
      .addCase(getEventsByCoachee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEventsByCoachee.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.events = action.payload;
        state.events = action.payload.map(event => ({
          ...event,
          id: event._id
        }));
      })
      .addCase(getEventsByCoachee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Get events
      .addCase(getEventsByCoach.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEventsByCoach.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.events = action.payload;
        state.events = action.payload.map(event => ({
          ...event,
          id: event._id
        }));
      })
      .addCase(getEventsByCoach.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Create event
      .addCase(createEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.events.push(action.payload);
        const event = { ...action.payload, id: action.payload._id };
        state.events.push(event);
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Update event
      .addCase(updateEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedEvent = { ...action.payload.updatedData, id: action.payload.updatedData._id };
        console.log("updatedEvent", updatedEvent);
        state.events = state.events.map((event) =>
          event.id === updatedEvent.id ? updatedEvent : event
        );
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Delete event
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.events = state.events.filter((event) => event.id !== action.payload.deletedData._id);
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent, selectRange } = slice.actions;
