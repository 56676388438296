import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, CircularProgress, IconButton } from '@mui/material';
// components
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
import LightboxModal from '../../../components/LightboxModal';
import { varFade } from '../../../components/animate';
import { updateAction } from '../../../redux/actionReducer';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

KanbanTaskAttachmentsAction.propTypes = {
  attachments: PropTypes.array.isRequired,
  action: PropTypes.object.isRequired,
};

export default function KanbanTaskAttachmentsAction({ attachments, action }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const imagesLightbox = attachments;

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <>
      {attachments?.map((attachment) => (
        <Image
          key={attachment}
          src={attachment}
          onClick={() => handleOpenLightbox(attachment)}
          sx={{
            m: 0.5,
            width: 64,
            height: 64,
            borderRadius: 1,
            cursor: 'pointer',
          }}
        />
      ))}

      <UploadFile action={action} />

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}

// ----------------------------------------------------------------------

UploadFile.propTypes = {
  action: PropTypes.object.isRequired,
};

function UploadFile({ action }) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const [loadPic, setLoadPic] = useState(false);

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {

  //     setFiles(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );

  //     console.log("files", files);
  //     console.log("acceptedFiles", acceptedFiles);

  //   },
  //   [setFiles]
  // );


  const handleUpdateTask = async (image) => {
      dispatch(updateAction({ ...action, "attachements": [...action.attachements, image]}))
      .then((result) => {
        if (updateAction.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (updateAction.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error h:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });
  };

  const handleUploadClickCover = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          // console.log("reader.result", reader.result);

          // Call the onCloudinarySaveCb function to upload the image
          const img = await onCloudinarySaveCover(reader.result);
          // console.log("Img uploaded to Cloudinary:", img);

          setFiles(
            [...files, img]
          );

          await handleUpdateTask(img)


        } catch (error) {
          console.error("Error uploading image to Cloudinary:", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onCloudinarySaveCover = async (base64Img) => {
    let pic = ""
    try {
      setLoadPic(true);

      const apiUrl = 'https://api.cloudinary.com/v1_1/micity/image/upload';
      const data = {
        file: base64Img,
        upload_preset: 'ml_default'
      };

      const response = await fetch(apiUrl, {
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json'
        },
        method: 'POST'
      });

      const responseData = await response.json();

      if (response.ok && responseData.secure_url) {
        setLoadPic(false);
        // console.log('Cover uploaded to Cloudinary:', responseData.secure_url);
        pic = responseData.secure_url;
      } else {
        setLoadPic(false);
        // console.error('Error uploading Cover to Cloudinary:', responseData);
        throw new Error('Error uploading Cover to Cloudinary');
      }
    } catch (error) {
      setLoadPic(false);
      console.error('Error in onCloudinarySaveCb:', error);
      throw error;
    }
    return pic
  };


  const { isDragActive } = useDropzone({
    // onDrop: handleDrop,
  });

  return (
    <>
      {files.map((file) => {
        const { name, preview } = file;
        const key = isString(file) ? file : name;

        return (
          <Box
            key={key}
            {...varFade().inRight}
            sx={{
              p: 0,
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Image
              src={isString(file) ? file : preview}
              sx={{
                height: 1,
                position: 'absolute',
                border: (theme) => `solid 1px ${theme.palette.divider}`,
              }}
            />
            {
              loadPic ? <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box> :
                <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                  <IconButton
                    size="small"
                    onClick={() => handleRemove(file)}
                    sx={{
                      p: '2px',
                      color: 'common.white',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  >
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                </Box>
            }

          </Box>
        );
      })}

      {/* <input
          accept="image/jpeg,image/png,image/tiff,image/webp"
          style={{ display: 'block' }}
          id="contained-button-fileCover"
          type="file"
          onChange={(e) => handleUploadClickCover(e)}
                    /> */}

      <DropZoneStyle
        // {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
        }}
      >
        {/* <input {...getInputProps()} /> */}

        <input
          accept="image/jpeg,image/png,image/tiff,image/webp"
          style={{ display: 'block', opacity: 0, position: "absolute", width: 64, height: 64, zIndex: 999 }}
          id="contained-button-fileCover"
          type="file"
          onChange={(e) => handleUploadClickCover(e)}
        />


        <Iconify icon={'eva:plus-fill'} sx={{ color: 'text.secondary', }} />

      </DropZoneStyle>
    </>
  );
}
