import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// @mui
import { Paper, Stack, Button } from '@mui/material';
// redux

import { deleteColumn, updateColumn } from '../../../redux/kanbanReducer';
// components
import Iconify from '../../../components/Iconify';
//
import KanbanAddTask from './KanbanTaskAdd';
import KanbanTaskCard from './KanbanTaskCard';
import KanbanColumnToolBar from './KanbanColumnToolBar';
import { createGoal, deleteGoal, updateGoal, updateGoalCoach, updateGoalCoachee } from '../../../redux/goalReducer';

// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  column: PropTypes.object,
  // board: PropTypes.object,
  onDeleteT: PropTypes.func,
  index: PropTypes.number,
};

export default function KanbanColumn({ column, onDeleteT, index }) {
  const dispatch = useDispatch();
  const { board, boardDeleted } = useSelector((state) => state.goal);
  const { actionList } = useSelector((state) => state.action);
  const { coachingList } = useSelector((state) => state.coaching);
  const { userList } = useSelector((state) => state.listUser);

  const { user } = useSelector((state) => state.auth?.user?.user);

  const { enqueueSnackbar } = useSnackbar();


  const [open, setOpen] = useState(false);

  const { name, cardIds, id } = column;

  const hasCoach = () => {
    let val = null;
    coachingList?.forEach(element => {
      if (element.coache.includes(user.userId)) {

        val = userList.find(u => u?._id === element.coach)
      }
    });
    // console.log("val", val);
    return val;
  }


  const handleOpenAddTask = () => {
    setOpen((prev) => !prev);
  };

  const handleCloseAddTask = () => {
    setOpen(false);
  };

  const handleDeleteTask = async (cardId) => {
    console.log({ cardId, columnId: id });
   

    const role = user.role;
    const userId = user.userId;
    console.log("&&&&&&&&&&&&",role, userId);

    const response = await dispatch(deleteGoal({cardId, role:user.role, userId: user.userId}));

      if (deleteGoal.fulfilled.match(response)) {
        console.log('Success:', response.payload);
        enqueueSnackbar('Objectif supprimé avec succès', { variant: 'success' });
      } else if (deleteGoal.rejected.match(response)) {
        console.error('Error:', response.error);
        if (response.payload && response.payload.errors) {
          response.payload.errors.forEach(error => {
            enqueueSnackbar(error, { variant: 'error' });
          });
        } else {
          console.log('ErrorFinal:', response.error);
          enqueueSnackbar("Erreur lors de la suppression de l'objectif", { variant: 'error' });
        }
      }

    // Call the onDeleteT function with the boardDeleted parameter
    await onDeleteT(boardDeleted);
    console.log("myeeeeeeeeeeeee", boardDeleted);
  };

  const handleUpdateTask = async (card) => {
    console.log({ ...card, columnId: id });
    if (user.role === "COACH") {
      dispatch(updateGoalCoach({ ...card, "column": parseInt(id, 10), idCoach: user.userId }));

    } else if (user.role === "COACHE") {
      dispatch(updateGoalCoachee({ ...card, "column": parseInt(id, 10), idCoachee: user.userId }));
    }
    else {
      dispatch(updateGoal({ ...card, "column": parseInt(id, 10) }));
    };
  }

  const handleUpdateAction = async (card) => {
    console.log("edit action", { ...card, columnId: id });

  };
  const handleUpdateColumn = async (newName) => {
    try {
      if (newName !== name) {
        dispatch(updateColumn(id, { ...column, name: newName }));
        enqueueSnackbar('Update success!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteColumn = async () => {
    try {
      dispatch(deleteColumn(id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddTask = async (task) => {
    const idCoachee = user.role === "COACHE" ? [user.userId ] : []; 
    const idCoach = user.role === "COACH" ? user.userId : hasCoach()?._id;

    const response = await dispatch(createGoal({ ...task, "column": parseInt(id, 10), idCoachee, idCoach, role:user.role }));
    if (createGoal.fulfilled.match(response)) {
      console.log('Success:', response.payload);
      enqueueSnackbar('Objectif envoyée avec succès', { variant: 'success' });
    } else if (createGoal.rejected.match(response)) {
      console.error('Error:', response.error);
      if (response.payload && response.payload.errors) {
        response.payload.errors.forEach(error => {
          enqueueSnackbar(error, { variant: 'error' });
        });
      } else {
        console.log('ErrorFinal:', response.error);
        enqueueSnackbar("Erreur lors de l'ajout de l'objectif", { variant: 'error' });
      }
    }


    handleCloseAddTask();
  };

  return (
    <Draggable isDragDisabled draggableId={id} index={index}>

      {(provided) => (
        <Paper
          {...provided.draggableProps}
          ref={provided.innerRef}
          variant="outlined"
          sx={{ px: 2, bgcolor: 'rgb(244, 246, 248)' }}
        >
          <Stack spacing={3} {...provided.dragHandleProps}>
            <KanbanColumnToolBar columnName={name} onDelete={handleDeleteColumn} onUpdate={handleUpdateColumn} />

            <Droppable droppableId={id} type="task">
              {(provided) => (
                <Stack ref={provided.innerRef} {...provided.droppableProps} spacing={2} width={280}>
                  {cardIds.map((cardId, index) => (
                    <KanbanTaskCard
                      key={cardId}
                      onDeleteTask={handleDeleteTask}
                      onUpdateTatk={handleUpdateTask}
                      onEditAction={handleUpdateAction}
                      card={board?.cards?.find((val) => val.id === cardId)}
                      actions={actionList.filter((action) => action.goalId === cardId)}
                      index={index}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>

            <Stack spacing={2} sx={{ pb: 3 }}>
              {open && <KanbanAddTask onAddTask={handleAddTask} onCloseAddTask={handleCloseAddTask} />}

              <Button
                fullWidth
                size="large"
                color="inherit"
                startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
                onClick={handleOpenAddTask}
                sx={{ fontSize: 14 }}
              >
                Ajouter un objectif
              </Button>
            </Stack>
          </Stack>
        </Paper>
      )}
    </Draggable>
  );
};

