import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
// @mui
// import { MobileDateRangePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  DialogTitle,
  Dialog,
  DialogActions,
} from '@mui/material';
// hooks
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import { IconButtonAnimate } from '../../../components/animate';
//
import KanbanTaskCommentList from './KanbanTaskCommentList';
import KanbanTaskAttachments from './KanbanTaskAttachments';
import KanbanTaskCommentInput from './KanbanTaskCommentInput';
import Actions from '../../action/Actions';
import { createAction, updateAction } from '../../../redux/actionReducer';

// ----------------------------------------------------------------------

const PRIORITIZES = ['LOW', 'MEDIUM', 'HIGHT'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

KanbanTaskDetails.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
  onUpdateTatk: PropTypes.func,
  myActions: PropTypes.object,
  onDelete: PropTypes.func,
  onCheck: PropTypes.func,
  myActionsChecked: PropTypes.object,
  // onEditAction: PropTypes.func
};

export default function KanbanTaskDetails({ card, isOpen, onClose, onDeleteTask, onUpdateTatk, myActions, myActionsChecked, onDelete, onCheck }) {
  const isDesktop = useResponsive('up', 'sm');

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const [taskCompleted, setTaskCompleted] = useState(card.completed);
  const [prioritize, setPrioritize] = useState(card.priority);

  const { name, description, attachements, chat } = card;
  const [goalName, setGoalName] = useState(name);
  const [goalDesc, setGoalDesc] = useState(description);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [actionEdit, setActionEdit] = useState('');
  const [action, setAction] = useState('');
  const [actions, setActions] = useState(myActions.map((description) => description.description));

  // console.log('actionList', myActions.map((description) => description.description));

  const handleCloseModal = () => {
    setAction('');
    setIsOpenModal(false);
  };

  const handleCloseModalEdit = () => {
    setAction('');
    setIsOpenModalEdit(false);
  };

  const onEditAction = (t) => {
    setIsOpenModalEdit(true)
    setActionEdit(t)
    setAction(t.description)
  }

  const saveAction = () => {
    console.log(actions);

    dispatch(
      createAction({
        coachMood: '',
        status: 'PENDING',
        goalId: card._id,
        // "date_limite": "2024-05-03T00:00:00.000Z",
        priority: 'LOW',
        description: action,
        attachements: [],
      })
    );
    setActions([...actions, action]);
    setAction('');
    setIsOpenModal(false);
  };


  const editAction = async () => {

    dispatch(updateAction(await { ...actionEdit, description: action }))
      .then((result) => {
        if (updateAction.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (updateAction.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });
    // dispatch(fetchGoal());

    setActions([...actions, actionEdit]);
    console.log("Actions", actions);



    // setActions(await actions.map((actionItem) =>
    //     actionItem.id === actionEdit._id ? actionEdit : actionItem
    //   ))
    // console.log("actions",actions);
    // console.log("testttt", actions.map((actionItem) =>
    //   actionItem.id === actionEdit._id ? actionEdit : actionItem
    // ));
    console.log("actionEdit", actionEdit);


    setIsOpenModalEdit(false);
  };


  // const {
  //   dueDate,
  //   startTime,
  //   endTime,
  //   isSameDays,
  //   isSameMonths,
  //   onChangeDueDate,
  //   openPicker,
  //   onOpenPicker,
  //   onClosePicker,
  // } = useDatePicker({
  //   date: due,
  // });

  const handleAttach = () => {
    fileInputRef.current?.click();
  };

  const handleToggleCompleted = () => {
    setTaskCompleted((prev) => !prev);
  };

  const handleChangePrioritize = (event) => {
    setPrioritize(event.target.value);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <Stack p={2.5} direction="row" alignItems="center">
          {!isDesktop && (
            <>
              <Tooltip title="Back">
                <IconButtonAnimate onClick={onClose} sx={{ mr: 1 }}>
                  <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
            </>
          )}

          <Button
            size="small"
            variant="outlined"
            color={taskCompleted ? 'primary' : 'inherit'}
            startIcon={!taskCompleted && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />}
            onClick={handleToggleCompleted}
          >
            {taskCompleted ? 'Fini' : 'En cours'}
          </Button>

          <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
            {/* <Tooltip title="Like this">
              <IconButtonAnimate size="small">
                <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip> */}
            <Button
              onClick={() =>
                onUpdateTatk({ ...card, name: goalName, description: goalDesc, attachements, priority: prioritize })
              }
              variant="contained"
            >
              Mettre à jour
            </Button>

            <>
              <Tooltip title="Attachment">
                <IconButtonAnimate size="small" onClick={handleAttach}>
                  <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
                </IconButtonAnimate>
              </Tooltip>
              <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
            </>

            <Tooltip title="Supprimer un objectif">
              <IconButtonAnimate onClick={onDeleteTask} size="small" sx={{ color: 'red' }}>
                <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>

            {/* <Tooltip title="More actions">
              <IconButtonAnimate size="small">
                <Iconify icon={'eva:more-horizontal-fill'} width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip> */}
          </Stack>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
            <OutlinedInput
              fullWidth
              multiline
              size="small"
              placeholder="Entrer la tache"
              value={goalName}
              onChange={(e) => setGoalName(e.target.value)}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
              }}
            />

            <Stack direction="row" alignItems="center">
              <LabelStyle>Priorité</LabelStyle>
              <TextField
                fullWidth
                select
                size="small"
                value={prioritize}
                onChange={handleChangePrioritize}
                sx={{
                  '& svg': { display: 'none' },
                  '& fieldset': { display: 'none' },
                  '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' },
                }}
              >
                {PRIORITIZES.map((option) => (
                  <MenuItem key={option} value={option} sx={{ mx: 1, my: 0.5, borderRadius: 1 }}>
                    <Box
                      sx={{
                        mr: 1,
                        width: 14,
                        height: 14,
                        borderRadius: 0.5,
                        bgcolor: 'error.main',
                        ...(option === 'LOW' && { bgcolor: 'info.main' }),
                        ...(option === 'MEDIUM' && { bgcolor: 'warning.main' }),
                      }}
                    />
                    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                      {option === 'LOW' ? 'FAIBLE' : option === 'MEDIUM' ? 'MOYEN' : 'ÉLEVÉ'}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>Description</LabelStyle>
              <OutlinedInput
                fullWidth
                multiline
                rows={3}
                size="small"
                placeholder="Entrer la description"
                value={goalDesc}
                onChange={(e) => setGoalDesc(e.target.value)}
                sx={{ typography: 'body2' }}
              />
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Actions</LabelStyle>
              <Stack>
                <Actions
                  actions={myActions}
                  onCheck={onCheck}
                  myActionsChecked={myActionsChecked}
                  // actions={myActions.map((description) => description.description)} 
                  onDelete={onDelete}
                  onEditAction={onEditAction} />

                {/* {assignee?.map((user) => (
                  // <Avatar key={user.id} alt={user.name} src={user.avatar} sx={{ m: 0.5, width: 36, height: 36 }} />

                
                ))} */}
                <Tooltip title="Ajoute une action">
                  <IconButtonAnimate
                    onClick={() => setIsOpenModal(true)}
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  >
                    <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                  </IconButtonAnimate>
                </Tooltip>
              </Stack>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>Pièces jointes</LabelStyle>
              <Stack direction="row" flexWrap="wrap">
                <KanbanTaskAttachments attachments={attachements} goal={card} />
              </Stack>
            </Stack>
          </Stack>

          {chat?.length > 0 && <KanbanTaskCommentList comments={chat} />}
        </Scrollbar>

        <Divider />

        <KanbanTaskCommentInput card={card} />
      </Drawer>
      <Dialog fullWidth open={isOpenModal} onClose={handleCloseModal}>
        <DialogTitle>Ajouter une action</DialogTitle>

        <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
          {/* <Stack direction="row" alignItems="center" spacing={2}>
    <Avatar  sx={{ width: 48, height: 48 }} />
    <div>
      <Typography variant="subtitle2">name</Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
       email
      </Typography>
    </div>
  </Stack> */}

          <TextField
            onChange={(e) => setAction(e.target.value)}
            value={action}
            fullWidth
            multiline
            placeholder="Entrer une action"
          />
        </Stack>
        <DialogActions>
          <Button variant="contained" onClick={saveAction}>
            Confirmer
          </Button>
          <Button onClick={handleCloseModal}>Annuler</Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth open={isOpenModalEdit} onClose={handleCloseModalEdit}>
        <DialogTitle>Modifier une action</DialogTitle>

        <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
          <TextField
            onChange={(e) => setAction(e.target.value)}
            value={action}
            fullWidth
            multiline
            placeholder="Entrer une action"
          />
        </Stack>
        <DialogActions>
          <Button variant="contained" onClick={() => editAction()}>
            Modifier
          </Button>
          <Button onClick={handleCloseModalEdit}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
