import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Typography,
  Card,
  CardHeader,
  Container,
  Grid,
  Stack,
  IconButton,
  Checkbox,
  MenuItem,
  Divider,
  Pagination,
  Box,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  Tooltip,
  OutlinedInput,
  styled,
} from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineSeparator, TimelineConnector } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import Scrollbar from '../../components/Scrollbar';

import Page from '../../components/Page';
import { AppWidget } from '../../sections/@dashboard/app';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import { fetchGoalBuyCoach, fetchGoalBuyCoachee } from '../../redux/goalReducer';
import { fetchAction, updateAction } from '../../redux/actionReducer';
import { IconButtonAnimate } from '../../components/animate';
import KanbanTaskCommentList from '../../sections/@dashboard/kanban/KanbanTaskCommentList';
import KanbanTaskAttachmentsAction from '../../sections/@dashboard/kanban/KanbanTaskAttachmentsAction';
import KanbanTaskCommentInputAction from '../../sections/@dashboard/kanban/KanbanTaskCommentInputAction';
import Action from '../../components/action/Action';
import { fPercentZero } from '../../utils/formatNumber';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

const PRIORITIZES = [0, 20, 50, 80, 100];

const ITEMS_PER_PAGE = 2; // Set the number of items per page

export default function ActionSection() {
  const dispatch = useDispatch();

  const { control } = useForm({
    defaultValues: {
      taskCompleted: [],
    },
  });

  const { board } = useSelector((state) => state.goal);
  const { actionList, isLoadingUpdateAction } = useSelector((state) => state.action);
  const { user } = useSelector((state) => state.auth?.user?.user);
  const { currentUser } = useSelector((state) => state.listUser);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [action, setAction] = useState(null);
  const [prioritize, setPrioritize] = useState(0);
  const [newAction, setNewAction] = useState("");
  const [attachements, setAttachements] = useState([]);
  const [chat, setChat] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (user.role === "COACHE" && user?.userId) {
      dispatch(fetchGoalBuyCoachee(user?.userId));
    } else if (user.role === "COACH" && currentUser?._id !== null) {
      dispatch(fetchGoalBuyCoachee(currentUser?._id));
    } else {
      enqueueSnackbar("Veillez choisir un coaché!", { variant: 'error' });
    }
   
  }, [ currentUser]); // Added currentUser as a dependency


  // Create a mapping of actions by goalId
  const actionsByGoalId = actionList.reduce((acc, action) => {
    if (!acc[action.goalId]) {
      acc[action.goalId] = [];
    }
    acc[action.goalId].push(action);
    return acc;
  }, {});

  const countAction = () => {
    let totalActions = 0;
    let totalActionsPending = 0;
    let totalActionsAccepeted = 0;
    let totalGoals = 0;
    let totalGoalsAccepted = 0;
    board?.cards?.forEach((card) => {
      const actionsForCard = actionList.filter((action) => action.goalId === card._id);
      const actionsPending = actionList.filter((action) => action.goalId === card._id && action.status === "PENDING");
      const actionsAccepted = actionList.filter((action) => action.goalId === card._id && action.status === "ACCEPETED");

      totalActions += actionsForCard.length;
      totalActionsPending += actionsPending.length;
      totalActionsAccepeted += actionsAccepted.length;
      totalGoals += 1;
      if (card.status === "ACCEPETED" ) {
        totalGoalsAccepted += 1;
      }
     
    });
    return {totalActions, totalActionsPending, totalActionsAccepeted, totalGoals,totalGoalsAccepted };
  };

  const onEditAction = async (task) => {
    setPrioritize(parseInt(task?.score, 10))
    setNewAction(task?.description);
    setAttachements(task?.attachements)
    setChat(task?.chat)

    setAction(task);
    console.log("on edit ----------", action);
    setIsOpenModal(true)
  }
  const handleCloseModalEdit = () => {
    setAction(null)
    setIsOpenModal(false);
  };

  const onCheck = async (task) => {
    // console.log("onCheck", task);
    dispatch(updateAction({ ...task, status: task.status === "ACCEPETED" ? "PENDING" : "ACCEPETED", _id: task._id }))
      .then((result) => {
        if (updateAction.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (updateAction.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error during matching to coach:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });
  }

  const _analyticOrderTimeline = board.cards.map(goal => ({
    id: goal._id,
    title: goal.name,
    type: goal.priority.toLowerCase(), // Assuming priority is used for type
    time: new Date(goal.createdAt).toLocaleDateString(), // Format the date as needed
    actions: actionsByGoalId[goal._id] || [], // Get actions related to this goal
  }));

  // Calculate the total number of pages
  const totalPages = Math.ceil(_analyticOrderTimeline.length / ITEMS_PER_PAGE);

  // Get the items for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = _analyticOrderTimeline.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChangePrioritize = (event) => {
    setPrioritize(event.target.value);
  };

  const editAction = async (act) => {
    dispatch(updateAction(await { ...act, description: newAction, score: prioritize }))
      .then((result) => {
        if (updateAction.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          handleCloseModalEdit()
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (updateAction.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
          handleCloseModalEdit()
        }
      })
      .catch((error) => {
        // Handle any additional errors
        handleCloseModalEdit()
        console.log(`Error during matching to coach:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });

  }
  const onUpdateMessage = (newChat) => {
    setChat(newChat)
  }


  const dialogContent = (act) => {
    console.log("sct +++++++++++++++", act);

    return <>
      <Stack direction="row" alignItems="center">

        <Button
          size="small"
          variant="outlined"
          color={act?.status === "ACCEPETED" ? 'success' : 'error'}
          startIcon={!act?.status === "ACCEPETED" && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />}
          onClick={() => {

            onCheck({ ...act, status: act?.status === "ACCEPETED" ? "PENDING" : "ACCEPETED" })
            setAction({ ...act, status: act?.status === "ACCEPETED" ? "PENDING" : "ACCEPETED" });
          }}
        >

          {act?.status === "ACCEPETED" ? 'Action complete' : 'Action en cours'}
        </Button>


        <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
          <Tooltip title="Like this">
            <IconButtonAnimate size="small">
              <Iconify icon={'ic:round-thumb-up'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>


          <Tooltip title="Supprimer un objectif">
            <IconButtonAnimate
              // onClick={onDeleteTask} 
              size="small" sx={{ color: 'red' }}>
              <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>

          <Tooltip title="More actions">
            <IconButtonAnimate size="small">
              <Iconify icon={'eva:more-horizontal-fill'} width={20} height={20} />
            </IconButtonAnimate>
          </Tooltip>
        </Stack>
      </Stack>

      <Divider />

     

      <Stack spacing={3} sx={{ py: 3 }}>

        <Stack direction="row" alignItems="center">
          <LabelStyle>Mood du coach</LabelStyle>
          <TextField
            fullWidth
            select
            size="small"
            value={prioritize}
            onChange={handleChangePrioritize}
            sx={{
              '& svg': { display: 'none' },
              '& fieldset': { display: 'none' },
              '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' },
            }}
          >
            {PRIORITIZES.map((option) => (
              <MenuItem key={option} value={option} sx={{ mx: 1, my: 0.5, borderRadius: 1 }}>
                <Box
                  sx={{
                    mr: 1,
                    width: 14,
                    height: 14,
                    borderRadius: 0.5,
                    bgcolor: 'error.main',
                    ...(option === 0 && { bgcolor: 'error.main' }),
                    ...(option === 50 && { bgcolor: 'warning.main' }),
                    ...(option === 80 && { bgcolor: 'warning.main' }),
                    ...(option === 100 && { bgcolor: 'success.main' }),
                  }}
                />
                <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                  {option} %
                </Typography>
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack direction="row">
          <LabelStyle sx={{ mt: 2 }}>Action</LabelStyle>
          <OutlinedInput
            fullWidth
            multiline
            rows={3}
            size="small"
            placeholder="Entrer la description"
            value={newAction}
            onChange={(e) => setNewAction(e.target.value)}
            sx={{ typography: 'body2' }}
          />
        </Stack>

        <Stack direction="row">
          <LabelStyle sx={{ mt: 2 }}>Pièces jointes</LabelStyle>
          <Stack direction="row" flexWrap="wrap">
            <KanbanTaskAttachmentsAction attachments={attachements} action={act} />
          </Stack>
        </Stack>
      </Stack>
      <Scrollbar>

      {act?.chat?.length > 0 && <KanbanTaskCommentList comments={chat} />}
      </Scrollbar>

      <Divider />

      <KanbanTaskCommentInputAction action={act} onUpdateMessage={onUpdateMessage} />
    </>
  }

  // console.log("board.cards", board.cards);


  const AnalyticsOrderTimeline = () => {
    return (
      <Card
        sx={{
          '& .MuiTimelineItem-missingOppositeContent:before': {
            display: 'none',
          },
        }}
      >
        <CardHeader title="Actions" subheader="Voici la liste d'actions auquelles vous devez fournir des preuves et des captures d'écran 
            pour vérifier si elles sont mis en œuvre" />
        <CardContent>
          <Timeline>
            {currentItems.map((item, index) => (
              <OrderItem
                key={item.id}
                item={item}
                isLast={index === currentItems.length - 1}
                control={control}
                onCheck={onCheck}
                isLoadingUpdateAction={isLoadingUpdateAction}
                onEditAction={onEditAction}
              />
            ))}
          </Timeline>

          {/* Pagination Component */}
          <Box sx={{ mb: 5, mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </CardContent>
      </Card>
    );
  }


  return (
    <Page title="ActionSection">
      <Container>
        <HeaderBreadcrumbs
          heading=" "
          links={[{ name: '', href: '' }]}
          action={
            <Button
              disabled
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
            >
              Ajouter une action
            </Button>
          }
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            {
              AnalyticsOrderTimeline()
            }
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Stack spacing={3}>
              {/* <AppWidget 
                title="Objectifs réalisés" 
                total={`${countAction().totalGoalsAccepted} / ${countAction().totalGoals}`}
                icon={'eva:person-fill'} 
                chartData={fPercentZero((countAction().totalGoalsAccepted * 100)/countAction().totalGoals)}
                /> */}
              <AppWidget
                title="Actions en attente"
                total={`${countAction().totalActionsPending} / ${countAction().totalActions}`}
                icon={'eva:email-fill'}
                color="warning"
                chartData={fPercentZero((countAction().totalActionsPending * 100)/countAction().totalActions)}
              />
              <AppWidget
                title="Actions réalisées"
                total={`${countAction().totalActionsAccepeted} / ${countAction().totalActions}`}
                icon={'eva:email-fill'}
                color="success"
                chartData={fPercentZero((countAction().totalActionsAccepeted * 100)/countAction().totalActions)}
              />
            </Stack>
          </Grid>
        </Grid>

        <Dialog fullWidth open={isOpenModal} onClose={handleCloseModalEdit}>
          <DialogTitle>Modifier une action</DialogTitle>

          <Stack spacing={3} sx={{ px: 3, pb: 0 }}>





            {
              dialogContent(action)
            }




          </Stack>
          <DialogActions>
            <Button variant="contained"
              onClick={() => editAction(action)}
            >
              Modifier
            </Button>
            <Button variant="outlined" color="error" onClick={handleCloseModalEdit}>Annuler</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  onCheck: PropTypes.func,
  isLoadingUpdateAction: PropTypes.bool,
  onEditAction: PropTypes.func,
  item: PropTypes.shape({
    time: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    actions: PropTypes.array, // Add actions prop type
  }),
  control: PropTypes.object.isRequired, // Add control prop type
};

function OrderItem({ item, isLast, control, onCheck, isLoadingUpdateAction, onEditAction }) {
  const { type, title, actions, time } = item; // Destructure actions
  // console.log("type, title,time, actions",type, title, time, actions);


  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (type === 'high' && 'error') ||
            (type === 'hight' && 'error') ||
            (type === 'low' && 'info') ||
            (type === 'medium' && 'warning') ||
            'primary'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent paddingLeft={0}>
        <Typography variant="subtitle2">{title}</Typography>
        <Typography sx={{ opacity: 0.5 }} variant="caption">
          {time}
        </Typography>

        <Controller
          name="taskCompleted"
          control={control}
          render={({ field }) => {
            const onSelected = (task) =>
              field.value.includes(task) ? field.value.filter((value) => value !== task) : [...field.value, task];

            return (
              <>
                {actions?.map((task, ky) => (

                  <TaskItem
                    key={task._id} // Use action ID as key
                    task={`${ky + 1}) ${task.description}`}
                    fullTask={task}
                    checked={task.status === "ACCEPETED"}
                    onChange={() => {
                      field.onChange(onSelected(task.description));
                      onCheck(task)
                      console.log("bree");
                    }}
                    isLoadingUpdateAction={isLoadingUpdateAction}
                    onCheck={onCheck}
                    // onDelete={onDelete}
                    onEditAction={onEditAction}

                  />
                ))}
              </>
            );
          }}
        />
      </TimelineContent>
    </TimelineItem>
  );
}

TaskItem.propTypes = {
  task: PropTypes.string,
  checked: PropTypes.bool,
  isLoadingUpdateAction: PropTypes.bool,
  onChange: PropTypes.func,
  onCheck: PropTypes.func,
  onEditAction: PropTypes.func,
  fullTask: PropTypes.object
};

function TaskItem({ task, fullTask, checked, onChange, isLoadingUpdateAction, onCheck, onEditAction }) {
  return (
    <Stack
      direction="row"
      sx={{
        // px: 2,
        py: 0.75,
        color: 'black',
        ...(checked && {
          color: 'text.disabled',
          opacity: 0.7,
          textDecoration: 'line-through',
        }),
        alignItems:"self-start"
      }}
    >
      {isLoadingUpdateAction ? (
            <CircularProgress size={15} color="warning" />
          ) : (
            <Checkbox checked={checked} onChange={onChange} />
          )}
      <Action action={fullTask} desc={task} />
     
      <MoreMenuButton fullTask={fullTask} onCheck={onCheck} onEditAction={onEditAction} />
    </Stack>
  );
}

// ----------------------------------------------------------------------
MoreMenuButton.propTypes = {
  onDelete: PropTypes.func,
  fullTask: PropTypes.object,
  onCheck: PropTypes.object,
  onEditAction: PropTypes.func,

};

function MoreMenuButton({ onDelete, onCheck, fullTask, onEditAction }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={() => {
          onEditAction(fullTask)
          handleClose()
        }} >
          <Iconify icon={'eva:edit-fill'} sx={{ ...ICON }}
          />
          Visualiser
        </MenuItem>

        <MenuItem onClick={() => {
          handleClose()
          onCheck(fullTask)
        }
        }>
          <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ ...ICON }} />
          Marquer comme complete
        </MenuItem>

        <MenuItem>
          <Iconify icon={'eva:share-fill'} sx={{ ...ICON }} />
          Partager
        </MenuItem>

      </MenuPopover>
    </>
  );
}