import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";
// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of coaching. 
// Then we define a new slice called coachingSlice with an initial state containing 
// an empty list of coaching, isLoading flag, and an error message if any.

export const fetchCoaching = createAsyncThunk(
  "coaching/fetchCoaching",
  async () => {
    const response = await axios.get(
      `${BASE_URL}api/coaching/`
    );
    return response.data;
  }
);

// Create an async thunk to create a new coaching object
export const createCoaching = createAsyncThunk('coaching/create', async (Coaching, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${BASE_URL}api/coaching/`, Coaching);
    return response.data;
  } catch (error) {
    let throwError = ""
    if (error.response) {
      // Server responded with a status other than 2xx
      throwError = rejectWithValue(error.response.data);
    } else if (error.request) {
      // Request was made but no response was received
      console.log('Error Request:', error.request);
      throwError = rejectWithValue('No response received from server');
    } else {
      // Something happened in setting up the request
      console.log('Error Message:', error.message);
      throwError = rejectWithValue(error.message);
    }
    return throwError;
  }
});


// Create an async thunk to update an existing coaching object
export const updateCoaching = createAsyncThunk(
  "coaching/update",
  async ({
    _id,
    autre, status, coach, coache
  }) => {
    console.log("id========", _id);
    const url = `${BASE_URL}api/coaching/${_id}`; // Concatenate ID to the base URL

    const response = await axios.put(url, { // Use PUT request for updating
      autre, status, coach, coache
    });

    console.log("Edit coaching---?????? ok==", response.data);
    return response.data;
  }
);


// Create an async thunk to delete an existing coaching object
export const deleteCoaching = createAsyncThunk(
  "coaching/delete",
  async (_id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}api/coaching/${_id}`);
      return response.data;
    } catch (error) {
      let throwError = "";
      if (error.response) {
        // Server responded with a status other than 2xx
        throwError = rejectWithValue(error.response.data);
      } else if (error.request) {
        // Request was made but no response was received
        console.log('Error Request:', error.request);
        throwError = rejectWithValue('No response received from server');
      } else {
        // Something happened in setting up the request
        console.log('Error Message:', error.message);
        throwError = rejectWithValue(error.message);
      }
      return throwError;
    }
  }
);

const coachingSlice = createSlice({
  name: "coaching",
  initialState: {
    coachingList: [],
  
    isLoadingCoaching: false,
    errorCoaching: null,

    isLoadingCreateCoaching: false,
    errorCreateCoaching: null,

    isLoadingUpdateCoaching: false,
    errorUpdateCoaching: null,

    isLoadingDeleteCoaching: false,
    errorDeleteCoaching: null,

  },
  reducers: {},
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchCoaching is in a pending, fulfilled, or rejected state. 
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoaching.pending, (state) => {
        state.isLoadingCoaching = true;
        state.errorCoaching = null;
      })
      .addCase(fetchCoaching.fulfilled, (state, action) => { // action
        state.isLoadingCoaching = false;
        state.coachingList = action.payload;
        state.errorCoaching = null;
      })
      .addCase(fetchCoaching.rejected, (state, action) => {
        state.isLoadingCoaching = false;
        state.errorCoaching = action.error.message;
      });

    // Handle the createCoaching action
    builder
      .addCase(createCoaching.pending, (state) => {
        state.isLoadingCreateCoaching = true;
      })
      .addCase(createCoaching.fulfilled, (state, action) => {
        state.isLoadingCreateCoaching = false;
        state.coachingList.push(action.payload);
      })
      .addCase(createCoaching.rejected, (state, action) => {
        state.isLoadingCreateCoaching = false;
        state.errorCreateCoaching = action.payload;
      });

    // update
    builder
      .addCase(updateCoaching.pending, (state) => {
        state.isLoadingUpdateCoaching = true;
      })
      .addCase(updateCoaching.fulfilled, (state, action) => {
        state.isLoadingUpdateCoaching = false;
        state.coachingList.push(action.payload);
      })
      .addCase(updateCoaching.rejected, (state, action) => {
        state.isLoadingUpdateCoaching = false;
        state.errorUpdateCoaching = action.error.message;
      });

    // Handle the deleteCoaching action
    builder
      .addCase(deleteCoaching.pending, (state) => {
        state.isLoadingDeleteCoaching = true;
      })
      .addCase(deleteCoaching.fulfilled, (state, action) => {
        state.isLoadingDeleteCoaching = false;
        state.coachingList = state.coachingList.filter(coaching => coaching._id !== action.meta.arg);
      })
      .addCase(deleteCoaching.rejected, (state, action) => {
        state.isLoadingDeleteCoaching = false;
        state.errorDeleteCoaching = action.payload;
      });
  },
});

export default coachingSlice.reducer;
