import { Helmet } from 'react-helmet-async';
import React from 'react'

// @mui
import {
  Container
} from '@mui/material';
import EditProfile from './editProfile';

export default function SettingsPage() {

  return (
    <>
      <Helmet>
        <title> Parametres | Coahing </title>
      </Helmet>

      <Container >

        <EditProfile />


      </Container>

    </>
  );
}
