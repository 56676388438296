import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// import omit from 'lodash/omit';
import { BASE_URL } from "../utils/axios";

// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch
// the list of goal.
// Then we define a new slice called goalSlice with an initial state containing
// an empty list of goal, isLoading flag, and an error message if any.

// Define the initial board state
const initialBoardState = {
  cards: [],
  columns: [
    {
      id: '0',
      name: 'Objectif à réaliser',
      cardIds: [],
    },
    {
      id: '1',
      name: 'Objectif en cours',
      cardIds: [],
    },
    {
      id: '2',
      name: 'Objectifs réalisés',
      cardIds: [],
    },
  ],
  columnOrder: ['0', '1', '2'],
};

export const fetchGoal = createAsyncThunk('goal/fetchGoal', async () => {
  const response = await axios.get(`${BASE_URL}api/goal/`);
  return response.data;
});

export const fetchGoalBuyCoach = createAsyncThunk('goal/fetchGoalBuyCoach', async (id) => {
  const response = await axios.get(`${BASE_URL}api/goal/coach/${id}`);
  return response.data;
});

export const fetchGoalBuyCoachee = createAsyncThunk('goal/fetchGoalBuyCoachee', async (id) => {
  const response = await axios.get(`${BASE_URL}api/goal/coachee/${id}`);
  return response.data;
});

// Create an async thunk to create a new goal object
export const createGoal = createAsyncThunk(
  'goals/createGoal',
  async (goal, { rejectWithValue }) => {
    try {
      console.log("goal", goal);
      const url = `${BASE_URL}api/goal/`;
      const response = await axios.post(url, goal);

      return response.data;
    } catch (error) {
      console.log("Error =========??????", error);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create an async thunk to update an existing goal object
export const updateGoal = createAsyncThunk(
  'goal/update',

  /* eslint-disable camelcase */
  async ({
    _id,
    name,
    coachMood,
    status,
    sessionId,
    cover,
    label,
    date_limite,
    priority,
    description,
    attachements,
    chat,
    percentage,
    column,
  }) => {
    const url = `${BASE_URL}api/goal/${_id}`; // Concatenate ID to the base URL

    const response = await axios.put(url, {
      // Use PUT request for updating
      coachMood,
      name,
      status,
      sessionId,
      cover,
      label,
      date_limite,
      priority,
      description,
      attachements,
      chat,
      percentage,
      column,
    });

    console.log('Edit goal---?????? ok==', response.data);
    return response.data;
  }
);

// Create an async thunk to update an existing goal object
export const updateGoalCoach = createAsyncThunk(
  'goal/updateCoach',

  /* eslint-disable camelcase */
  async ({
    _id,
    name,
    coachMood,
    status,
    sessionId,
    cover,
    label,
    date_limite,
    priority,
    description,
    attachements,
    chat,
    percentage,
    column,
    idCoach
  }) => {
    const url = `${BASE_URL}api/goal/coach/${_id}/${idCoach}`; // Concatenate ID to the base URL

    const response = await axios.put(url, {
      // Use PUT request for updating
      coachMood,
      name,
      status,
      sessionId,
      cover,
      label,
      date_limite,
      priority,
      description,
      attachements,
      chat,
      percentage,
      column
    });

    console.log('Edit goal---?????? ok==', response.data);
    return response.data;
  }
);


// Create an async thunk to update an existing goal object
export const updateGoalCoachee = createAsyncThunk(
  'goal/updateCoachee',

  /* eslint-disable camelcase */
  async ({
    _id,
    name,
    coachMood,
    status,
    sessionId,
    cover,
    label,
    date_limite,
    priority,
    description,
    attachements,
    chat,
    percentage,
    column,
    idCoachee
  }) => {
    const url = `${BASE_URL}api/goal/coachee/${_id}/${idCoachee}`; // Concatenate ID to the base URL

    const response = await axios.put(url, {
      // Use PUT request for updating
      coachMood,
      name,
      status,
      sessionId,
      cover,
      label,
      date_limite,
      priority,
      description,
      attachements,
      chat,
      percentage,
      column
    });

    console.log('Edit goal---?????? ok==', response.data);
    return response.data;
  }
);

// Create an async thunk to update an existing goal object
export const deleteGoal = createAsyncThunk('goal/delete', async ({cardId, role, userId}) => {
  console.log("role, userId", role, userId);
  // Make an API call to delete the goal with a body
  const response = await axios.delete(`${BASE_URL}api/goal/role/${role}/userId/${userId}/id/${cardId}`);

  // Return the response data if needed
  return response.data; // You can return any relevant data from the response
});

const goalSlice = createSlice({
  name: 'goal',
  initialState: {

    goalList: [],
    
    board: initialBoardState,

    boardDeleted: [],

    isLoadingGoal: false,
    errorGoal: null,

    isLoadingCreateGoal: false,
    errorCreateGoal: null,

    isLoadingUpdateGoal: false,
    errorUpdateGoal: null,
    updateGoal: null,

    isLoadingDeleteGoal: false,
    errorDeleteGoal: null,
    deleteGoal: null,
  },
  reducers: {
    deleteAll: (state) => {
      state.goalList = [];
      state.board = initialBoardState;
    },
  },
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchGoal is in a pending, fulfilled, or rejected state.
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoal.pending, (state) => {
        state.isLoadingGoal = true;
        state.errorGoal = null;
      })
      .addCase(fetchGoal.fulfilled, (state, action) => {
        state.goalList = action.payload;
        const goalList = action.payload;

        if (goalList && goalList.length > 0) {
          const updatedColumns = [...state.board.columns];
          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const isCardIdExists = column.cardIds.includes(goal._id);
                if (!isCardIdExists) {
                  column.cardIds.push(goal._id);
                }
              }
            });
          });
          state.board.columns = updatedColumns;
        }
        state.isLoadingGoal = false;
        state.errorGoal = null;
      })
      .addCase(fetchGoal.rejected, (state, action) => {
        state.isLoadingGoal = false;
        state.errorGoal = action.error.message;
      });

    builder
      .addCase(fetchGoalBuyCoach.pending, (state) => {
        state.isLoadingGoal = true;
        state.errorGoal = null;
      })
      .addCase(fetchGoalBuyCoach.fulfilled, (state, action) => {
        state.goalList = action.payload;
        const goalList = action.payload;

        if (goalList && goalList.length > 0) {
          const updatedColumns = [...state.board.columns];
          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const isCardIdExists = column.cardIds.includes(goal._id);
                if (!isCardIdExists) {
                  column.cardIds.push(goal._id);
                }
              }
            });
          });
          state.board.columns = updatedColumns;
        }
        state.isLoadingGoal = false;
        state.errorGoal = null;
      })
      .addCase(fetchGoalBuyCoach.rejected, (state, action) => {
        state.isLoadingGoal = false;
        state.errorGoal = action.error.message;
      });

    builder
      .addCase(fetchGoalBuyCoachee.pending, (state) => {
        state.isLoadingGoal = true;
        state.errorGoal = null;
      })
      .addCase(fetchGoalBuyCoachee.fulfilled, (state, action) => {
        state.goalList = action.payload;
        const goalList = action.payload;

        if (goalList && goalList.length > 0) {
          const updatedColumns = [...state.board.columns];
          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const isCardIdExists = column.cardIds.includes(goal._id);
                if (!isCardIdExists) {
                  column.cardIds.push(goal._id);
                }
              }
            });
          });
          state.board.columns = updatedColumns;
        }
        state.isLoadingGoal = false;
        state.errorGoal = null;
      })
      .addCase(fetchGoalBuyCoachee.rejected, (state, action) => {
        state.isLoadingGoal = false;
        state.errorGoal = action.error.message;
      });
    // Handle the createGoal action
    builder
      .addCase(createGoal.pending, (state) => {
        state.isLoadingCreateGoal = true;
      })
      .addCase(createGoal.fulfilled, (state, action) => {
        state.isLoadingCreateGoal = false;

        state.goalList.push(action.payload);
        console.log("Reducer action.payload", action.payload);
        const returnedValues = action.payload;
        const goalList = returnedValues.allData;

        if (goalList && goalList.length > 0) {
          const updatedColumns = [...state.board.columns];
          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const isCardIdExists = column.cardIds.includes(goal._id);
                if (!isCardIdExists) {
                  column.cardIds.push(goal._id);
                }
              }
            });
          });
          state.board.columns = updatedColumns;
        }
      })
      .addCase(createGoal.rejected, (state, action) => {
        state.isLoadingCreateGoal = false;
        state.errorCreateGoal = action.error.message;
      });

    // update
    builder
      .addCase(updateGoal.pending, (state) => {
        state.isLoadingUpdateGoal = true;
      })
      .addCase(updateGoal.fulfilled, (state, action) => {
        state.isLoadingUpdateGoal = false;

        const returnedValues = action.payload;
        const goalList = returnedValues.allData;

        if (goalList && goalList.length > 0) {
          state.board.columns = initialBoardState.columns;

          const updatedColumns = [...state.board.columns];
          console.log('updatedColumns', updatedColumns);
          console.log('state.board.columns', state.board.columns);
          state.board.cards = [];

          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const updatedColumn = { ...column };

                // OR (Option 2)
                // const updatedColumn = Object.assign({}, column);

                // Check if the card ID already exists in the column's cardIds array
                const isCardIdExists = updatedColumn.cardIds.includes(goal._id);

                if (!isCardIdExists) {
                  // Option 1: Use spread syntax to create a new array
                  updatedColumn.cardIds = [...updatedColumn.cardIds, goal._id];

                  // Option 2: Use concat method to create a new array
                  // updatedColumn.cardIds = updatedColumn.cardIds.concat(goal._id);
                }

                // Replace the original column with the updatedColumn in the updatedColumns array
                const columnIndex = updatedColumns.findIndex((c) => c.id === column.id);
                updatedColumns[columnIndex] = updatedColumn;

              }
            });
          });
          state.board.columns = updatedColumns;
        }

        state.boardDeleted = state.board;
        console.log(' state.boardDeleted ---- ', state.boardDeleted);
      })
      .addCase(updateGoal.rejected, (state, action) => {
        state.isLoadingUpdateGoal = false;
        state.errorUpdateGoal = action.error.message;
      });


    // update
    builder
      .addCase(updateGoalCoach.pending, (state) => {
        state.isLoadingUpdateGoal = true;
      })
      .addCase(updateGoalCoach.fulfilled, (state, action) => {
        state.isLoadingUpdateGoal = false;

        const returnedValues = action.payload;
        const goalList = returnedValues.allData;

        if (goalList && goalList.length > 0) {
          state.board.columns = initialBoardState.columns;

          const updatedColumns = [...state.board.columns];
          console.log('updatedColumns', updatedColumns);
          console.log('state.board.columns', state.board.columns);
          state.board.cards = [];

          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const updatedColumn = { ...column };

                // OR (Option 2)
                // const updatedColumn = Object.assign({}, column);

                // Check if the card ID already exists in the column's cardIds array
                const isCardIdExists = updatedColumn.cardIds.includes(goal._id);

                if (!isCardIdExists) {
                  // Option 1: Use spread syntax to create a new array
                  updatedColumn.cardIds = [...updatedColumn.cardIds, goal._id];

                  // Option 2: Use concat method to create a new array
                  // updatedColumn.cardIds = updatedColumn.cardIds.concat(goal._id);
                }

                // Replace the original column with the updatedColumn in the updatedColumns array
                const columnIndex = updatedColumns.findIndex((c) => c.id === column.id);
                updatedColumns[columnIndex] = updatedColumn;

              }
            });
          });
          state.board.columns = updatedColumns;
        }

        state.boardDeleted = state.board;
        console.log(' state.boardDeleted ---- ', state.boardDeleted);
      })
      .addCase(updateGoalCoach.rejected, (state, action) => {
        state.isLoadingUpdateGoal = false;
        state.errorUpdateGoal = action.error.message;
      });

    // update
    builder
      .addCase(updateGoalCoachee.pending, (state) => {
        state.isLoadingUpdateGoal = true;
      })
      .addCase(updateGoalCoachee.fulfilled, (state, action) => {
        state.isLoadingUpdateGoal = false;

        const returnedValues = action.payload;
        const goalList = returnedValues.allData;

        if (goalList && goalList.length > 0) {
          state.board.columns = initialBoardState.columns;

          const updatedColumns = [...state.board.columns];
          console.log('updatedColumns', updatedColumns);
          console.log('state.board.columns', state.board.columns);
          state.board.cards = [];

          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const updatedColumn = { ...column };

                // OR (Option 2)
                // const updatedColumn = Object.assign({}, column);

                // Check if the card ID already exists in the column's cardIds array
                const isCardIdExists = updatedColumn.cardIds.includes(goal._id);

                if (!isCardIdExists) {
                  // Option 1: Use spread syntax to create a new array
                  updatedColumn.cardIds = [...updatedColumn.cardIds, goal._id];

                  // Option 2: Use concat method to create a new array
                  // updatedColumn.cardIds = updatedColumn.cardIds.concat(goal._id);
                }

                // Replace the original column with the updatedColumn in the updatedColumns array
                const columnIndex = updatedColumns.findIndex((c) => c.id === column.id);
                updatedColumns[columnIndex] = updatedColumn;

              }
            });
          });
          state.board.columns = updatedColumns;
        }

        state.boardDeleted = state.board;
        console.log(' state.boardDeleted ---- ', state.boardDeleted);
      })
      .addCase(updateGoalCoachee.rejected, (state, action) => {
        state.isLoadingUpdateGoal = false;
        state.errorUpdateGoal = action.error.message;
      });

    // Handle the deleteGoal action
    builder
      .addCase(deleteGoal.pending, (state) => {
        state.isLoadingDeleteGoal = true;
      })
      .addCase(deleteGoal.fulfilled, (state, action) => {
        state.isLoadingDeleteGoal = false;
        const returnedValues = action.payload;
        const goalList = returnedValues.allData;

        if (goalList && goalList.length > 0) {
          state.board.columns = initialBoardState.columns;

          const updatedColumns = [...state.board.columns];
          console.log('updatedColumns', updatedColumns);
          console.log('state.board.columns', state.board.columns);
          state.board.cards = [];

          goalList.forEach((goal) => {
            const isCardExists = state.board.cards.some((card) => card.id === goal?._id);
            if (!isCardExists) {
              state.board.cards.push({ ...goal, id: goal?._id });
            }
            updatedColumns.forEach((column) => {
              if (parseInt(goal.column, 10) === parseInt(column.id, 10)) {
                const updatedColumn = { ...column };

                // OR (Option 2)
                // const updatedColumn = Object.assign({}, column);

                // Check if the card ID already exists in the column's cardIds array
                const isCardIdExists = updatedColumn.cardIds.includes(goal._id);

                if (!isCardIdExists) {
                  // Option 1: Use spread syntax to create a new array
                  updatedColumn.cardIds = [...updatedColumn.cardIds, goal._id];

                  // Option 2: Use concat method to create a new array
                  // updatedColumn.cardIds = updatedColumn.cardIds.concat(goal._id);
                }

                // Replace the original column with the updatedColumn in the updatedColumns array
                const columnIndex = updatedColumns.findIndex((c) => c.id === column.id);
                updatedColumns[columnIndex] = updatedColumn;

              }
            });
          });
          state.board.columns = updatedColumns;
        }
      })
      .addCase(deleteGoal.rejected, (state, action) => {
        state.isLoadingDeleteGoal = false;
        state.errorDeleteGoal = action.error.message;
      });
  },
});

export const { deleteAll } = goalSlice.actions;


export default goalSlice.reducer;