import { Helmet } from 'react-helmet-async';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// @mui
import { Container, Box, Typography, CircularProgress, CardContent, styled, Grid, Card } from '@mui/material';
import { UserCard } from '../sections/user/cards';
import { store } from '../redux/Store';
import { fetchUsers } from '../redux/listUserReducer';
import { BookingIllustration } from '../assets/assets';

export default function Coach() {
  const navigate = useNavigate();

  // react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchUsers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userList, isLoadingUser } = useSelector((state) => state.listUser);
  const { user } = useSelector((state) => state.auth?.user?.user);
  const { coachingList, isLoadingCoaching } = useSelector((state) => state.coaching);

  useEffect(() => {
    coachingList.forEach(element => {
      if (element.coache.includes(user.userId)) {

        const coache = [];

        nbrCoachee(element.coach)[1].forEach(element_ => {
          element_.forEach(found => {

            if (userList.find(u => u._id === found)) {
              coache.push(userList.find(u => u._id === found))
            }
          })
        });

        navigate('/dashboard/coacheProfile', {
          replace: true,
          state: { realCoach: true, coache, ...userList.find(coach => coach._id === element.coach), }
        })

      }

    });
  }, [coachingList, navigate, user.userId, userList]);

  const nbrCoachee = (idCoach) => {
    let v = 0;
    const coache = [];
    coachingList.forEach(element => {
      if (element.coach === idCoach) {
        v = element.coache.length
        coache.push(element.coache)
      }
    });
    return [v, coache]
  }

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,

    backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up('md')]: {
      height: '100%',
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));

  return (
    <>
      <Helmet>
        <title> Dashboard | Coahing </title>
      </Helmet>

      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Mon Coach
          </Typography>
        </Box>
        {isLoadingUser || isLoadingCoaching ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="warning" />
          </Box>
        ) : null}

        <Grid item xs={12} sm={12}>
          <RootStyle>
            <CardContent
              sx={{
                p: { md: 0 },
                pl: { md: 5 },
                color: 'grey.800',
              }}
            >
              <Typography gutterBottom variant="h5">
                Choisissez votre coach en entrepreneurial!
              </Typography>
              <Typography variant="body2" sx={{ pb: { xs: 3, xl: 3 }, maxWidth: 680, mx: 'auto' }}>
                Voici nos coachs disponibles  pour booster vos apacité à comprendre, décomposer et interpréter des informations,
                à developer votre aptitude à mettre en œuvre les décisions et à atteindre les objectifs, et en fin
                à vous donner une vision à long terme et la capacité à maintenir les performances dans le temps!
              </Typography>
            </CardContent>

            <BookingIllustration
              sx={{
                // p: 3,
                width: 150,
                margin: { xs: 'auto', md: 'inherit' },
              }}
            />
          </RootStyle>
        </Grid>

        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
          }}
        >
          {
            userList.filter((value) => value.role === 'COACH').map((user_) => {
              const coache = [];

              nbrCoachee(user_._id)[1].forEach(element => {
                element.forEach(found => {

                  if (userList.find(u => u._id === found)) {
                    coache.push(userList.find(u => u._id === found))
                  }
                })
              });

              return <UserCard key={user_._id} user={{ coachee: nbrCoachee(user_._id)[0], coache, ...user_ }} />

            })}
        </Box>
      </Container>
    </>
  );
}
