import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";
// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of attachement. 
// Then we define a new slice called attachementSlice with an initial state containing 
// an empty list of attachement, isLoading flag, and an error message if any.

export const fetchAttachement = createAsyncThunk(
  "attachement/fetchAttachement",
  async () => {
    const response = await axios.get(
      `${BASE_URL}api/attachement/`
    );
    return response.data;
  }
);

// Create an async thunk to create a new attachement object
export const createAttachement = createAsyncThunk('attachement/create', async (attachement) => {
  // console.log("----------------------", attachement);
  try {
    const response = await axios.post(`${BASE_URL}api/attachement/`, attachement);
    return response.data;
  } catch (error) {
    console.log("Error =========??????", error);

    throw error;
  }
});

// Create an async thunk to update an existing attachement object
export const updateAttachement = createAsyncThunk(
  "attachement/update",
  async ({
    _id,
    owner, comment, url
  }) => {
    console.log("id========", _id);
    const baseUrl = `${BASE_URL}api/attachement/${_id}`; // Concatenate ID to the base URL

    const response = await axios.put(baseUrl, { // Use PUT request for updating
      owner, comment, url
    });

    console.log("Edit attachement---?????? ok==", response.data);
    return response.data;
  }
);


const attachementSlice = createSlice({
  name: "attachement",
  initialState: {
    attachementList: [],

    isLoadingAttachement: false,
    errorAttachement: null,

    isLoadingCreateAttachement: false,
    errorCreateAttachement: null,

    isLoadingUpdateAttachement: false,
    errorUpdateAttachement: null,
    updateAttachement: null,

  },
  reducers: {},
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchAttachement is in a pending, fulfilled, or rejected state. 
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttachement.pending, (state) => {
        state.isLoadingAttachement = true;
        state.errorAttachement = null;
      })
      .addCase(fetchAttachement.fulfilled, (state) => { // action
        state.isLoadingAttachement = false;
        state.attachementList = []; // action.payload;
        state.errorAttachement = null;
      })
      .addCase(fetchAttachement.rejected, (state, action) => {
        state.isLoadingAttachement = false;
        state.errorAttachement = action.error.message;
      });

    // Handle the createAttachement action
    builder
      .addCase(createAttachement.pending, (state) => {
        state.isLoadingCreateAttachement = true;
      })
      .addCase(createAttachement.fulfilled, (state, action) => {
        state.isLoadingCreateAttachement = false;
        state.attachementList.push(action.payload);
      })
      .addCase(createAttachement.rejected, (state, action) => {
        state.isLoadingCreateAttachement = false;
        state.errorCreateAttachement = action.error.message;
      });

    // update
    builder
      .addCase(updateAttachement.pending, (state) => {
        state.isLoadingUpdateAttachement = true;
      })
      .addCase(updateAttachement.fulfilled, (state, action) => {
        state.isLoadingUpdateAttachement = false;
        state.attachementList.push(action.payload);
      })
      .addCase(updateAttachement.rejected, (state, action) => {
        state.isLoadingUpdateAttachement = false;
        state.errorUpdateAttachement = action.error.message;
      });

  },
});

export default attachementSlice.reducer;
