import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';

import CardHeader from '../../components/cardHeader';

function Billing() {
	return (
		<Stack spacing={6}>
			<ChangePlanSection />
		</Stack>
	);
}

function ChangePlanSection() {

	return (
		<Card type="section" sx={{padding: 3}}>
			<CardHeader title="Moyen de paiement" subtitle="Vos moyens de paiement" />
			<Stack spacing={3} direction={{ xs: 'column', md: 'row' }}/>
		</Card>
	);
}


export default Billing;
