import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect } from 'react';

import merge from 'lodash/merge';
import { isBefore, addHours } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { gapi } from 'gapi-script'; // Import Google API client
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, Stack, Button, Tooltip, IconButton, DialogActions, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useDispatch } from 'react-redux';

// redux
import { createEvent, updateEvent, deleteEvent } from '../../../redux/calendarReducer';
// components
import Iconify from '../../../components/Iconify';
import { ColorSinglePicker } from '../../../components/color-utils';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  '#00AB55', '#1890FF', '#54D62C', '#FFC107', '#FF4842', '#04297A', '#7A0C2E',
];

const getInitialValues = (event, range) => {
  const _event = {
    title: '',
    description: '',
    textColor: '#1890FF',
    color: '#1890FF',
    allDay: false,
    start: range ? new Date(range.start) : new Date(),
    // Don't include 'end' in the initial values since it's automatically set later
  };

  if (event || range) {
    return merge({}, _event, event);
  }

  return _event;
};

// ----------------------------------------------------------------------

CalendarForm.propTypes = {
  event: PropTypes.object,
  range: PropTypes.object,
  onCancel: PropTypes.func,
  currentUser: PropTypes.object,
  coach: PropTypes.object,
};

export default function CalendarForm({ event, range, onCancel, currentUser, coach, user, coachingList, userList }) {

  const dispatch = useDispatch();


  // Initialize Google API client
// Initialize Google API client
useEffect(() => {
  const initClient = () => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({
        clientId: '767810973898-odns86cfsft07lkums3l8glrm5l221go.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.send',
      }).then(() => {
        gapi.client.load('calendar', 'v3'); // Load the calendar API
      });
    });
  };

  gapi.load('client:auth2', initClient);
}, []);

useEffect(() => {
  fetchCalendarEvents()
}, []);


  const hasCoach = () => {
    let val = null;
    coachingList?.forEach(element => {
      if (element.coache.includes(user.userId)) {

        val = userList.find(u => u?._id === element.coach)
      }
    });
    // console.log("val", val);
    return val;
  }

  const isCreating = Object.keys(event).length === 0;

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Le titre est requis'),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range),
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (data) => {
    try {
      const endDate = addHours(new Date(data.start), 1); // Automatically set 'end' to 1 hour after 'start'
  
      if (event.id) {
        const updatedEvent = {
          ...data,
          start: data.start.toISOString(),
          end: endDate.toISOString(), // Use calculated end date
          idCoach: coach,
          idCoachee: [currentUser._id],
        };
        dispatch(updateEvent({ eventId: event.id, updateEvent: updatedEvent }));
        enqueueSnackbar('Mis à jour avec succès', { variant: 'success' });
      } else {
        const newEvent = {
          ...data,
          start: data.start.toISOString(),
          end: endDate.toISOString(), // Use calculated end date
          idCoach: user.role === "COACH" ? user.userId : hasCoach()?._id,
          idCoachee: user.role === "COACHE" ? [user.userId] : [currentUser?._id],
          colorId: COLOR_OPTIONS.indexOf(data.textColor) + 1, // Set colorId based on selected color

        };
  
        if (!newEvent.idCoach || !newEvent.idCoachee) {
          enqueueSnackbar('Veuillez choisir votre coach ou coaché!', { variant: 'error' });
        } else {
          dispatch(createEvent(newEvent)).then(async (result) => {
            if (createEvent.fulfilled.match(result)) {
              enqueueSnackbar('Session ajoutée avec succès', { variant: 'success' });
             
              // Now create the Google Meet link and send the event via email
              const googleMeetLink = await createGoogleMeetLink(newEvent);
              addCalendarEvent(newEvent, googleMeetLink);
              await sendEventAsEmail(newEvent, googleMeetLink, currentUser.email);
            } else if (createEvent.rejected.match(result)) {
              enqueueSnackbar(result.payload, { variant: 'error' });
            }
          }).catch((error) => {
            enqueueSnackbar(`Une erreur est survenue: ${error}`, { variant: 'error' });
          });
        }
      }
      onCancel();
      reset();
    } catch (error) {
      console.error(error);
    }
  };
  
  // Function to create a Google Calendar event with Google Meet link
  const createGoogleMeetLink = async (event) => {
    try {
      const response = await gapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: {
          summary: event.title,
          description: event.description,
          start: {
            dateTime: event.start,
            timeZone: 'Africa/Kinshasa', // Adjust the timezone to DRC time
          },
          end: {
            dateTime: event.end,
            timeZone: 'Africa/Kinshasa',
          },
          conferenceData: {
            createRequest: {
              requestId: `${Date.now()}-meet`, // Unique request ID to create the meet link
              conferenceSolutionKey: {
                type: 'hangoutsMeet', // Specify Google Meet
              },
            },
          },
          attendees: [
            { email: currentUser.email }, // Add other attendees as needed
          ],
        },
        conferenceDataVersion: 1, // Required for Google Meet creation
      });
  
      const meetLink = response.result.conferenceData?.entryPoints?.find((entry) => entry.entryPointType === 'video')?.uri;
      console.log('Google Meet link created:', meetLink);
      return meetLink;
    } catch (error) {
      console.error('Error creating Google Meet link:', error);
      throw error;
    }
  };
  
  // Function to send the event as an email using Gmail API
  const sendEventAsEmail = async (event, googleMeetLink, recipientEmail) => {
    try {
      const authInstance = gapi.auth2.getAuthInstance();
      const isSignedIn = authInstance.isSignedIn.get();
  
      if (!isSignedIn) {
        await authInstance.signIn();
      }
  
      // Create the email content
      const subject = `Nouveau Coaching: ${event.title}`;
      const body = `
       Prenez un rendez-vous pour le coaching!:
        Titre: ${event.title}
        Description: ${event.description}
        Debut: ${new Date(event.start).toLocaleString()}
        Fin: ${new Date(event.end).toLocaleString()}
        
        Joindre le coaching: ${googleMeetLink}

        wwww.alphanew.coach
        `;
  
      const rawEmail = createEmail(recipientEmail, subject, body);
  
      // Send the email using Gmail API
      await gapi.client.load('gmail', 'v1'); // Load the Gmail API
      
      const response = await gapi.client.gmail.users.messages.send({
        userId: 'me',
        resource: {
          raw: rawEmail,
        },
      });
  
      console.log('Email sent successfully:', response);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  
  // Function to create a base64-encoded email
  const createEmail = (to, subject, body) => {
    const email = [
      `To: ${to}`,
      'Content-Type: text/plain; charset="UTF-8"',
      'MIME-Version: 1.0',
      `Subject: ${subject}`,
      '',
      body,
    ].join('\n');
  
    return btoa(unescape(encodeURIComponent(email))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };
  
  const handleDelete = async () => {
    if (!event.id) return;
    try {
      onCancel();
      dispatch(deleteEvent(event.id));
      enqueueSnackbar('Supprimé avec succès', { variant: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  const values = watch();

  // Function to fetch and display the full calendar events
const fetchCalendarEvents = async () => {
  try {
    const response = await gapi.client.calendar.events.list({
      calendarId: 'primary',
      timeMin: (new Date()).toISOString(), // Fetch events from the current time onwards
      showDeleted: false,
      singleEvents: true,
      maxResults: 10, // Number of events to fetch
      orderBy: 'startTime',
    });

    const events = response.result.items;
    if (events.length > 0) {
      console.log('Upcoming events:', events);
    } else {
      console.log('No upcoming events found.');
    }
  } catch (error) {
    console.error('Error fetching calendar events:', error);
  }
};

// Function to add an event to the user's calendar
const addCalendarEvent = async (eventData, googleMeetLink) => {
  try {
    const response = await gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: {
        summary: eventData.title,
        description: `${eventData.description} ${googleMeetLink}`,
        start: {
          dateTime: eventData.start, // Example: '2024-12-01T10:00:00Z'
          timeZone: 'Africa/Kinshasa', // Set DRC timezone (adjust accordingly)     
        },
        end: {
          dateTime: eventData.end, // Example: '2024-12-01T11:00:00Z'
          timeZone: 'Africa/Kinshasa', // Set DRC timezone (adjust accordingly)
        },
        colorId: eventData.colorId || 1, // Optional color for the event
      },
    });

    console.log('Event created: ', response.result);
    enqueueSnackbar('Event added successfully', { variant: 'success' });
  } catch (error) {
    console.error('Error adding event:', error);
    enqueueSnackbar('Error adding event', { variant: 'error' });
  }
};



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ p: 3 }}>
          <RHFTextField name="title" label="Titre" />
          <RHFTextField name="description" label="Description" multiline rows={2} />

          <Controller
            name="start"
            control={control}
            render={({ field: { onChange, value, ...restField } }) => (
              <MobileDateTimePicker
                label="Date de début"
                value={value ? dayjs(value) : null}
                onChange={(date) => onChange(date ? date.toDate() : null)}
                renderInput={(params) => <TextField {...params} />}
                {...restField}
              />
            )}
          />

          <Controller
            name="textColor"
            control={control}
            render={({ field }) => (
              <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />
            )}
          />
        </Stack>

        <DialogActions>
          {!isCreating && (
            <Tooltip title="Supprimer la session">
              <IconButton onClick={handleDelete}>
                <Iconify icon="eva:trash-2-outline" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Quitter
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Ajouter
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </LocalizationProvider>
  );
}
