import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";

// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of action. 
// Then we define a new slice called actionSlice with an initial state containing 
// an empty list of action, isLoading flag, and an error message if any.

export const fetchAction = createAsyncThunk(
  "action/fetchAction",
  async () => {
    const response = await axios.get(
      `${BASE_URL}api/action/`
    );
    return response.data;
  }
);

// Create an async thunk to create a new action object
export const createAction = createAsyncThunk('action/create', async (action) => {
  // console.log("----------------------", action);
  try {
    const response = await axios.post(`${BASE_URL}api/action/`, action);
    return response.data;
  } catch (error) {
    console.log("Error =========??????", error);

    throw error;
  }
});

// Create an async thunk to update an existing action object
export const updateAction = createAsyncThunk(
  "action/update",
  /* eslint-disable camelcase */
  async ({
    _id,
    coachMood,
    status,
    goalId,
    date_limite,
    priority,
    description,
    attachements,
    score,
    chat
  }, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}api/action/${_id}`; // Concatenate ID to the base URL

      // Use PUT request for updating the action object
      const response = await axios.put(url, {
        coachMood,
        status,
        goalId,
        date_limite,
        priority,
        description,
        attachements,
        score,
        chat
      });

      return response.data; // Return the updated action data
    } catch (error) {
      console.log("Error updating action:", error);
      return rejectWithValue(error.response.data); // Handle error and return rejected value
    }
  }
);

// Create an async thunk to remove an existing action object
export const removeAction = createAsyncThunk(
  "action/remove",

  async (
    _id
  ) => {
    console.log("id========", _id);
    const url = `${BASE_URL}api/action/${_id}`; // Concatenate ID to the base URL

    const response = await axios.delete(url);

    console.log("Delete action---?????? ok==", response.data);
    return response.data;
  }
);


const actionSlice = createSlice({
  name: "action",
  initialState: {
    actionList: [],

    isLoadingAction: false,
    errorAction: null,

    isLoadingCreateAction: false,
    errorCreateAction: null,

    isLoadingUpdateAction: false,
    errorUpdateAction: null,

    isLoadingRemoveAction: false,
    errorRemoveAction: null,

  },
  reducers: {},
  // In the extraReducers field, we define how the state should change when the asynchronous
  // thunk fetchAction is in a pending, fulfilled, or rejected state. 
  extraReducers: (builder) => {
    builder
      .addCase(fetchAction.pending, (state) => {
        state.isLoadingAction = true;
        state.errorAction = null;
      })
      .addCase(fetchAction.fulfilled, (state, action) => { // action
        state.isLoadingAction = false;
        state.actionList = action.payload;
        state.errorAction = null;
      })
      .addCase(fetchAction.rejected, (state, action) => {
        state.isLoadingAction = false;
        state.errorAction = action.error.message;
      });

    // Handle the createAction action
    builder
      .addCase(createAction.pending, (state) => {
        state.isLoadingCreateAction = true;
      })
      .addCase(createAction.fulfilled, (state, action) => {
        state.isLoadingCreateAction = false;
        state.actionList.push(action.payload);
      })
      .addCase(createAction.rejected, (state, action) => {
        state.isLoadingCreateAction = false;
        state.errorCreateAction = action.error.message;
      });

    // update
    builder
      .addCase(updateAction.pending, (state) => {
        state.isLoadingUpdateAction = true;
      })
      .addCase(updateAction.fulfilled, (state, action) => {
        state.isLoadingUpdateAction = false;
        // state.actionList.push(action.payload);
        const updatedAction = action.payload;

        // Update the actionList in state based on the ID
        state.actionList = updatedAction.allActions;
        // state.actionList = state.actionList.map((actionItem) =>
        //   actionItem.id === updatedAction.updatedData._id ? updatedAction.updatedData : actionItem
        // );
      })
      .addCase(updateAction.rejected, (state, action) => {
        state.isLoadingUpdateAction = false;
        state.errorUpdateAction = action.error.message;
      });

    // remove
    builder
      .addCase(removeAction.pending, (state) => {
        state.isLoadingRemoveAction = true;
      })
      .addCase(removeAction.fulfilled, (state, action) => {
        state.isLoadingRemoveAction = false;
        // state.actionList.push(action.payload);
        const deletedData = action.payload;
        console.log(deletedData.deletedData._id);
        const index = state.actionList.findIndex((item) => item._id === deletedData.deletedData._id);
        if (index !== -1) {
          state.actionList.splice(index, 1);
        }
      })
      .addCase(removeAction.rejected, (state, action) => {
        state.isLoadingRemoveAction = false;
        state.errorRemoveAction = action.error.message;
      });

  },
});

export default actionSlice.reducer;
