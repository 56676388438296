import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import registerReducer from './registerReducer';
import listUserReducer from './listUserReducer';
import coachingReducer from './coachingReducer';
import goalReducer from './goalReducer';
import sessionReducer from './sessionReducer';
import actionReducer from './actionReducer';
import attachementReducer from './attachementReducer';
import socialMediaReducer from './socialMediaReducer';
import kanbanReducer from './kanbanReducer';
import calendarReducer from './calendarReducer';
import evaluationReducer from './evaluationReducer';
import answerReducer from './answerReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    register: registerReducer,
    listUser: listUserReducer,
    coaching: coachingReducer,
    goal: goalReducer,
    session: sessionReducer,
    action: actionReducer,
    attachement: attachementReducer,
    social: socialMediaReducer,
    kanban: kanbanReducer,
    calendar: calendarReducer,
    evaluation: evaluationReducer,
    answer: answerReducer
  },
});


// export default { store, dispatch, };
export default store;
