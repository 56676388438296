import axios from 'axios';
import { registerFailure, registerStart, registerSuccess } from './registerReducer';
import { BASE_URL } from "../utils/axios";

export const register = (name, email, phone, sex, password, role, quality,project, detailsOfProject) => async (dispatch) => {
  try {
    dispatch(registerStart());

    const response = await axios.post(`${BASE_URL}auth/signup`, {
      name,
      email,
      mobile: phone,
      username: name,
      sex,
      password,
      role,
      mobile_secondaire: phone,
      qualityCoach: quality,
      project, 
      detailsOfProject
    });

    // Dispatch loginSuccess action with response data
    dispatch(registerSuccess(response.data));

    return response.data;

  } catch (error) {
    console.log("-----====", error);
    dispatch(registerFailure(error?.response?.data?.message !== undefined ? error.response.data.message : "Verifiez votre internet!"));

    // Throw the error to be caught by the promise's catch block
    throw error;
  }
};
