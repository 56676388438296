import React from 'react';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ApiCalendar from 'react-google-calendar-api';
import CardHeader from '../../components/cardHeader';

function GoogleMeet() {


const config = {
  "clientId": "767810973898-odns86cfsft07lkums3l8glrm5l221go.apps.googleusercontent.com",
  "client_id": "767810973898-odns86cfsft07lkums3l8glrm5l221go.apps.googleusercontent.com",
  
  "apiKey": "AIzaSyDnTwGZ3L5bdEbcM0h9MOV2MPe96k4HM4c",
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)

  const handleItemClick = (event, name) => {
    try {

    if (name === 'sign-in') {
      apiCalendar.handleAuthClick();
      console.log('logged in');
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick();
      console.log('logged out');
    }
  }catch(e){
    console.log(e);
  }
  };

  // const getUserInfo = async () => {
  //   try{
  //   if (apiCalendar.sign) {
  //     const response = await apiCalendar.getBasicUserProfile();
  //     console.log(response);
  //   }
  // }catch(e){
  //   console.log(e);
  // }
  // };

  // const listUpcomingEvents = () => {
  //   if (apiCalendar.sign) {
  //     apiCalendar.listUpcomingEvents(10).then(({ result }) => {
  //       console.log('upcoming events', result.items);
  //     });
  //   }
  // };

  // const listAllEvents = () => {
  //   if (apiCalendar.sign) {
  //     apiCalendar.listEvents({
  //       // timeMin: new Date().toISOString(),
  //       // timeMax: new Date().addDays(10).toISOString(),
  //       maxResults: 10,
  //       orderBy: 'updated',
  //     }).then(({ result }) => {
  //       console.log(result.items);
  //     });
  //   }
  // };

  // const updateEvent = () => {
  //   const eId = '7eppmkfbhi4gtvvapv9hvej1lm';
  //   const event = {
  //     summary: 'changed name to meet30june for demo purposes',
  //   };
  //   apiCalendar.updateEvent(event, eId).then((res) => {
  //     console.log(res);
  //   });

  //   apiCalendar.getEvent(eId).then(console.log);
  // };

  // const createEventFromNow = () => {
  //   const eventFromNow = {
  //     summary: 'Poc Dev From Now',
  //     time: 180,
  //   };

  //   apiCalendar.createEventFromNow(eventFromNow)
  //     .then((response) => console.log(response))
  //     .catch((err) => console.log(err));
  // };

  // const createEvent = () => {
  //   const stDate = '2021-07-01T12:00:00+05:30';
  //   const endDate = '2021-07-01T15:00:00+05:30';
  //   const event = {
  //     summary: 'new event created',
  //     description: 'demo of create event function',
  //     start: {
  //       dateTime: stDate,
  //     },
  //     end: {
  //       dateTime: endDate,
  //     },
  //   };

  //   apiCalendar.createEvent(event)
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // };

  return (
    <Card type="section" sx={{ padding: 3 }}>
      <CardHeader title="Integration de google meet" />
      <Stack spacing={6}>
        <Typography>Google Meet</Typography>
        <>
          <Button onClick={(e) => handleItemClick(e, 'sign-in')}>Se connecter</Button>
          {/* <Button onClick={(e) => handleItemClick(e, 'sign-out')}>sign-out</Button> */}
          {/* <Button onClick={() => getUserInfo()}>get user info</Button> */}
          {/* <Button onClick={() => listUpcomingEvents()}>list upcoming events</Button>
          <Button onClick={() => listAllEvents()}>list all events</Button>
          <Button onClick={() => updateEvent()}>update an Event</Button>
          <Button onClick={() => createEventFromNow()}>create an Event from now</Button>
          <Button onClick={() => createEvent()}>create an Event</Button> */}
        </>
      </Stack>
    </Card>
  );
}

export default GoogleMeet;
