// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Accueil',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Mon Coach',
    path: '/dashboard/coach',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Coachés',
    path: '/dashboard/coache',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Facture',
    path: '/dashboard/invoice',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Parametres',
    path: '/dashboard/settings',
    icon: icon('ic_analytics'),
  },
  
];

export default navConfig;
