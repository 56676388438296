import { useSelector, useDispatch } from 'react-redux'; // Assuming you're using Redux
import merge from 'lodash/merge';
import { useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from '../../components/chart';
import { getEventsByCoachee } from '../../redux/calendarReducer';
import { fetchGoalBuyCoach, fetchGoalBuyCoachee } from '../../redux/goalReducer';
import { fetchAction } from '../../redux/actionReducer';
import { fetchCoaching } from '../../redux/coachingReducer';

const CHART_DATA = [
  {
    name: 'Sessions',
    type: 'column',
    data: [],
  },
  {
    name: 'Objectifs',
    type: 'area',
    data: [], // This will be filled dynamically
  },
  {
    name: 'Actions',
    type: 'line',
    data: [], // This will be filled dynamically
  }
];

function HoursSection() {
  const dispatch = useDispatch();

  const { board } = useSelector((state) => state.goal);
  const { actionList } = useSelector((state) => state.action);
  const { events } = useSelector((state) => state.calendar);
  const { user } = useSelector((state) => state.auth?.user?.user);
  const { currentUser } = useSelector((state) => state.listUser);

  useEffect(() => {

    if (user.role === "COACHE" && user?.userId) {
      dispatch(getEventsByCoachee(user?.userId));
      dispatch(fetchGoalBuyCoachee(user.userId));
    } else if (user.role === "COACH" && user?.userId && currentUser) {
      dispatch(getEventsByCoachee(currentUser?._id));
      dispatch(fetchGoalBuyCoachee(currentUser?._id));
    }
    dispatch(fetchAction());
    dispatch(fetchCoaching());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  // Initialize data arrays for 12 months
  const dataCountsObjectifs = Array(12).fill(0);
  const dataCountsActions = Array(12).fill(0);
  const dataCountsSessions = Array(12).fill(0); // Initialize session counts

  // Create a Set of goalIds from the objectifs for quick lookup
  const objectifIds = new Set(board?.cards?.map((objectif) => objectif._id));

  // Count objectives for each month
  board?.cards?.forEach((objectif) => {
    const createdAt = new Date(objectif.createdAt);
    if (createdAt.getFullYear() === 2024) {
      const month = createdAt.getMonth(); // getMonth() returns 0-11
      dataCountsObjectifs[month] += 1;
    }
  });

  // Count actions for each month, filtering by goalId
  actionList.forEach((action) => {
    if (objectifIds.has(action.goalId)) { // Check if action's goalId matches any objectif's ID
      const createdAt = new Date(action.createdAt);
      if (createdAt.getFullYear() === 2024) {
        const month = createdAt.getMonth(); // getMonth() returns 0-11
        dataCountsActions[month] += 1; // Increment the count for the corresponding month
      }
    }
  });

  // Count sessions for each month
  events.forEach((session) => {
    const start = new Date(session.start);
    if (start.getFullYear() === 2024) {
      const month = start.getMonth(); // getMonth() returns 0-11
      dataCountsSessions[month] += 1; // Increment the count for the corresponding month
    }
  });

  // Update the CHART_DATA with the counted objectives, actions, and sessions
  CHART_DATA[1].data = dataCountsObjectifs; // Update Objectifs data
  CHART_DATA[2].data = dataCountsActions; // Update Actions data
  CHART_DATA[0].data = dataCountsSessions; // Update Sessions data

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 2] },
    plotOptions: { bar: { columnWidth: '14%' } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: [
      '01/01/2024',
      '02/01/2024',
      '03/01/2024',
      '04/01/2024',
      '05/01/2024',
      '06/01/2024',
      '07/01/2024',
      '08/01/2024',
      '09/01/2024',
      '10/01/2024',
      '11/01/2024',
      '12/01/2024',
    ],
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Stack spacing={2} height="100%" justifyContent="center">
            <Typography variant="subtitle1" fontSize={65}>
              {events.length}
            </Typography>
            <Typography variant="subtitle1">Total de séances (30 jours)</Typography>
            <Typography variant="body2" color="text.tertiary">
              Vous avez beneficier de {events.length} séances avec votre coach.
            </Typography>
            <Stack direction="row" spacing={3}>
              <Link href="#!" underline="none">
                Voir le details
              </Link>
              <Link href="#!" underline="none">
                Accedez aux parametres
              </Link>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Box sx={{ p: 3, pb: 1 }} dir="ltr">
            <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}

export default HoursSection;
