import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/axios";
// Here, we are using the createAsyncThunk function to create an asynchronous thunk to fetch 
// the list of evaluation. 
// Then we define a new slice called evaluationSlice with an initial state containing 
// an empty list of evaluation, isLoading flag, and an error message if any.

export const fetchEvaluation = createAsyncThunk(
    "evaluation/fetchEvaluation",
    async () => {
        const response = await axios.get(
            `${BASE_URL}api/evaluation/`
        );
        return response.data;
    }
);

// Create an async thunk to create a new answer evaluation object
// POST http://localhost:3000/evaluation/<evaluationId>/facteur/<facteurId>/question/<questionId>/eval
export const createAnswer = createAsyncThunk(
    'evaluation/evalMultiple',
    async ({ evaluations }, { rejectWithValue }) => {
      try {
        const url = `${BASE_URL}api/evaluation/evalmultiple`;
        const response = await axios.post(url, { evaluations });
        return response.data;
      } catch (error) {
        console.log("Error =========??????", error);
        return rejectWithValue(error.response.data);
      }
    }
  );

// Create an async thunk to update an existing evaluation object
// PUT http://localhost:3000/evaluation/<evaluationId>/facteur/<facteurId>/question/<questionId>/eval/<evalId>

export const updateAnswer = createAsyncThunk(
    "evaluation/update",
    async ({
        evaluationId,
        facteurId,
        questionId,
        evalId,
        /* eslint-disable camelcase */

        score_by_coach, status_by_coach, coach, coachee
    }) => {
        const url = `${BASE_URL}api/evaluation/${evaluationId}/facteur/${facteurId}/question/${questionId}/eval/${evalId}`;

        const response = await axios.put(url, { // Use PUT request for updating
            score_by_coach, status_by_coach, coach, coachee
        });

        console.log("Edit evaluation---?????? ok==", response.data);
        return response.data;
    }
);


const evaluationSlice = createSlice({
    name: "evaluation",
    initialState: {
   
        evaluationList:[],
        isLoadingEvaluation: false,
        errorEvaluation: null,

        isLoadingCreateAnswer: false,
        errorCreateAnswer: null,

        isLoadingUpdateAnswer: false,
        errorUpdateAnswer: null,

    },
    reducers: {},
    // In the extraReducers field, we define how the state should change when the asynchronous
    // thunk fetchEvaluation is in a pending, fulfilled, or rejected state. 
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvaluation.pending, (state) => {
                state.isLoadingEvaluation = true;
                state.errorEvaluation = null;
            })
            .addCase(fetchEvaluation.fulfilled, (state, action) => { // action
                state.isLoadingEvaluation = false;
                state.evaluationList = action.payload;
                console.log(action.payload);
                state.errorEvaluation = null;
            })
            .addCase(fetchEvaluation.rejected, (state, action) => {
                state.isLoadingEvaluation = false;
                state.errorEvaluation = action.error.message;
            });

        // Handle the createAnswer action
        builder
            .addCase(createAnswer.pending, (state) => {
                state.isLoadingCreateAnswer = true;
            })
            .addCase(createAnswer.fulfilled, (state) => {
                state.isLoadingCreateAnswer = false;
                // state.evaluationList.push(action.payload);
            })
            .addCase(createAnswer.rejected, (state, action) => {
                state.isLoadingCreateAnswer = false;
                state.errorCreateAnswer = action.error.message;
            });

        // update
        builder
            .addCase(updateAnswer.pending, (state) => {
                state.isLoadingUpdateAnswer = true;
            })
            .addCase(updateAnswer.fulfilled, (state) => {
                state.isLoadingUpdateAnswer = false;
                // state.evaluationList.push(action.payload);
            })
            .addCase(updateAnswer.rejected, (state, action) => {
                state.isLoadingUpdateAnswer = false;
                state.errorUpdateAnswer = action.error.message;
            });
    },
});

export default evaluationSlice.reducer;
