import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalCase } from 'change-case';
import { styled } from '@mui/material/styles';

// @mui
import { Container, Box, Card, Tabs, Tab, Grid, Stack } from '@mui/material';
import { store } from '../redux/Store';
import { fetchUsers } from '../redux/listUserReducer';
import { fetchCoaching } from '../redux/coachingReducer';

import ProfileFriends from '../sections/user/ProfileFriends';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { ProfileAbout, ProfileCover, ProfileSocialInfo } from '../sections/@dashboard/user/profile';
import useTabs from '../hooks/useTabs';
import Iconify from '../components/Iconify';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));


export default function Coache() {
  const { user } = useSelector((state) => state.auth?.user?.user);

  const [findFriends, setFindFriends] = useState('');
  //  react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchUsers());
    store.dispatch(fetchCoaching());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userList, isLoadingUser } = useSelector((state) => state.listUser);
  const { coachingList, isLoadingCoaching } = useSelector((state) => state.coaching);

  
  const coaching = (listCoachs, listUsers) => {
    // Filter coaching entries for the current user (coach)
    const myCoachesTemp = listCoachs.filter(val => val.coach === user.userId);

    // If no coaching entries are found, return an empty array
    if (myCoachesTemp.length === 0) {
      return [];
    }

    // Create a Set to store unique coachee IDs
    const coacheeIds = new Set();

    // Collect all coachee IDs from the filtered coaching entries
    myCoachesTemp.forEach(coachingEntry => {
      coachingEntry.coache.forEach(coacheeId => {
        coacheeIds.add(coacheeId); // Use Set to avoid duplicates
      });
    });

    // Filter the list of users to find coachees based on the collected IDs
    const myCoaches = listUsers.filter(user => coacheeIds.has(user._id));

    return myCoaches; // Return the array of coachee user objects
  };


  const { currentTab, onChangeTab } = useTabs('MES COACHES');
  const handleFindFriends = (value) => {
    setFindFriends(value);
  };

  const PROFILE_TABS = [
    {
      value: 'MES COACHES',
      icon: <Iconify icon={'eva:heart-fill'} width={20} height={20} />,
      component: (
        <ProfileFriends
          friends={coaching(coachingList, userList.filter((value) => value.role === 'COACHE'))}
          findFriends={findFriends}
          onFindFriends={handleFindFriends}
        />
      ),
    },
    {
      value: 'Mon profile',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <ProfileSocialInfo profile={user} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <ProfileAbout profile={user} />
            </Stack>
          </Grid>
        </Grid>
      ),
    },

    {
      value: 'Tous les coaches',
      icon: <Iconify icon={'eva:heart-fill'} width={20} height={20} />,
      component: (
        <ProfileFriends
          friends={userList.filter((value) => value.role === 'COACHE')}
          findFriends={findFriends}
          onFindFriends={handleFindFriends}
        />
      ),
    },

    // {
    //   value: 'Mon Coach',
    //   icon: <Iconify icon={'eva:heart-fill'} width={20} height={20} />,
    //   component: (
    //     <ProfileFriends
    //       friends={userList.filter((value) => value.role === 'COACHE')}
    //       findFriends={findFriends}
    //       onFindFriends={handleFindFriends}
    //     />
    //   ),
    // },
  ];




  return (
    <>
      <Helmet>
        <title> Dashboard | Coacheés </title>
      </Helmet>

      <Container>
        <HeaderBreadcrumbs
          heading="Mes coacheés"
          links={[
            { name: 'Accueil', href: '/dashboard' },
            { name: `${user.name}`, href: '/dashboard' },
          ]}
        />

        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative',
          }}
        >
          <ProfileCover myProfile={user} />

          <TabsWrapperStyle>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}

        {/* 
        {isLoadingUser ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="warning" />
          </Box>
        ) : null}
        <Box>
          <ProfileFriends
            friends={userList.filter((value) => value.role === 'COACHE')}
            findFriends={findFriends}
            onFindFriends={handleFindFriends}
          />
        </Box> */}
      </Container>
    </>
  );
}
