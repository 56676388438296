import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Divider, Button } from '@mui/material';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

ProfileRequestFollow.propTypes = {
  user: PropTypes.object,
  onDemandCoaching: PropTypes.func,
  onBreakCoaching: PropTypes.func,
  isLoadingCreateCoaching: PropTypes.bool
};

export default function ProfileRequestFollow({ user, onDemandCoaching, onBreakCoaching, isLoadingCreateCoaching }) {
  console.log("user", user);
  return (
    <Card sx={{ p: 3 }}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
        {
          !user.realCoach ?
            <Stack width={1} textAlign="center">
              <Button disabled={isLoadingCreateCoaching} onLoad={isLoadingCreateCoaching} onClick={() => onDemandCoaching(user._id)} fullWidth variant="contained" color="info" endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}>
                Demande de coaching
              </Button>
            </Stack> :
            <Stack width={1} textAlign="center">
              <Button onClick={() => onBreakCoaching(user._id)} fullWidth variant="contained" color="error" endIcon={<Iconify icon={'eva:close-circle-fill'} />}>
                Rompre le coaching
              </Button>
            </Stack>
        }
      </Stack>
    </Card>
  );
}
