import axios from 'axios';
import { updateFailure, updateStart, updateSuccess } from './updateUserReducer';
import { BASE_URL } from "../utils/axios";

export const update = (id, name, mobile, sex, province, ville, bio, experience, project, detailsOfProject) => async (dispatch) => {
  try {
    dispatch(updateStart());

    const response = await axios.put(`${BASE_URL}auth/update/${id}`, {
      name,
      mobile,
      sex,
      province,
      ville,
      bio,
      experience,
      project, 
      detailsOfProject
    });

    // Dispatch loginSuccess action with response data
    dispatch(updateSuccess(response.data));

    return response.data;

  } catch (error) {
    console.log("-----====", error);
    dispatch(updateFailure(error?.response?.data?.message !== undefined ? error.response.data.message : "Verifiez votre internet!"));

    // Throw the error to be caught by the promise's catch block
    throw error;
  }
};
