import PropTypes from 'prop-types';
import { useState } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { update } from '../../redux/updateUserAction';
import { login } from '../../redux/loginAction';

import CardHeader from '../../components/cardHeader';

function Account() {
	return (
		<Stack spacing={6}>
			<GeneralSettingsSection />
			<ProfileSettingsSection />
			<AdvancedSettingsSection />
			<DeleteAccountSection />
		</Stack>
	);
}

function GeneralSettingsSection() {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [name, setName] = useState(user?.user?.user.name || '');
	const [email, setEmail] = useState(user?.user?.user.email || '');
	const [mobile, setMobile] = useState(user?.user?.user.mobile || '');
	const [sex, SetSex] = useState(user?.user?.user.sex || '');
	const [province, SetProvince] = useState(user?.user?.user.province || '');
	const [ville, setVille] = useState(user?.user?.user.ville || '');
	const [bio, setBio] = useState(user?.user?.user.bio || '');
	const [experience, setExperience] = useState(user?.user?.user.experience || '');
	const [project, setProject] = useState(user?.user?.user.project || ''); // Add project state
	const [detailsOfProject, setDetailsOfProject] = useState(user?.user?.user.detailsOfProject || ''); // Add detailsOfProject state

	const [errorUpdateUser, seErrorUpdateUser] = useState('');

	const editUser = async () => {
		console.log(name);
		try {
			if (name && mobile && sex && province && ville && bio && experience && project && detailsOfProject) {
				// Dispatch update action here
				dispatch(update(user.user.user.userId, name, mobile, sex, province, ville, bio, experience, project, detailsOfProject))
					.then((data) => {
						console.log('data', data);
						// setLatestCreatedUser(data.userId);
						seErrorUpdateUser('');
						dispatch(login(email, user?.user?.user.password));

						enqueueSnackbar('Mis à jour avec succès', { variant: 'success' });
					})
					.catch((error) => {
						seErrorUpdateUser(`Erreur: ${error}`);

						console.error('Registration error:', error);
						enqueueSnackbar("Une erreur s'est produite", { variant: 'error' });
					});
			} else {
				seErrorUpdateUser("Validez tous les champs");
				enqueueSnackbar('Validez tous les champs', { variant: 'error' });
			}

			console.log();
			// console.log('registeredUser2', await registeredUser);

		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Card type="section" sx={{ padding: 3 }}>
			<CardHeader title="PARAMÈTRES GÉNÉRAUX" />
			<Stack spacing={6}>
				<Stack direction="row" spacing={6} alignItems="center" justifyContent="center">
					<Avatar
						alt="User Img"
						src={user.user.user.profile_picture}
						sx={{
							width: 150,
							height: 150,
							border: 3,
							borderColor: 'primary.light',
							borderStyle: 'dotted',
							boxShadow: (theme) =>
								`0px 0px 0px 4px ${theme.palette.background.paper} ,0px 0px 0px 6px ${theme.palette.primary[300]}`,
						}}
					/>
					<div>
						<Typography variant="caption" display="block">
							Image size Limit should be 125kb Max.
							<ErrorOutlineIcon fontSize="small" />
						</Typography>
						<Button disableElevation size="medium" variant="contained" endIcon={<AccountBoxOutlinedIcon />}>
							Changer l'image
						</Button>
					</div>
				</Stack>
				<Divider />
				<form>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<TextField label="Nom complet" variant="outlined" defaultValue={name}
								onChange={(e) => setName(e.target.value)}
								fullWidth />
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField select fullWidth label="Province" variant="outlined" defaultValue={province} onChange={(e) => SetProvince(e.target.value)}>
								<MenuItem value="nk">Nord-kivu </MenuItem>
								<MenuItem value="sk">Sud-kivu </MenuItem>
								<MenuItem value="kin">KINSHASA</MenuItem>
								<MenuItem value="kc">KONGO CENTRAL</MenuItem>
								<MenuItem value="AUTRE">AUTRE</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField
								type="email"
								label="Email"
								variant="outlined"
								defaultValue={email}
								fullWidth
								disabled
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField label="Téléphone" variant="outlined" defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} fullWidth />
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField select fullWidth label="Sexe" variant="outlined" defaultValue={sex} onChange={(e) => SetSex(e.target.value)} >
								<MenuItem value="M">Masculin </MenuItem>
								<MenuItem value="F">Feminin </MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							{/* Empty Grid item for spacing */}
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<Stack spacing={3}>
								<TextField select fullWidth label="Ville" variant="outlined" defaultValue={ville} onChange={(e) => setVille(e.target.value)}>
									<MenuItem value="GOMA">GOMA </MenuItem>
									<MenuItem value="KINSHASA">KINSHASA </MenuItem>
									<MenuItem value="MATADI">MATADI</MenuItem>
									<MenuItem value="AUTRE">AUTRE</MenuItem>
								</TextField>
								<TextField select fullWidth label="Expérience" variant="outlined" defaultValue={experience} onChange={(e) => setExperience(e.target.value)}>
									<MenuItem value="4">4 années</MenuItem>
									<MenuItem value="5">Plus de 5 années</MenuItem>
								</TextField>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField
								multiline
								minRows={5}
								label="Profil"
								defaultValue={bio}
								fullWidth
								onChange={(e) => setBio(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField
								label="Projet"
								variant="outlined"
								defaultValue={project}
								onChange={(e) => setProject(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<TextField
								label="Détails du projet"
								variant="outlined"
								defaultValue={detailsOfProject}
								onChange={(e) => setDetailsOfProject(e.target.value)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Button
								disableElevation
								variant="contained"
								endIcon={<EditIcon />}
								sx={{
									float: 'right',
								}}
								onClick={() => editUser()}
							>
								Mettre à jour le compte
							</Button>
						</Grid>
						{errorUpdateUser && (
							<Typography variant="body" sx={{ textAlign: 'center', color: 'red', mb: 3 }}>
								{errorUpdateUser}
							</Typography>
						)}
					</Grid>
				</form>
			</Stack>
		</Card>
	);
}

function ProfileSettingsSection() {
	return (
		<Card type="section" sx={{ padding: 3 }}>
			<CardHeader title="PROFIL PUBLIC" />
			<Stack spacing={3}>
				<SettingItem
					title="Rendre les informations de contact publiques"
					subtitle="Cela signifie que toute personne consultant votre profil pourra voir les détails sur vous!"
				/>

				<Divider />
				<SettingItem
					title="Disponible pour le coaching"
					subtitle="En cochant cette case, vous indiquerez à tous les coachés que vous êtes disponible pour les coacher."
					value
				/>
			</Stack>
		</Card>
	);
}

function AdvancedSettingsSection() {
	return (
		<Card type="section" sx={{ padding: 3 }}>
			<CardHeader title="PARAMÈTRES AVANCÉS" />
			<Grid
				container
				spacing={0}
				sx={{
					width: '100%',
					'--Grid-borderWidth': '1px',
					borderTop: 'var(--Grid-borderWidth) solid',
					borderLeft: 'var(--Grid-borderWidth) solid',
					borderColor: 'border',
					'& > div': {
						borderRight: 'var(--Grid-borderWidth) solid',
						borderBottom: 'var(--Grid-borderWidth) solid',
						borderColor: 'border',
						p: 2,
					},
				}}
			>
				<Grid item xs={12} sm={6} md={6}>
					<SettingItem title="Navigation sécurisée" subtitle="Naviguer en toute sécurité ( https ) lorsque c'est nécessaire" />
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<SettingItem title="Notifications de connexion" subtitle="Notifier les tentatives de connexion à partir d'un autre endroit" />
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<SettingItem
						title="Autorisation de connexion"
						subtitle="L'approbation n'est pas nécessaire lorsque l'on se connecte à partir d'un appareil non reconnu."
						value
					/>
				</Grid>
			</Grid>
		</Card>
	);
}

function DeleteAccountSection() {
	return (
		<Stack
			sx={{
				borderRadius: 1,
				border: 2,
				borderColor: 'red',
				borderStyle: 'dotted',
				bgcolor: 'background.paper',
				p: 3,
			}}
		>
			<CardHeader title="SUPPRIMER LE COMPTE" />
			<Typography mb={2}>
				Pour désactiver votre compte, supprimez d'abord ses ressources.
			</Typography>
			<Button variant="outlined" color="error">
				SUPPRIMER LE COMPTE
			</Button>
		</Stack>
	);
}

function SettingItem({ title, subtitle, value }) {
	return (
		<Stack direction="row" justifyContent="space-between">
			<div>
				<Typography variant="h6" gutterBottom>
					{title}
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{subtitle}
				</Typography>
			</div>
			<Switch defaultChecked={value} />
		</Stack>
	);
}

SettingItem.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	value: PropTypes.string,
};

export default Account;
