import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';

import { useState, useRef, useEffect } from 'react';
// @mui
import { Card, Button, Container, DialogTitle, Dialog } from '@mui/material';
// redux
// import { useDispatch, useSelector } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';


import {openModal, closeModal, updateEvent, selectEvent, selectRange, getEventsByCoachee } from '../../redux/calendarReducer';
// routes

// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
// import { DialogAnimate } from '../../components/animate';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { CalendarForm, CalendarStyle, CalendarToolbar } from '../../sections/@dashboard/calendar';

// ----------------------------------------------------------------------

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;
  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }
  return null;
};

export default function SessionSection() {
  const { currentUser } = useSelector((state) => state.listUser);
  const { user } = useSelector((state) => state.auth?.user?.user);
  const { coachingList } = useSelector((state) => state.coaching);
  const { userList } = useSelector((state) => state.listUser);

  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'sm');

  const calendarRef = useRef(null);

  const [date, setDate] = useState(new Date());

  const [view, setView] = useState(isDesktop ? 'dayGridMonth' : 'listWeek');

  const selectedEvent = useSelector(selectedEventSelector);

  const { events, isOpenModal, selectedRange } = useSelector((state) => state.calendar);


  const hasCoach = () => {
    let val = null;
    coachingList?.forEach(element => {
      if (element.coache.includes(user.userId)) {

        val = userList.find(u => u?._id === element.coach)
      }
    });
    // console.log("val", val);
    return val;
  }

  useEffect(() => {
    console.log("user.role === COACHE && user?.userId",user.role === "COACHE" && user?.userId);
    console.log("currentUser",currentUser);
    if (user.role === "COACHE" && user?.userId) {
      dispatch(getEventsByCoachee(user?.userId));
    } else if (user.role === "COACH" && user?.userId && currentUser) {
      dispatch(getEventsByCoachee(currentUser._id));
    }
    else {
      enqueueSnackbar("Veillez choisir un coaché!", { variant: 'error' });

    }
   
  }, [dispatch]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isDesktop ? 'dayGridMonth' : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop]);


  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    dispatch(selectRange(arg.start, arg.end));
  };

  const handleSelectEvent = (arg) => {
    dispatch(selectEvent(arg.event.id));
  };

  const handleResizeEvent = async ({ event }) => {
    try {
      const updatedSession = events.find((val) => val.id === event.id);
      console.log('---------------------', updatedSession);
      dispatch(
        updateEvent({ eventId: event.id, updateEvent: { ...updatedSession, start: event.start, end: event.end } })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = async ({ event }) => {
    try {
      const updatedSession = events.find((val) => val.id === event.id);

      dispatch(
        updateEvent({ eventId: event.id, updateEvent: { ...updatedSession, start: event.start, end: event.end } })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddEvent = () => {
    dispatch(openModal());
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Page title="SessionSection">
      <Container>
        <HeaderBreadcrumbs
          heading=" "
          links={[{ name: '', href: '' }]}
          action={
            <Button
              disabled={!currentUser}
              // onLoad={isLoading}
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
              onClick={handleAddEvent}
            >
              Ajouter une session
            </Button>
          }
        />

        <Card>
          <CalendarStyle>
            <CalendarToolbar
              date={date}
              view={view}
              onNextDate={handleClickDateNext}
              onPrevDate={handleClickDatePrev}
              onToday={handleClickToday}
              onChangeView={handleChangeView}
            />
            <FullCalendar
              weekends
              editable
              droppable
              selectable
              locale={'fr'}
              events={events}
              ref={calendarRef}
              rerenderDelay={10}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              headerToolbar={false}
              allDayMaintainDuration
              eventResizableFromStart
              select={handleSelectRange}
              eventDrop={handleDropEvent}
              eventClick={handleSelectEvent}
              eventResize={handleResizeEvent}
              height={isDesktop ? 720 : 'auto'}
              plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
            />
          </CalendarStyle>
        </Card>

        <Dialog open={isOpenModal} onClose={handleCloseModal}>
          <DialogTitle>{selectedEvent ? 'Modifier une session' : 'Ajouter une session'}</DialogTitle>

          <CalendarForm
            event={selectedEvent || {}}
            range={selectedRange}
            onCancel={handleCloseModal}
            currentUser={user.role === "COACHE" ? hasCoach() : currentUser}
            coach={user}
            user={user}
            userList={userList}
            coachingList={coachingList}
          />
        </Dialog>
      </Container>
    </Page>
  );
}
