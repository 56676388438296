/* eslint-disable */

import { useEffect } from 'react';
// @mui
import { Container, Stack } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

// redux
// import { persistColumn, persistCard } from '../../redux/kanbanReducer';
import { fetchGoalBuyCoachee, updateGoal, updateGoalCoach, updateGoalCoachee } from '../../redux/goalReducer';
import { store } from '../../redux/Store';

// layouts
// components
import Page from '../../components/Page';
import { SkeletonKanbanColumn } from '../../components/skeleton';
import { KanbanColumn } from '../../sections/@dashboard/kanban';

export default function GoalsKanban() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.listUser);

  const { isLoadingGoal, board, isLoadingDeleteGoal, isLoadingUpdateGoal } = useSelector((state) => state.goal);
  const { user } = useSelector((state) => state.auth?.user?.user);

  useEffect(() => {
    console.log("user.role === COACHE && user?.userId", user.role === "COACHE" && user?.userId);
    console.log("user.role === 'COACH' && currentUser?._id", user.role === "COACH" && user?.userId && currentUser);
    console.log("currentUser", currentUser);
    console.log("board", board);
    if (user.role === "COACHE" && user?.userId) {
      store.dispatch(fetchGoalBuyCoachee(user?.userId));
    } else if (user.role === "COACH" && currentUser?._id !== null) {
      store.dispatch(fetchGoalBuyCoachee(currentUser?._id));
    } else {
      enqueueSnackbar("Veillez choisir un coaché!", { variant: 'error' });
    }
   
  }, [ currentUser]); // Added currentUser as a dependency


  const onDeleteT = (d) => {
    console.log('d----------', d);
  };

  const handleOnDragEnd = async (result) => {
    // Create a copy of the original board.columns
    const updatedColumns = [...board.columns];

    let currentCard = await board.cards.find((obj) => obj._id === result.draggableId);

    console.log('board', currentCard);
    console.log(result.draggableId);
    console.log(result.destination.droppableId);


    if (user.role === "COACH") {
      //userId
      dispatch(updateGoalCoach({ ...currentCard, column: parseInt(result.destination.droppableId, 10), idCoach: user.userId }));

    } else if (user.role === "COACHE") {
      dispatch(updateGoalCoachee({ ...currentCard, column: parseInt(result.destination.droppableId, 10), idCoachee: user.userId }));
    }
    else {
      dispatch(updateGoal({ ...currentCard, column: parseInt(result.destination.droppableId, 10) }));
    }
  };

  return (
    <Page title="GoalsKanban" sx={{ height: 1 }}>
      <Container maxWidth={false} sx={{ height: 1 }}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction="row"
                alignItems="flex-start"
                spacing={3}
                sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
              >
                {!board.columnOrder.length || isLoadingGoal || isLoadingDeleteGoal || isLoadingUpdateGoal ? (
                  <SkeletonKanbanColumn />
                ) : (
                  board.columnOrder.map((columnId, index) => (
                    <KanbanColumn
                      onDeleteT={onDeleteT}
                      board={board}
                      index={index}
                      key={columnId}
                      column={board.columns.find((val) => val.id === columnId)}
                    />
                  ))
                )}

                {/* {provided.placeholder} */}
                {/* <KanbanColumnAdd /> */}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </Page>
  );
}
