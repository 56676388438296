import { Helmet } from 'react-helmet-async';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalCase } from 'change-case';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

// @mui
import { Container, Box, Card, Tabs, Tab, Grid, Stack } from '@mui/material';
import ProfileFriends from '../sections/user/ProfileFriends';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { ProfileAbout, ProfileCover, ProfileRequestFollow, ProfileSocialInfo } from '../sections/@dashboard/user/profile';
import useTabs from '../hooks/useTabs';
import Iconify from '../components/Iconify';
import { createCoaching, deleteCoaching } from '../redux/coachingReducer';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
  },
}));

export default function CoacheProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth?.user?.user);
  const { isLoadingCreateCoaching } = useSelector((state) => state.coaching);

  const [findFriends, setFindFriends] = useState('');
  /* eslint-disable camelcase */

  const coachProfil_ = useLocation().state;
  const { currentTab, onChangeTab } = useTabs('PROFILE');
  const handleFindFriends = (value) => {
    setFindFriends(value);
  };
  
  const onDemandCoaching = (idCoach) => {
    console.log("Breeexs", idCoach);
    console.log("user.userId", user.userId);
    // call coaching API


    dispatch(createCoaching({ coach: idCoach, coache: user.userId }))
      .then((result) => {
        if (createCoaching.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
          navigate('/dashboard/app', { replace: true });
        } else if (createCoaching.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error during matching to coach:, ${error}`);
        enqueueSnackbar(`Error during matching to coach:, ${error}`, { variant: 'error' });
      });
  };

  const onBreakCoaching = (idCoach) => {
    console.log("Breaking coaching with", idCoach);
    dispatch(deleteCoaching(idCoach))
      .then((result) => {
        if (deleteCoaching.fulfilled.match(result)) {
          // Handle successful deletion
          console.log('Coaching relationship broken:', result.payload);
          enqueueSnackbar('Coaching relationship broken', { variant: 'info' });
        } else if (deleteCoaching.rejected.match(result)) {
          // Handle rejected deletion
          console.log("Error:", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error during breaking coaching relationship: ${error}`);
        enqueueSnackbar(`Error during breaking coaching relationship: ${error}`, { variant: 'error' });
      });
  };

  const PROFILE_TABS = [
    {
      value: 'PROFILE',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <ProfileRequestFollow user={coachProfil_} onDemandCoaching={onDemandCoaching} onBreakCoaching={onBreakCoaching} isLoadingCreateCoaching={isLoadingCreateCoaching} />
              <ProfileSocialInfo profile={coachProfil_} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <ProfileAbout profile={coachProfil_} />
            </Stack>
          </Grid>
        </Grid>
      ),
    },
    {
      value: 'Ses coaches',
      icon: <Iconify icon={'eva:heart-fill'} width={20} height={20} />,
      component: (
        <ProfileFriends
          friends={coachProfil_.coache}
          findFriends={findFriends}
          onFindFriends={handleFindFriends}
        />
      ),
    }
  ];

  return (
    <>
      <Helmet>
        <title> Dashboard | Profile du Coach </title>
      </Helmet>

      <Container>
        <HeaderBreadcrumbs
          heading="Profile du Coach"
          links={[
            { name: 'Accueil', href: '/dashboard' },
            { name: 'Mon Coach', href: '/dashboard/coach' },
            { name: coachProfil_.name, href: '' },
          ]}
        />

        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative',
          }}
        >
          <ProfileCover myProfile={coachProfil_} />

          <TabsWrapperStyle>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </>
  );
}
