import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
// @mui
import { Stack, Divider, Checkbox, MenuItem, IconButton, FormControlLabel, Box } from '@mui/material';
import { useSelector } from 'react-redux';

// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import {store} from '../../redux/Store';
import { fetchAction } from '../../redux/actionReducer';

Actions.propTypes = {
  actions: PropTypes.array,
  onDelete: PropTypes.func,
  onCheck: PropTypes.func,
  onEditAction:PropTypes.func,
};

export default function Actions({ onDelete, onCheck, onEditAction, actions}) {
  
  const { control } = useForm({
    defaultValues: {
      taskCompleted: actions.map(item => {
        // Check if the status is "ACCEPTED"
        if (item.status === "ACCEPETED") {
          // If accepted, return only the description property
          return item.description;
        }
        // Otherwise, return undefined (or any other falsy value)
        return "";
      })}
      // myActionsChecked
    
  });

  return (
    <Box>
      <Controller
        name="taskCompleted"
        control={control}
        render={({ field }) => {
          // console.log("field",field.value);
          const onSelected = (task) =>
            field.value.includes(task) ? field.value.filter((value) => value !== task) : [...field.value, task];

          return (
            <>
              {actions.map((task, key) => (
                <TaskItem
                  key={task._id}
                  task={task}
                  ky={key+1}
                  checked={field.value.includes(task.description)}
                  onChange={() => {
                    field.onChange(onSelected(task.description));
                    onCheck(task)
                  }}
                  onDelete={onDelete}
                  onCheck={onCheck}
                  onEditAction={onEditAction}
                />
              ))}
            </>
          );
        }}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  ky: PropTypes.number,
  task: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onCheck: PropTypes.func,
  onEditAction: PropTypes.func
};

function TaskItem({ ky, task, checked, onChange, onDelete, onCheck, onEditAction }) {
  return (
    <Stack direction="row" sx={{ ...(checked && { color: 'text.disabled', textDecoration: 'line-through' }) }}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} />}
        label={`${ky}) ${task.description}`}
        sx={{ flexGrow: 1, mb: 2 }}
      />
      <MoreMenuButton onDelete={onDelete} onCheck={onCheck} task={task} onEditAction={onEditAction} />
    </Stack>
  );
}

// ----------------------------------------------------------------------
MoreMenuButton.propTypes = {
  onDelete: PropTypes.func,
  task: PropTypes.string,
  onCheck: PropTypes.object,
  onEditAction: PropTypes.func,
};

function MoreMenuButton({ onDelete, onCheck, task, onEditAction }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          background: "#d8d7d4",
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem  onClick={() => onCheck(task)}>
          <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ ...ICON }} />
          Marquer comme terminé
        </MenuItem>

        <MenuItem onClick={() => onEditAction(task)}>
          <Iconify icon={'eva:edit-fill'} sx={{ ...ICON }} />
          Modifier
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ color: 'error.main' }} onClick={() => onDelete(task)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
          Supprimer
        </MenuItem>
      </MenuPopover>
    </>
  );
}
