import { createSlice } from "@reduxjs/toolkit";

const updateSlice = createSlice({
  name: 'update',
  initialState: {
    updatedUser: null,
    errorupdate: null,
    isLoadingupdate: false,
  },
  reducers: {
    updateStart: (state) => {
      state.isLoadingupdate = true;
    },
    updateSuccess: (state, action) => {
      state.isLoadingupdate = false;
      state.updatedUser = action.payload;
      state.errorupdate = null;

    },
    updateFailure: (state, action) => {
      state.isLoadingupdate = false;
      state.errorupdate = action.payload;
    },
  },
});

export const { updateStart, updateSuccess, updateFailure } = updateSlice.actions;

export default updateSlice.reducer;