/* eslint-disable */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import { Paper, Typography, Box, Chip, IconButton } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
// components
import Image from '../../../components/Image';
import { store } from '../../../redux/Store';
import { fetchAction, removeAction, updateAction } from '../../../redux/actionReducer';

//
import KanbanTaskDetails from './KanbanTaskDetails';


// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: 2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

KanbanTaskCard.propTypes = {
  card: PropTypes.object,
  actions: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  onDeleteT: PropTypes.func,
  onCheck: PropTypes.func,
  onUpdateTatk: PropTypes.func,
  // onEditAction: PropTypes.func
};

export default function KanbanTaskCard({ card, onDeleteTask, onUpdateTatk, index, actions }) {
  const { name, attachements } = card;
  // console.log("cadr--->>>", name);
  const dispatch = useDispatch();

  const [openDetails, setOpenDetails] = useState(false);


  const [completed, setCompleted] = useState(card.completed);


  useEffect(() => {
    //  dispatch(getBoard());
    store.dispatch(fetchAction());
  }, [dispatch]);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    console.log('ok');
    setOpenDetails(false);
  };

  // const handleChangeComplete = (event) => {
  //   setCompleted(event.target.checked);
  // };

  const onDelete = (task) => {
    console.log("Delete", task);
    dispatch(removeAction(task._id))
  }

  // const onEditAction = (task) =>{
  //   console.log("onEditAction", task);
  //   // dispatch(removeAction(task._id))
  // }

  const onCheck = (task) => {
    console.log("onCheck", task);


    dispatch(updateAction({ ...task, status: task.status === "ACCEPETED" ? "PENDING" : "ACCEPETED", _id: task._id }))
      .then((result) => {
        if (updateAction.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (updateAction.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error during matching to coach:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });
  }

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Paper
            sx={{
              px: 2,
              paddingBottom: 2,
              width: 1,
              position: 'relative',
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z16,
              },
              ...(attachements?.length > 0 && {
                pt: 2,
              }),
            }}
          >
            <Box onClick={handleOpenDetails} sx={{ cursor: 'pointer' }}>
              {attachements?.length > 0 && (
                <Box
                  sx={{
                    pt: '60%',
                    borderRadius: 1,
                    overflow: 'hidden',
                    position: 'relative',
                    transition: (theme) =>
                      theme.transitions.create('opacity', {
                        duration: theme.transitions.duration.shortest,
                      }),
                    ...(completed && {
                      opacity: 0.48,
                    }),
                  }}
                >
                  <Image src={attachements[0]} sx={{ position: 'absolute', top: 0, width: 1, height: 1 }} />
                </Box>
              )}

              <Typography
                noWrap
                variant="subtitle2"
                sx={{
                  py: 3,
                  // pl: 5,
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  ...(completed && { opacity: 0.48 }),
                }}
              >
                {name}
              </Typography>
            </Box>

            {/* <Checkbox
              disableRipple
              checked={completed}
              icon={<Iconify icon={'eva:radio-button-off-outline'} />}
              checkedIcon={<Iconify icon={'eva:checkmark-circle-2-outline'} />}
              onChange={handleChangeComplete}
              sx={{ position: 'absolute', bottom: 15 }}
            /> */}

            <Chip
              label={card.priority === 'LOW' ? 'FAIBLE' : card.priority === 'MEDIUM' ? 'MOYEN' : 'ÉLEVÉ'}
              color={card.priority === 'LOW' ? 'info' : card.priority === 'MEDIUM' ? 'warning' : 'error'}
              size="small"
            />

            <IconButton aria-label="cart">
              <StyledBadge badgeContent={`${actions.filter((val) => val.status === "ACCEPETED").length}/${actions.length}`} color="secondary">
                <FormatListNumberedIcon />
              </StyledBadge>
            </IconButton>
          </Paper>

          <KanbanTaskDetails
            card={card}
            onDelete={onDelete}
            // onEditAction={onEditAction}
            onCheck={onCheck}
            myActions={actions}
            myActionsChecked={actions.map(item => {
              // Check if the status is "ACCEPTED"
              if (item.status === "ACCEPETED") {
                // If accepted, return only the description property
                return item.description;
              }
              // Otherwise, return undefined (or any other falsy value)

              return "";
            })}
            isOpen={openDetails}
            onClose={handleCloseDetails}
            onDeleteTask={() => onDeleteTask(card.id)}
            onUpdateTatk={(c) => onUpdateTatk(c)}
          />
        </div>
      )}
    </Draggable>
  );
}
