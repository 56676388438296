import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppProvider, SignInPage } from '@toolpad/core';

import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gapi } from 'gapi-script'; // Import Google API client
import { useTheme } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';

import Iconify from '../../../components/Iconify';
import { login } from '../../../redux/loginAction';
import { deleteAll } from '../../../redux/goalReducer';
import { loginGoogle } from '../../../redux/loginGoogleAction';

const providers = [
  { id: 'google', name: 'Google' },
  // { id: 'credentials', name: 'Email and Password' },
];

export default function LoginForm() {
  const dispatch = useDispatch();

  const { error, isLoading } = useSelector((state) => state.auth);

  const [localError, setLocalError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [userInfo, setUserInfo] = useState(null);

  const handleClick = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      // Check if email or password is empty
      // Set an error message
      setLocalError("L'e-mail ou le mot de passe ne peut pas être vide")

      return;
    }
    setLocalError('')
    dispatch(deleteAll());
    dispatch(login(email, password));
  };

  const handleGoogleSignIn = async () => {
    try {
      const response = await gapi.auth2.getAuthInstance().signIn();
      const profile = response.getBasicProfile(); // Get user's profile information

      // Extract user information
      const userInfo = {
        id: profile.getId(), // Do not send to your backend
        name: profile.getName(),
        email: profile.getEmail(),
        imageUrl: profile.getImageUrl(),
      };



      // Log user information
      console.log("User Information:", userInfo);
      setLocalError('')
      dispatch(deleteAll());
      dispatch(loginGoogle( {email: userInfo.email, name:userInfo.name, imageUrl: userInfo.imageUrl}))
      .then((result) => {
        if (loginGoogle.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (loginGoogle.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error during matching to coach:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });

      sendWelcomeEmail(userInfo.email);



      // You can now use this information in your application
      // For example, you can set it in the state or send it to your backend
      setUserInfo(userInfo);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };

  // Initialize Google API client
useEffect(() => {
  const initClient = () => {
    gapi.load('client:auth2', () => {
      gapi.auth2.init({
        clientId: '767810973898-odns86cfsft07lkums3l8glrm5l221go.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.send', // Added Gmail send scope 
      });
    });
  };

  gapi.load('client:auth2', initClient);
}, []);


  const theme = useTheme();

  // Function to send welcome email using Gmail API
  const sendWelcomeEmail = async (email) => {
    const message = `From: "Alphanew Coaching" <get.edupedia@gmail.com>\r\n` +
                  `To: ${email}\r\n` +
                  `Subject: Bienvenue dans notre Alphanew.coach!\r\n\r\n` +
                  `Merci de vous être connecté ! Nous sommes heureux de vous avoir.`;

    const encodedMessage = btoa(unescape(encodeURIComponent(message))).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    try {
      // Ensure the Gmail API is initialized before sending the email
      await gapi.client.load('gmail', 'v1'); // Load the Gmail API
      const response = await gapi.client.gmail.users.messages.send({
        // userId: 'me',
        userId: 'me',  // Use the sender's email address instead of 'me'
        resource: {
          raw: encodedMessage,
        },
      });
      console.log("Email sent successfully:", response);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };


  return (
    <AppProvider theme={theme}>
      <Stack spacing={3}>

      <SignInPage signIn={handleGoogleSignIn} providers={providers} />

       
        <TextField name="email" label="Adresse email" value={email} onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link href="/resetPassword" style={{ cursor: 'pointer' }} variant="subtitle2" underline="hover">
          Mot de passe oublié?
        </Link>
      </Stack>

      {error && <Typography variant="body" sx={{ textAlign: 'center', color: 'red', mb: 3 }}>{error}</Typography>}
      {localError && <Typography variant="body" sx={{ textAlign: 'center', color: 'red', mb: 3 }}>{localError}</Typography>}

      <LoadingButton 
        loading={isLoading} 
        disabled={isLoading} 
        fullWidth 
        size="large" 
        type="submit" 
        variant="contained" 
        onClick={handleClick}
      >
        Se connecter
      </LoadingButton>



      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Typography variant="body" sx={{}}>Voulez-vous créer un compte?</Typography>

        <Link href="/register" style={{ cursor: 'pointer' }} variant="subtitle2" underline="hover">
          Créer un compte
        </Link>
      </Stack>
    </AppProvider>
  );
}
