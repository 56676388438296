// @mui
import { Container } from '@mui/material';
// routes
// hooks
// components
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
// sections
import InvoiceNewEditForm from '../sections/@dashboard/invoice/new-edit-form';

// ----------------------------------------------------------------------

export default function InvoiceCreate() {

  return (
    <Page title="Facture: Créer une facture">
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading="Créer une facture"
          links={[
            { name: 'Dashboard', href: '/' },
            { name: 'Factutres', href: '/dashboard/invoice' },
            { name: 'Nouvelle facture' },
          ]}
        />

        <InvoiceNewEditForm />
      </Container>
    </Page>
  );
}
