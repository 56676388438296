import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Container, Card, CardHeader, Grid, Typography, Button, Box, CircularProgress,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  ButtonGroup, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { styled, useTheme } from '@mui/material/styles';
import PrintIcon from '@mui/icons-material/Print';

import merge from 'lodash/merge';
import { useSelector, useDispatch } from 'react-redux';
import Groq from "groq-sdk";

import ReactApexChart from 'react-apexcharts';
// @mui

//
import { BaseOptionChart } from '../../components/chart';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import { store } from '../../redux/Store';
import { fetchEvaluation } from '../../redux/evaluationReducer';
import { fetchCoaching } from '../../redux/coachingReducer';
import { fetchAnswers } from '../../redux/answerReducer';

// ----------------------------------------------------------------------

export default function EvaluationSection() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [advice, setAdvice] = useState('');
  const [isLoadingAdvice, setIsLoadingAdvice] = useState(false);
  const { answerList } = useSelector((state) => state.answer);
  const { user } = useSelector((state) => state.auth.user?.user);

  const [availableLevels, setAvailableLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const [selectedCoachee, setSelectedCoachee] = useState('');
  const [coachees, setCoachees] = useState([]);

  useEffect(() => {
    if (answerList.length > 0 && user) {
      const userAnswers = answerList.filter(answer => answer.coachee?._id === user.userId);
      const levels = [...new Set(userAnswers.map(answer => answer.level))].sort((a, b) => b - a);
      setAvailableLevels(levels);
      if (levels.length > 0 && !selectedLevel) {
        setSelectedLevel(levels[0]);
      }
    }
  }, [answerList, user, selectedLevel]);

  useEffect(() => {
    if (user && user.role === 'COACH' && answerList.length > 0) {
      const uniqueCoachees = [...new Set(answerList.map(answer => answer.coachee?._id))]
        .filter(Boolean)
        .map(coacheeId => {
          const coacheeAnswer = answerList.find(answer => answer.coachee?._id === coacheeId);
          return {
            id: coacheeId,
            name: coacheeAnswer?.coachee?.name || 'Inconnue',
            project: coacheeAnswer?.coachee?.project || 'Inconnue',
            detailsOfProject: coacheeAnswer?.coachee?.detailsOfProject || 'Inconnue'
          };
        });
      setCoachees(uniqueCoachees);
      if (uniqueCoachees.length > 0) {
        setSelectedCoachee(uniqueCoachees[0].id);
      }
    }
  }, [user, answerList]);

  const handleLevelClick = (level) => {
    setSelectedLevel(level);
  };

  const handleCoacheeChange = (event) => {
    setSelectedCoachee(event.target.value);
  };

  //  react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchEvaluation());
    store.dispatch(fetchCoaching());
    store.dispatch(fetchAnswers());
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { currentUser } = useSelector((state) => state.listUser);
  const { evaluationList, isLoadingEvaluation } = useSelector((state) => state.evaluation);

  const categories = ['Produit/Service', 'Marketing et ventes', 'Finance', 'Ressources Humaines', 'Planification', 'Logistique'];
  const factors = ['Analyse', 'Outils', 'Exécution', 'Durabilité'];


  const [scores, setScores] = useState({});

  const calculateAllScores = () => {
    const newScores = {};
    categories.forEach(category => {
      newScores[category] = {};
      factors.forEach(factor => {
        newScores[category][factor] = calculateCategoryScore(category, factor);
      });
    });
    setScores(newScores);
  };

  useEffect(() => {
    if (evaluationList.length > 0 && answerList.length > 0) {
      calculateAllScores();
    }
  }, [evaluationList, answerList]);

  const getCurrentCoachee = () => {
    if (user.role === 'COACH') {
      return coachees.find(c => c.id === selectedCoachee) || null;
    }
    return user ? { id: user.userId, name: user.name, project: user.project, detailsOfProject: user.detailsOfProject } : null;
  };

  const calculateCategoryScore = (category, nomFacteur) => {
    const currentCoachee = getCurrentCoachee();
    if (!currentCoachee) {
      console.log('Aucun coaché ​​sélectionné ou données utilisateur disponibles ');
      return 0;
    }
    
    const userAnswers = answerList.filter(answer => answer.coachee?._id === currentCoachee.id);
    const categoryEvaluation = evaluationList.find(evaluation => evaluation.questionHeader.includes(category));
    
    if (!categoryEvaluation || userAnswers.length === 0) {
      console.log("Aucune évaluation de catégorie ou réponse d'utilisateur trouvée");
      return 0;
    }

    const filteredAnswers = filterAnswersByCoacheeAndFacteur(
      {
        coachee: { _id: currentCoachee.id },
        evaluation: categoryEvaluation,
        answers: userAnswers.find(answer => answer.evaluation?._id === categoryEvaluation._id)?.answers || []
      },
      currentCoachee.id,
      nomFacteur
    );

    if (filteredAnswers.length === 0) {
      console.log(`No answers found for ${nomFacteur}`);
      return 0;
    }

    const totalQuestions = filteredAnswers.length;
    const correctAnswers = filteredAnswers.filter(a => a.answer === 1).length;
    const score = (correctAnswers / totalQuestions) * 100;
    
    console.log(`${category} - ${nomFacteur}: Nombre total de questions: ${totalQuestions}, Correct Answers: ${correctAnswers}, Score: ${score}`);
    
    return score;
  };

  const formatAdvice = (advice)=> {
    const sections = advice.split('**'); // Séparer le texte par les ** qui entourent les titres
  
    return sections.map((section, index) => {
      if (index % 2 === 1) {
        // Les indices impairs contiennent les titres entourés de **, donc on les rend en gras
        return (
          <Typography key={index} variant="h6" fontWeight="bold" gutterBottom>
            {section.trim()}
          </Typography>
        );
      }
      // Les autres sections sont des paragraphes normaux
      return (
        <Typography key={index} variant="body1" paragraph>
          {section.trim()}
        </Typography>
      );
    });
  }

  const CHART_DATA = [
    { name: 'Analyse', data: [
      calculateCategoryScore('Produit/Service', "Analyse"),
      calculateCategoryScore('Marketing et ventes', "Analyse"),
      calculateCategoryScore('Finance', "Analyse"),
      calculateCategoryScore('Ressources Humaines', "Analyse"),
      calculateCategoryScore('Planification', "Analyse"),
      calculateCategoryScore('Logistique', "Analyse"),
    ]},
    { name: 'Outils', data: [
      calculateCategoryScore('Produit/Service', "Outils"),
      calculateCategoryScore('Marketing et ventes', "Outils"),
      calculateCategoryScore('Finance', "Outils"),
      calculateCategoryScore('Ressources Humaines', "Outils"),
      calculateCategoryScore('Planification', "Outils"),
      calculateCategoryScore('Logistique', "Outils"), 
    ]},
    { name: 'Exécution', data: [
      calculateCategoryScore('Produit/Service', "Exécution" ),
      calculateCategoryScore('Marketing et ventes', "Exécution"),
      calculateCategoryScore('Finance', "Exécution"),
      calculateCategoryScore('Ressources Humaines', "Exécution"),
      calculateCategoryScore('Planification', "Exécution"),
      calculateCategoryScore('Logistique', "Exécution"),
    ]},
    { name: 'Durabilité', data: [
      calculateCategoryScore('Produit/Service', "Durabilité"),
      calculateCategoryScore('Marketing et ventes', "Durabilité"),
      calculateCategoryScore('Finance', "Durabilité"),
      calculateCategoryScore('Ressources Humaines', "Durabilité"),
      calculateCategoryScore('Planification', "Durabilité"),
      calculateCategoryScore('Logistique', "Durabilité"),
    ]},
  ];

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: 2 },
    fill: { opacity: 0.48 },
    legend: { floating: true, horizontalAlign: 'center' },
    xaxis: {
      categories: [
        'Produit/service',
        'Marketing et ventes',
        'Finance',
        'Ressources humaines',
        'Planification',
        'Logistique',
      ],
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
          ],
        },
      },
    },
  });

  const getScoreBackgroundColor = (score) => {
    if (score < 33) return theme.palette.error.light;
    if (score < 66) return theme.palette.warning.light;
    return theme.palette.success.light;
  };

  const groq = new Groq({
    apiKey: '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',
    dangerouslyAllowBrowser: true
  });

  const getGroqChatCompletion = async (message) => {
    try {
      const response = await groq.chat.completions.create({
        messages: [
          {
            role: "user",
            content: message,
          },
        ],
        model: "llama3-8b-8192",
      });
      return response.choices[0]?.message?.content || "";
    } catch (error) {
      console.error('Error in getGroqChatCompletion:', error);
      throw error;
    }
  };

  const generateAdvice = async () => {
    setIsLoadingAdvice(true);
    const currentCoachee = getCurrentCoachee();
    if (!currentCoachee) {
      setAdvice('Impossible de générer des conseils : aucune donnée sur le coaché ​​disponible.');
      setIsLoadingAdvice(false);
      return;
    }

    const scoresPs = {
      Analyse: calculateCategoryScore('Produit/Service', 'Analyse'),
      Outils: calculateCategoryScore('Produit/Service', 'Outils'),
      Exécution: calculateCategoryScore('Produit/Service', 'Exécution'),
      Durabilité: calculateCategoryScore('Produit/Service', 'Durabilité'),
    };

    const scoresMv = {
      Analyse: calculateCategoryScore('Marketing et ventes', 'Analyse'),
      Outils: calculateCategoryScore('Marketing et ventes', 'Outils'),
      Exécution: calculateCategoryScore('Marketing et ventes', 'Exécution'),
      Durabilité: calculateCategoryScore('Marketing et ventes', 'Durabilité'),
    };

    const scoresF = {
      Analyse: calculateCategoryScore('Finance', 'Analyse'),
      Outils: calculateCategoryScore('Finance', 'Outils'),
      Exécution: calculateCategoryScore('Finance', 'Exécution'),
      Durabilité: calculateCategoryScore('Finance', 'Durabilité'),
    };

    const scoresRH = {
      Analyse: calculateCategoryScore('Ressources Humaines', 'Analyse'),
      Outils: calculateCategoryScore('Ressources Humaines', 'Outils'),
      Exécution: calculateCategoryScore('Ressources Humaines', 'Exécution'),
      Durabilité: calculateCategoryScore('Ressources Humaines', 'Durabilité'),
    };

    const scoresP = {
      Analyse: calculateCategoryScore('Planification', 'Analyse'),
      Outils: calculateCategoryScore('Planification', 'Outils'),
      Exécution: calculateCategoryScore('Planification', 'Exécution'),
      Durabilité: calculateCategoryScore('Planification', 'Durabilité'),
    };

    const scoresL = {
      Analyse: calculateCategoryScore('Logistique', 'Analyse'),
      Outils: calculateCategoryScore('Logistique', 'Outils'),
      Exécution: calculateCategoryScore('Logistique', 'Exécution'),
      Durabilité: calculateCategoryScore('Logistique', 'Durabilité'),
    };

    const prompt = `
    Voici les détails de la Roue des Compétences de l'entreprise : ${currentCoachee.project}., 
    et nous aimerions une analyse approfondie avec des recommandations spécifiques 
    pour aider cette entreprise à améliorer ses performances globales.

L'entreprise opère dans le secteur : ${currentCoachee.detailsOfProject}.

Voici les scores obtenus par l'entreprise dans différents domaines de gestion :

Gestion du produit ou service :
Analyse : ${scoresPs.Analyse.toFixed(2)}%
Outils : ${scoresPs.Outils.toFixed(2)}%
Exécution : ${scoresPs.Exécution.toFixed(2)}%
Durabilité : ${scoresPs.Durabilité.toFixed(2)}%

Marketing et ventes :
Analyse : ${scoresMv.Analyse.toFixed(2)}%
Outils : ${scoresMv.Outils.toFixed(2)}%
Exécution : ${scoresMv.Exécution.toFixed(2)}%
Durabilité : ${scoresMv.Durabilité.toFixed(2)}%

Gestion des finances :
Analyse : ${scoresF.Analyse.toFixed(2)}%
Outils : ${scoresF.Outils.toFixed(2)}%
Exécution : ${scoresF.Exécution.toFixed(2)}%
Durabilité : ${scoresF.Durabilité.toFixed(2)}%

Ressources humaines :
Analyse : ${scoresRH.Analyse.toFixed(2)}%
Outils : ${scoresRH.Outils.toFixed(2)}%
Exécution : ${scoresRH.Exécution.toFixed(2)}%
Durabilité : ${scoresRH.Durabilité.toFixed(2)}%

Planification :
Analyse : ${scoresP.Analyse.toFixed(2)}%
Outils : ${scoresP.Outils.toFixed(2)}%
Exécution : ${scoresP.Exécution.toFixed(2)}%
Durabilité : ${scoresP.Durabilité.toFixed(2)}%

Logistique :
Analyse : ${scoresL.Analyse.toFixed(2)}%
Outils : ${scoresL.Outils.toFixed(2)}%
Exécution : ${scoresL.Exécution.toFixed(2)}%
Durabilité : ${scoresL.Durabilité.toFixed(2)}%

Objectif :
Sur la base des résultats obtenus, nous cherchons à générer des recommandations 
concises et pertinentes pour améliorer la performance globale de l'entreprise. 
Les conseils doivent être basés sur les scores les plus faibles, 
tout en tenant compte des spécificités du secteur d'activité. 
Nous souhaitons des recommandations par domaine clé (produit/service, marketing, finances, etc.), 
en identifiant les domaines où des améliorations importantes sont nécessaires, 
et en proposant des solutions pratiques.

Les suggestions doivent être organisées en plusieurs paragraphes, 
avec des conseils pratiques et des solutions concrètes pour chaque aspect. 
L'objectif est de fournir à cette entreprise des étapes claires pour renforcer ses compétences 
dans les domaines les plus critiques. Merci de formuler ces recommandations en français.`;

console.log(prompt);
    try {
      const generatedAdvice = await getGroqChatCompletion(prompt);
      setAdvice(generatedAdvice);
    } catch (error) {
      console.error('Error generating advice:', error);
      setAdvice('Désolé, nous n\'avons pas pu générer de conseil personnalisé pour le moment.');
    } finally {
      setIsLoadingAdvice(false);
    }
  };

  useEffect(() => {
    if (evaluationList.length > 0 && answerList.length > 0 && getCurrentCoachee()) {
      calculateAllScores();
      generateAdvice();
    }
  }, [evaluationList, answerList, selectedCoachee, user]);

  function filterAnswersByCoacheeAndFacteur(data, coacheeId, nomFacteur) {
    // Check if the data is for the correct coachee
    if (data.coachee._id !== coacheeId) {
      console.log("Coachee ID does not match");
      return [];
    }

    // Find the correct factor in the evaluation
    const factor = data.evaluation.facteur.find(f => f.nomFacteur === nomFacteur);
    if (!factor) {
      console.log(`No factor found for nomFacteur: ${nomFacteur}`);
      return [];
    }

    // Get the question IDs for the specified factor
    const factorQuestionIds = factor.questions.map(q => q._id);

    // Filter the answers
    const filteredAnswers = data.answers.filter(answer => 
      factorQuestionIds.includes(answer.idQuestion)
    );

    console.log(`Filtered ${filteredAnswers.length} answers for ${nomFacteur}`);
    return filteredAnswers;
  }

  const handlePrint = () => {
    const printContent = document.getElementById('printable-content');
    const windowPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    const currentDate = new Date().toLocaleDateString();
    const currentCoachee = getCurrentCoachee();
    
    const evaluationLevels = availableLevels.map(level => `Niveau ${level}`).join(', ');
    
    windowPrint.document.write(`
      <html>
        <head>
          <title>Evaluation Report</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 0; padding: 20px; }
            .print-section { margin-bottom: 20px; }
            table { border-collapse: collapse; width: 100%; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            .header { margin-bottom: 20px; }
            .footer { position: fixed; bottom: -12px; width: 100%; text-align: center; font-size: 10px; opacity: 0.5; }
          </style>
        </head>
        <body>
          <div class="header">
            <h2>Rapport d'évaluation</h2>
            <p>Date: ${currentDate}</p>
            <p>Coach: ${currentUser?.name || 'N/A'}</p>
            <p>Coachee: ${currentCoachee.name}</p>
            <p>Entreprise: ${currentCoachee.project}</p>
            <p>Niveaux d'évaluation: ${evaluationLevels}</p>
             <p>2024</p>
          </div>
          ${printContent.innerHTML}
          <div class="footer">
            <p>Alpha-coaching powered by ALPHA-NEW SARL</p>
          </div>
        </body>
      </html>
    `);
    windowPrint.document.close();
    windowPrint.focus();
    windowPrint.print();
    windowPrint.close();
  };

  return (
    <Page title="EvaluationSection">
      <Container>
        <HeaderBreadcrumbs
          heading=" "
          links={[{ name: '', href: '' }]}
          action={
            <Button
              disabled={!currentUser}
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
            >
              Passer une évaluation
            </Button>
          }
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {user && user.role === 'COACH' && (
              <FormControl sx={{ minWidth: 120, mr: 2 }}>
                <InputLabel id="coachee-select-label">Coachee</InputLabel>
                <Select
                  labelId="coachee-select-label"
                  value={selectedCoachee}
                  onChange={handleCoacheeChange}
                  label="Coachee"
                >
                  {coachees.map((coachee) => (
                    <MenuItem key={coachee.id} value={coachee.id}>{coachee.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Typography variant="subtitle1" component="span" sx={{ mr: 2 }}>
              Niveaux d'évaluation:
            </Typography>
            <ButtonGroup variant="outlined" size="small">
              {availableLevels.map((level) => (
                <Button
                  key={level}
                  variant={selectedLevel === level ? "contained" : "outlined"}
                  onClick={() => handleLevelClick(level)}
                >
                  {level}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={handlePrint}
          >
            Imprimer le rapport
          </Button>
        </Box>
        <div id="printable-content">
          <Grid container spacing={3}>
            <Grid item xs={12} >
              <Card>
                <CardHeader title="La roue des compétences" />
                <Box className="print-section">
                  {isLoadingEvaluation ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height={440}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ReactApexChart type="radar" series={CHART_DATA} options={chartOptions} height={440} />
                  )}
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Scores Détaillés" />
                <TableContainer component={Paper} className="print-section">
                  <Table sx={{ minWidth: 650 }} aria-label="scores table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Catégorie</TableCell>
                        {factors.map(factor => (
                          <TableCell key={factor} align="right">{factor}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {categories.map(category => (
                        <TableRow key={category}>
                          <TableCell component="th" scope="row">
                            {category}
                          </TableCell>
                          {factors.map(factor => {
                            const score = scores[category] ? scores[category][factor] : null;
                            return (
                              <TableCell 
                                key={`${category}-${factor}`} 
                                align="right"
                                sx={{
                                  bgcolor: score !== null ? getScoreBackgroundColor(score) : 'inherit',
                                  fontWeight: 'bold',
                                }}
                              >
                                {score !== null ? `${score.toFixed(2)}%` : '-'}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Conseil Personnalisé" />
                <Box sx={{ p: 3 }} className="print-section">
                  {isLoadingAdvice ? (
                    <CircularProgress />
                  ) : (
                    <Typography variant="body1">{formatAdvice(advice)}</Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Page>
  );
}
