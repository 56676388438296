/* eslint no-unneeded-ternary: "error" */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';

import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
  Button,
  Stepper,
  Step,
  StepLabel,
  Container,
  Grid,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { register } from '../../../redux/registerAction';

import Iconify from '../../../components/Iconify';
import { store } from '../../../redux/Store';
import { fetchUsers } from '../../../redux/listUserReducer';


const objectifs = ["Planification intégrée et suivi des sessions",
"Prise de notes",
"Objectifs, actions et suivi des progrès",
"Formulaires et feuilles de travail",
"Contrat et facture numériques",
"Gérer les coachés et leurs plannings"];


const objectifsEntrepreneur = ["Planification intégrée et suivi des sessions",
"Prise de notes",
"Objectifs, actions et suivi des progrès",
"Formulaires et feuilles de travail",
"Portail client à l'image de la marque",
"Contrat et facture numériques"];


export default function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //  react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchUsers());

    // fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { registeredUser, errorRegister, isLoadingRegister } = useSelector((state) => state.register);
  const { userList } = useSelector((state) => state.listUser);

  const [errorSaveUser, seErrorSaveUser] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [sex, setSex] = useState('');
  const [project, setProject] = useState(''); // Add project state
  const [detailsOfProject, setDetailsOfProject] = useState(''); // Add detailsOfProject state
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [sexError, setSexError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [projectError, setProjectError] = useState(''); // Add project error state
  const [detailsOfProjectError, setDetailsOfProjectError] = useState(''); // Add detailsOfProject error state

  // Categorie
  const [catSelector, setCatSelector] = useState(0);
  const [catError, setCatError] = useState('');

  // Add coach profile
  const [quality, setQuality] = useState([]);
  

  const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  }));

  const [steps, setSteps] = useState(['Catégorie', 'Profile', 'Entreprise']);

  // stepper
  const [activeStep, setActiveStep] = useState(0);

  // Autocomplete



  // Stepper
  const handleNext = () => {
    if (catSelector === 0) {
      setCatError('Veillez selectionner une categorie');
    } else {
      setCatError('');
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const handleChange = (event) => {
    setSex(event.target.value);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    console.log('ok', name, nameError);
    try {
      // Validate and set error messages
      if (!name.trim()) {
        setNameError('Le nom ne peut pas être vide');
      } else {
        setNameError('');
      }

      if (!phone.trim()) {
        setPhoneError('Le téléphone ne peut pas être vide');
      } else {
        setPhoneError('');
      }

      if (!email.trim()) {
        setEmailError("L'e-mail ne peut pas être vide");
      } else {
        setEmailError('');
      }

      if (!password.trim()) {
        setPasswordError('Le mot de passe ne peut pas être vide');
      } else {
        setPasswordError('');
      }

      if (!confirmPassword.trim()) {
        setConfirmPasswordError('Veuillez confirmer le mot de passe');
      } else if (confirmPassword !== password) {
        setConfirmPasswordError('Les mots de passe ne correspondent pas');
      } else {
        setConfirmPasswordError('');
      }

      if (!sex.trim()) {
        setSexError('Le mot de passe ne peut pas être vide');
      } else {
        setSexError('');
      }

      if (!project.trim()) {
        setProjectError('Le projet ne peut pas être vide');
      } else {
        setProjectError('');
      }

      if (!detailsOfProject.trim()) {
        setDetailsOfProjectError('Les détails du projet ne peuvent pas être vides');
      } else {
        setDetailsOfProjectError('');
      }

      // If there are no errors, proceed with registration
      if (name && email && password && confirmPassword && phone && sex && project && detailsOfProject) {
        // Dispatch registration action here
        console.log('registeredUser', await registeredUser);
        console.log(name, password, phone, confirmPassword, sex, email, project, detailsOfProject);
        const role = catSelector === 1 ? 'COACH' : 'COACHE';

        // check if user exists in b360
        const checkUser = userList.find((val) => val.email === email);

        if (!checkUser) {
          dispatch(register(name, email, phone, sex, password, role, quality, project, detailsOfProject))
            .then((data) => {
              console.log('data', data);
              // setLatestCreatedUser(data.userId);
              seErrorSaveUser('');
            })
            .catch((error) => {
              seErrorSaveUser(`Erreur: ${error}`);

              console.error('Registration error:', error);
            });
        } else {
          seErrorSaveUser("L'utilisateur existe, veillez vous connecter");
        }

        console.log();
        console.log('registeredUser2', await registeredUser);
      }
    } catch (e) {
      console.log('errorRegister', errorRegister);
      console.log(e);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ width: '100%', mb: 5 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              // minHeight="100vh"
              mt={4}
            >
              <Paper
                elevation={3}
                sx={{ padding: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <img
                  src="../../../assets/verified.gif"
                  alt="Success Gif"
                  style={{ width: '15%', marginBottom: 2, alignSelf: 'center' }}
                />

                <Typography variant="h5" gutterBottom>
                  Compte créé avec succès!
                </Typography>
                <Typography>Un email a été envoyé à votre adresse pour confirmation.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate('/login', { replace: true });
                  }}
                  sx={{ marginTop: 2 }}
                >
                  Se connecter
                </Button>
              </Paper>
            </Box>
          </>
        ) : (
          <>
            {activeStep === 0 ? (
              <>
                <Typography variant="h4" sx={{ mb: 5, textAlign: 'center' }}>
                  Choisissez votre catégorie
                </Typography>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      onClick={() => {
                        setCatSelector(1);
                        setSteps(['Catégorie', 'Profile', 'Entreprise']);
                      }}
                      sx={{
                        py: 5,
                        boxShadow: catSelector === 1 ? 0 : 5,
                        border: catSelector === 1 ? '1px solid red' : '0 solid red',
                        textAlign: 'center',
                        color: (theme) => theme.palette.primary.darker,
                        bgcolor: (theme) => theme.palette.primary.lighter,
                        cursor: 'pointer',
                        opacity: catSelector === 1 ? 1 : 0.5,
                      }}
                    >
                      <StyledIcon
                        sx={{
                          color: (theme) => theme.palette.primary.dark,
                          backgroundImage: (theme) =>
                            `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
                              theme.palette.primary.dark,
                              0.24
                            )} 100%)`,
                        }}
                      >
                        <Iconify icon="eva:person-outline" sx={{ color: '#000', width: 24, height: 24 }} />
                      </StyledIcon>
                      <Typography variant="body2">Coach</Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      onClick={() => {
                        setCatSelector(2);
                        setSteps(['Catégorie', 'Profile', 'Entreprise']);
                      }}
                      sx={{
                        py: 5,
                        textAlign: 'center',
                        boxShadow: catSelector === 2 ? 0 : 5,
                        border: catSelector === 2 ? '1px solid red' : '0 solid red',
                        color: (theme) => theme.palette.error.darker,
                        bgcolor: (theme) => theme.palette.error.lighter,
                        cursor: 'pointer',
                        opacity: catSelector === 2 ? 1 : 0.5,
                      }}
                    >
                      <StyledIcon
                        sx={{
                          color: (theme) => theme.palette.error.dark,
                          backgroundImage: (theme) =>
                            `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
                              theme.palette.error.dark,
                              0.24
                            )} 100%)`,
                        }}
                      >
                        <Iconify icon="eva:person-outline" sx={{ color: '#000', width: 24, height: 24 }} />
                      </StyledIcon>
                      <Typography variant="body2">Coaché(e)</Typography>
                    </Card>
                  </Grid>
                </Grid>
                <Typography variant="button" color="error" sx={{ display: 'block', textAlign: 'center', mt: 2 }}>
                  {catError}
                </Typography>
              </>
            ) : activeStep === 1 ? (
              <Container maxWidth="sm">
                <Box sx={{ mt: 2, mb: 1 }}>
                  <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 } }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      {catSelector === 1 ? "Profile d'un Coach" : "Profile d'un entrepreneur"}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Bienvenue dans notre plateforme de coaching!
                    </Typography>
                    <List
                      sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 300,
                        '& ul': { padding: 0, margin: 0 },
                      }}
                      subheader={<li />}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Voici les fonctionnalités de notre plateforme:
                      </Typography>
                      {(catSelector === 1 ? objectifs : objectifsEntrepreneur).map((name, key) => (
                        <ListItem key={`section-${key}`}>
                          <ListItemText primary={`${key + 1}. ${name}`} />
                        </ListItem>
                      ))}
                    </List>
                    <Stack spacing={2} sx={{ mt: 3 }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {catSelector === 1
                          ? "La qualité d'un coach en affaires repose sur plusieurs aspects clés."
                          : "Le coaching en affaires est essentiel pour guider les entrepreneurs vers la réussite et maximiser leur potentiel."}
                      </Typography>
                      <FormGroup>
                        <FormLabel component="legend" sx={{ mb: 1 }}>Cochez si vous êtes d'accord avec nous</FormLabel>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Expérience professionnelle"
                          onChange={() => setQuality([...quality, 'Expérience professionnelle'])}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Compétences en leadership"
                          onChange={() => setQuality([...quality, 'Compétences en leadership'])}
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Capacité d'écoute active"
                          onChange={() => setQuality([...quality, "Capacité d'écoute active"])}
                        />

                        <FormControlLabel
                          control={<Checkbox />}
                          label="Adaptabilité"
                          onChange={() => setQuality([...quality, 'Adaptabilité'])}
                        />

                        <FormControlLabel
                          control={<Checkbox />}
                          label="Orientation vers les résultats "
                          onChange={() => setQuality([...quality, 'Orientation vers les résultats '])}
                        />

                        <FormControlLabel
                          control={<Checkbox />}
                          label="Confidentialité"
                          onChange={() => setQuality([...quality, 'Confidentialité'])}
                        />
                      </FormGroup>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 2 }}
                      >
                        Continuer
                      </LoadingButton>
                    </Stack>
                  </Paper>
                </Box>
              </Container>
            ) : activeStep === 2 ? (
              <Container maxWidth="sm">
                <Box sx={{ mt: 2, mb: 1 }}>
                  {registeredUser ? (
                    <Paper
                      elevation={3}
                      sx={{
                        p: 3,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src="../../../assets/verified.gif"
                        alt="Success Gif"
                        style={{ width: '50%', maxWidth: 200, marginBottom: 16, alignSelf: 'center' }}
                      />
                      <Typography variant="h5" gutterBottom>
                        Compte créé avec succès!
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        Un email a été envoyé à votre adresse pour confirmation.
                      </Typography>
                      <Button variant="contained" color="primary" onClick={handleNext}>
                        Continuer
                      </Button>
                    </Paper>
                  ) : (
                    <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 } }}>
                      <Stack spacing={3}>
                        <TextField
                          name="name"
                          label="Nom complet"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          error={!!nameError}
                          helperText={nameError}
                        />
                        <TextField
                          type="email"
                          name="email"
                          label="Adresse email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={!!emailError}
                          helperText={emailError}
                        />
                        <TextField
                          type="tel"
                          name="phone"
                          label="Téléphone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          error={!!phoneError}
                          helperText={phoneError}
                        />
                        <FormControl fullWidth error={!!sexError}>
                          <InputLabel id="demo-simple-select-label">Sexe</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sex}
                            label="Sexe"
                            onChange={handleChange}
                          >
                            <MenuItem value="M">Masculin</MenuItem>
                            <MenuItem value="F">Féminin</MenuItem>
                            <MenuItem value="AUTRE">Autre</MenuItem>
                          </Select>
                          {!!sexError && <FormHelperText>Le sexe ne peut pas être vide</FormHelperText>}
                        </FormControl>
                        <TextField
                          name="password"
                          label="Mot de passe"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          error={!!passwordError}
                          helperText={passwordError}
                          defaultValue="AUTRE"
                        />

                        <TextField
                          name="confirmPassword"
                          label="Confirmer le mot de passe"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          error={!!confirmPasswordError}
                          helperText={confirmPasswordError}
                        />
                        <TextField
                          name="project"
                          label="Nom de l'Entreprise"
                          value={project}
                          onChange={(e) => setProject(e.target.value)}
                          error={!!projectError}
                          helperText={projectError}
                        />
                        <TextField
                          name="detailsOfProject"
                          label="Détails du projet"
                          value={detailsOfProject}
                          onChange={(e) => setDetailsOfProject(e.target.value)}
                          error={!!detailsOfProjectError}
                          helperText={detailsOfProjectError}
                        />
                        {errorRegister && (
                          <Typography variant="body" sx={{ textAlign: 'center', color: 'red', mb: 3 }}>
                            {errorRegister}
                          </Typography>
                        )}
                        
                        {errorSaveUser && (
                          <Typography variant="body" sx={{ textAlign: 'center', color: 'red', mb: 3 }}>
                            {errorSaveUser}
                          </Typography>
                        )}
                        <LoadingButton
                          loading={isLoadingRegister}
                          disabled={isLoadingRegister}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={handleClick}
                        >
                          S'enregistrer
                        </LoadingButton>
                      </Stack>
                    </Paper>
                  )}
                </Box>
              </Container>
            ) : null}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Retour
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button onClick={handleNext} variant="outlined" disabled={activeStep && !registeredUser}>
                {activeStep === steps.length - 1 ? 'Fin' : 'Suivant'}
              </Button>
            </Box>

            <Link
              href="/dashboard/app"
              style={{ display: 'block', cursor: 'pointer', textAlign: 'center', color: 'red' }}
              variant="subtitle2"
              underline="hover"
            >
              Retour à l'accueil
            </Link>
          </>
        )}
      </Box>
    </Container>
  );
}
