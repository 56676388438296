import PropTypes from 'prop-types';
// @mui
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Stack, Paper, Button, Tooltip, OutlinedInput, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import MyAvatar from '../../../components/MyAvatar';
import { updateGoal, updateGoalCoach, updateGoalCoachee } from '../../../redux/goalReducer';


// ----------------------------------------------------------------------

KanbanTaskCommentInput.propTypes = {
  card: PropTypes.object,
};
export default function KanbanTaskCommentInput({ card }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const { user } = useSelector((state) => state.auth);
  const { isLoadingUpdateGoal } = useSelector((state) => state.goal);


  const handleUpdateTaskAddComment = async () => {
    const chat = {
      message,
      date: new Date(),
      owner: user?.user?.user?.userId,
      attachements: [],
    }
    if (user?.user?.user?.role === "COACH") {
      await dispatch(updateGoalCoach({ ...card, "chat": [...card.chat, chat], idCoach: user?.user?.user?.userId }));

    } else if (user?.user?.user?.role === "COACHE") {
      await dispatch(updateGoalCoachee({ ...card, "chat": [...card.chat, chat], idCoachee: user?.user?.user?.userId }));
    }
    else {
      await dispatch(updateGoal({ ...card, "chat": [...card.chat, chat] }));
    };
    await setMessage("")

  };

  const handleChangeComment = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Stack direction="row" spacing={2} sx={{ py: 3, px: 2.5 }}>
      <MyAvatar />

      <Paper variant="outlined" sx={{ p: 1, flexGrow: 1 }}>
        <OutlinedInput
          fullWidth
          multiline
          rows={2}
          value={message}
          onChange={handleChangeComment}
          placeholder="Entrez un message"
          sx={{ '& fieldset': { display: 'none' } }}
        />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Add photo">
              <IconButton size="small">
                <Iconify icon={'ic:round-add-photo-alternate'} width={20} height={20} />
              </IconButton>
            </Tooltip>
            {/* <IconButton size="small">
              <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
            </IconButton> */}
          </Stack>

          <Button onLoad={isLoadingUpdateGoal} disabled={isLoadingUpdateGoal} variant="contained" onClick={handleUpdateTaskAddComment} >Commenter</Button>
        </Stack>
      </Paper>
    </Stack>
  );
}
