import PropTypes from 'prop-types';
// @mui
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

import { Stack, Paper, Button, Tooltip, OutlinedInput, IconButton, Avatar } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import { updateAction } from '../../../redux/actionReducer';


// ----------------------------------------------------------------------

KanbanTaskCommentInputAction.propTypes = {
  action: PropTypes.object,
  onUpdateMessage: PropTypes.func,

};
export default function KanbanTaskCommentInputAction({ action, onUpdateMessage }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const { user } = useSelector((state) => state.auth);
  const { isLoadingUpdateAction } = useSelector((state) => state.action);

  console.log("action", action);

  const handleUpdateTaskAddComment = async () => {
    const chat = {
      message,
      date: new Date(),
      owner: user?.user?.user?.userId,
      attachements: [],
    }
      await dispatch(updateAction({ ...action, chat: [...action?.chat, chat] }))
      .then((result) => {
        if (updateAction.fulfilled.match(result)) {
          // Handle successful matching
          console.log('Merci:', result.payload);
          enqueueSnackbar(result.payload, { variant: 'success' });
        } else if (updateAction.rejected.match(result)) {
          // Handle rejected sign up
          console.log("Errorrr ", result.payload);
          enqueueSnackbar(result.payload, { variant: 'error' });
        }
      })
      .catch((error) => {
        // Handle any additional errors
        console.log(`Error during matching to coach:, ${error}`);
        enqueueSnackbar(`Une erreur est survenue:, ${error}`, { variant: 'error' });
      });
      await onUpdateMessage([...action?.chat, chat] )
    
    await setMessage("")

  };

  const handleChangeComment = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Stack direction="row" spacing={2} sx={{ py: 3, px: 2.5 }}>
      <Avatar src={user.user.user.profile_picture} alt="photoURL" />

      <Paper variant="outlined" sx={{ p: 1, flexGrow: 1 }}>
        <OutlinedInput
          fullWidth
          multiline
          rows={2}
          value={message}
          onChange={handleChangeComment}
          placeholder="Entrez un message"
          sx={{ '& fieldset': { display: 'none' } }}
        />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Add photo">
              <IconButton size="small">
                <Iconify icon={'ic:round-add-photo-alternate'} width={20} height={20} />
              </IconButton>
            </Tooltip>
            {/* <IconButton size="small">
              <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
            </IconButton> */}
          </Stack>

          <Button color="info" onLoad={isLoadingUpdateAction} disabled={isLoadingUpdateAction} 
            variant="outlined" onClick={handleUpdateTaskAddComment} >Commenter</Button>
        </Stack>
      </Paper>
    </Stack>
  );
}
