/* eslint-disable */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { useSelector } from 'react-redux';

import { Stack, Avatar, Typography, Grid, CircularProgress, Box } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Image from '../../../components/Image';
import { store } from '../../../redux/Store';
import { fetchUsers } from '../../../redux/listUserReducer';
// import LightboxModal from '../../../components/LightboxModal';

// ----------------------------------------------------------------------

KanbanTaskCommentList.propTypes = {
  comments: PropTypes.array,
};

export default function KanbanTaskCommentList({ comments }) {
  const { userList, isLoadingUser } = useSelector((state) => state.listUser);
  console.log('userList', userList);

  const [openLightbox, setOpenLightbox] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  //  react-hooks/exhaustive-deps
  useEffect(() => {
    store.dispatch(fetchUsers());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const imagesLightbox = comments.filter((comment) => comment.messageType === 'image').map((item) => item.message);
  const imagesLightbox = comments
    .filter((comment) => comment.attachements.length != 0)
    .map((item) => item.attachements);
  // console.log("imagesLightbox", imagesLightbox);

  const handleOpenLightbox = (url) => {
    console.log('url', url);
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  return (
    <>
      <Stack spacing={3} sx={{ py: 3, px: 2.5, bgcolor: 'background.neutral' }}>
        {comments.map((comment) => (
          <Stack key={comment._id} direction="row" spacing={2}>
            <Avatar
              src={userList.find((val) => val._id === comment.owner)?.profile_picture}
              sx={{ width: 32, height: 32 }}
            />
            <div>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="subtitle2">{userList.find((val) => val._id === comment.owner)?.name}</Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {fToNow(comment.date)}
                </Typography>
              </Stack>
              <Stack>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                  {comment.message}
                </Typography>

                <Grid container spacing={2}>
                  {comment?.attachements.map((val) => (
                    <Grid item xs={6}>
                      <Image
                        key={val._id}
                        src={val}
                        onClick={() => handleOpenLightbox(val)}
                        sx={{ mt: 2, borderRadius: 1 }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </div>
          </Stack>
        ))}
        {isLoadingUser ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress color="warning" />
          </Box>
        ) : null}{' '}
      </Stack>

      {/* <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      /> */}
    </>
  );
}
